import { createContext, useContext, useEffect, useState } from "react";
import { useActiveContextMenuContext } from "./ActiveContextMenuProvider";
import { useBlocksContext } from "./BlocksContextProvider";
import { useColumnWidth } from "../../TimeBlocker/Utils/CustomHooks/useColumnWidth";
import { useRecurringContext } from "./RecurringContextProvider";
import { useActivityContext } from "./ActivitiesContextProvider";
import { GetUpdatedIndentions } from "../Utils/Functions/GetUpdatedIndentions";
import { deleteBlock } from "../../../Endpoints/Blocks/BlocksEndpoints";
import { useDebounce } from "use-debounce";
import { patchRecurrancePattern } from "../../../Endpoints/Recurrance/RecurranceEndpoints";
import { useColumnStateUpdater } from "./ColumnStateContextProvider";
import { useBlockColor } from "../Utils/CustomHooks/useBlockColor";
import { useBlockTitle } from "../Utils/CustomHooks/useBlockTitle";
import { useBlockUpdater } from "../Utils/CustomHooks/useBlockUpdater";

const EditBlockContext = createContext(null);

export const EditBlockProvider = ({ children }) => {
  const { activeContextBlock, setActiveContextBlock } =
    useActiveContextMenuContext();
  const { updateBlock } = useBlockUpdater();
  const { blocks, setBlocks } = useBlocksContext();
  const { columnWidth } = useColumnWidth();
  const { setEndpoint, setCallback, handleRecurringChange } =
    useRecurringContext();
  const [recurringPatternModalOpen, setRecurringPatternModalOpen] =
    useState(false);
  const [title, setTitle] = useState(activeContextBlock?.title);
  const [color, setColor] = useState(activeContextBlock?.color);
  const [colorUpdated, setColorUpdated] = useState(false);
  const { activities } = useActivityContext();
  const { loadColumns } = useColumnStateUpdater();

  const [colorChangeValues, setColorChangeValues] = useState(null);
  const [debouncedColorChangeValues] = useDebounce(colorChangeValues, 200);

  const [titleChange, setTitleChange] = useState(null);
  const [debouncedTitleChange] = useDebounce(titleChange, 500);

  const { color: blockColor } = useBlockColor(activeContextBlock);
  useEffect(() => {
    setColor(blockColor);
  }, [blockColor]);

  const { title: blockTitle } = useBlockTitle(activeContextBlock);
  useEffect(() => {
    setTitle(blockTitle);
  }, [blockTitle]);

  useEffect(() => {
    if (activeContextBlock == null) return;
    const contextBlock = blocks.find((b) => b._id === activeContextBlock._id);
    setActiveContextBlock(contextBlock);
  }, [blocks]);

  useEffect(() => {
    if (activeContextBlock == null) return;
    // setColor(activeContextBlock.color);
    setColorUpdated(false);
  }, [activeContextBlock]);

  const blockActivity = activities.find(
    (activity) => activity.id === activeContextBlock?.activityId
  );

  const handleColorChange = (value) => {
    setColor(value);
    setColorUpdated(true);
  };

  const handleColorBlur = () => {
    if (colorUpdated) setColorChangeValues(color);
  };

  useEffect(() => {
    if (debouncedColorChangeValues === null) return;

    const updateData = {
      color: debouncedColorChangeValues,
      hasCustomColor: true,
    };

    updateBlock(updateData, activeContextBlock);
  }, [debouncedColorChangeValues]);

  const onRemove = () => {
    setBlocks((currentBlocks) =>
      currentBlocks.filter((b) => b._id !== activeContextBlock._id)
    );
    const date = activeContextBlock.date;

    setBlocks((currentBlocks) =>
      GetUpdatedIndentions(currentBlocks, date, columnWidth)
    );
    deleteBlock(activeContextBlock, activeContextBlock.date);
    setActiveContextBlock(null);
  };

  const handleTitleChange = (title) => {
    setTitleChange({
      title,
      blockId: activeContextBlock._id,
      block: activeContextBlock,
    });
    setBlocks((current) =>
      current.map((b) =>
        b._id === activeContextBlock._id ? { ...b, title } : b
      )
    );
  };

  useEffect(() => {
    if (debouncedTitleChange === null) return;

    const updateData = {
      title: debouncedTitleChange.title,
      hasCustomTitle: true,
    };

    updateBlock(updateData, debouncedTitleChange.block);
  }, [debouncedTitleChange]);

  const handleUseCustomColorChange = (useCustomColor) => {
    const updateData = { hasCustomColor: useCustomColor };
    updateBlock(updateData, activeContextBlock);
  };

  const handleUseCustomTitleChange = (useCustomTitle) => {
    const updateData = { hasCustomTitle: useCustomTitle };
    updateBlock(updateData, activeContextBlock);
  };

  const handleRemoveActivity = () => {
    const updateData = { activityId: null };
    updateBlock(updateData, activeContextBlock);
  };

  const onRecurringChange = (recurring) => {
    if (recurring === false) {
      handleRecurringChange(activeContextBlock, false);
    } else {
      setRecurringPatternModalOpen(true);
    }
  };

  const onRecurringPatternSuccess = (pattern) => {
    handleRecurringChange(activeContextBlock, true, pattern);
  };

  const handleRadioChange = (value) => {
    setBlocks((current) =>
      current.map((b) =>
        b._id === activeContextBlock._id
          ? { ...b, selectedRadioOption: value }
          : b
      )
    );
  };

  const handlePatternChange = (value) => {
    patchRecurrancePattern(activeContextBlock, value).then((response) => {
      loadColumns();
    });
  };

  return (
    <EditBlockContext.Provider
      value={{
        color,
        title,
        handleColorChange,
        handleColorBlur,
        onRemove,
        handleTitleChange,
        blockActivity,
        handleRemoveActivity,
        onRecurringChange,
        handleRadioChange,
        handlePatternChange,
        recurringPatternModalOpen,
        setRecurringPatternModalOpen,
        onRecurringPatternSuccess,
        handleUseCustomColorChange,
        handleUseCustomTitleChange,
      }}
    >
      {children}
    </EditBlockContext.Provider>
  );
};

export const useEditBlockContext = () => {
  const context = useContext(EditBlockContext);
  if (!context) {
    throw new Error("useEditBlock must be used within an EditBlockProvider");
  }
  return context;
};
