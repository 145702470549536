export function HeatMap() {
  return (
    <div
      className="p-3 rounded-xl bg-white w-[300px] sm:w-[440px]"
      style={{
        boxShadow: "0px 0px 21.1px 6px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div className="flex gap-1 justify-center">
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube percentage={0} />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube percentage={0} />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube percentage={25} />
          <Cube />
          <Cube />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube percentage={75} />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube percentage={0} />
          <Cube percentage={25} />
          <Cube />
          <Cube percentage={0} />
          <Cube />
          <Cube percentage={0} />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube percentage={0} />
          <Cube percentage={50} />
          <Cube />
          <Cube percentage={75} />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube percentage={50} />
          <Cube />
        </div>
        <div className="flex flex-col gap-1">
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
          <Cube />
        </div>
      </div>
    </div>
  );
}
function Cube({ percentage = 100 }) {
  let color = "#B0B0B0";
  if (percentage === 25) color = "rgba(57, 125, 68, 0.25)";
  if (percentage === 50) color = "rgba(57, 125, 68, 0.50)";
  if (percentage === 75) color = "rgba(57, 125, 68, 0.75)";
  if (percentage === 100) color = "rgba(57, 125, 68, 1)";
  return (
    <div
      style={{ backgroundColor: color }}
      className="rounded w-3 h-3 sm:h-5 sm:w-5"
    ></div>
  );
}
