import axiosInstance from "../../httpInterceptor";
const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export async function getBlocks(startDate, endDate) {
  return await axiosInstance.post("/blocks/dates", { startDate, endDate });
}

export async function addBlock(block) {
  return await axiosInstance.post("/blocks", {
    ...block,
  });
}

export async function patchBlock(block) {
  return await axiosInstance.patch(`/blocks/${block._id}`, {
    ...block,
  });
}

export async function deleteBlock(block) {
  return await axiosInstance.post("/blocks/delete/" + block._id, {
    date: block.date,
    isRecurrance: block.isRecurrance,
    recurranceId: block.recurranceId,
  });
}
