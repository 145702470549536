import { createContext, useContext, useState } from "react";

const ActiveContextMenuContext = createContext(null);

export function ActiveContextMenuProvider({ children }) {
  const [activeContextBlock, setActiveContextBlock] = useState(new Date());

  return (
    <ActiveContextMenuContext.Provider
      value={{ activeContextBlock, setActiveContextBlock }}
    >
      {children}
    </ActiveContextMenuContext.Provider>
  );
}

export function useActiveContextMenuContext() {
  const context = useContext(ActiveContextMenuContext);
  if (!context) {
    throw new Error(
      "ActiveContextMenuContext must be used within a ActiveContextMenuProvider"
    );
  }
  return context;
}
