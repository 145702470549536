import { useNavigate } from "react-router-dom";
import googleIcon from "../../../Assets/Icons/google-logo.svg";
import { signInWithGoogle } from "../../Shared/Utils/Auth";
import useTheme from "../../Shared/Utils/useTheme";

export function Login() {
  const { theme, setTheme } = useTheme();

  const navigate = useNavigate();
  const handleSignInWithGoogle = () => {
    signInWithGoogle().then(() => {
      navigate("/timeBlocker/blocks");
    });
  };

  return (
    <div className="bg-secondaryLight dark:bg-lightDark text-white h-screen">
      <div className="container mx-auto pt-16 pt-10 lg:pt-20 xl:pt-36">
        <main className="mx-auto max-w-5xl flex justify-center lg:justify-start lg:py-5 lg:bg-secondaryLight lg:flex lg:items-center lg:content-center lg:shadow-xl text-black dark:text-white dark:lg:bg-lightDark">
          <div className="lg:w-1/2 h-full">
            <h1 className="text-4xl w-max mx-auto">Welcome back!</h1>
            <p className="w-max mx-auto">Please enter your details.</p>
            <GoogleButton login={handleSignInWithGoogle} />
          </div>
          <img className="hidden w-1/2 lg:block" alt="" />
        </main>
      </div>
    </div>
  );
}

function GoogleButton({ login }) {
  return (
    <div className="max-w-7xl mx-auto py-10 sm:py-20">
      <div className="mx-auto w-max">
        <button
          className="border rounded-md px-5 py-3 flex items-center border-black dark:border-white"
          onClick={login}
        >
          <i>
            <img className="w-7" src={googleIcon} alt="" />
          </i>
          <p>Sign in with Google</p>
        </button>
      </div>
    </div>
  );
}
