import { useEffect, useState } from "react";
import { useActivityContext } from "../../Contexts/ActivitiesContextProvider";
import { defaultBlockColor } from "../../../../App";
import { useActivityColor } from "./useActivityColor";

export function useBlockColor(inputBlock) {
  const { getActivityColor, colorsLoaded: activityColorsLoaded } =
    useActivityColor();
  const [block, setBlock] = useState(inputBlock);
  const [color, setColor] = useState(null);

  useEffect(() => {
    setBlock(inputBlock);
  }, [inputBlock]);

  useEffect(() => {
    if (block == null || activityColorsLoaded === false) return;

    if (block.hasCustomColor) {
      setColor(block.color);
      return;
    }

    if (block.activityId == null) {
      setColor(defaultBlockColor);
      return;
    }

    const activityColor = getActivityColor(block.activityId);

    if (activityColor == null) {
      setColor(defaultBlockColor);
      return;
    }

    setColor(activityColor);
  }, [block, activityColorsLoaded]);

  return { color };
}
