import { useState } from "react";
import { useActivityContext } from "../Contexts/ActivitiesContextProvider";

export function CategoryBundle({
  categoryId,
  children,
  uncategorized = false,
  startOpen = false,
}) {
  const [open, setOpen] = useState(startOpen);

  const { activities } = useActivityContext();
  const category = activities.find((a) => a._id === categoryId);

  const onToggle = () => {
    setOpen((current) => !current);
  };

  const styles = {
    display: "inline-block",
    transform: open ? "rotate(0deg)" : "rotate(-90deg)",
    transition: "transform 0.2s ease-in-out",
  };
  return (
    <div onClick={onToggle}>
      <div className="flex items-center text-gray-500 cursor-pointer">
        <svg
          style={{ ...styles, width: "15px", height: "15px" }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
        <h5 className="text-xs">
          {uncategorized ? "uncategorized" : category?.title}
        </h5>
      </div>
      {open && <div>{children}</div>}
    </div>
  );
}
