import { Link } from "react-router-dom";
import { HeatMap } from "./HeatMap";

export function LandingPage2() {
  return (
    <div className="quicksand overflow-hidden">
      <section className="bg-home">
        <div className="px-5 py-6 flex gap-3 items-center md:items-end ">
          <h2 className="text-2xl sm:text-5xl font-semibold">Blokkly</h2>
          <nav className="flex gap-2">
            <div>
              <Link className="border-b-2 border-black pb-1" to={"/home"}>
                Home
              </Link>
            </div>
            <div>
              <Link to={"/timeBlocker/blocks"}>Time Blocker</Link>
            </div>
          </nav>
          <div className="ml-auto self-center bg-[#FFE1E1] rounded-full hidden sm:block">
            <Link to={"/login"}>
              <div className="py-3 px-4">Try for free</div>
            </Link>
          </div>
        </div>
      </section>

      <section className="bg-home">
        <div className="max-w-80 xs:container mx-auto xs:pt-10 sm:pt-20 lg:pt-28 w-[898px]">
          <div>
            <div className="px-5">
              <div className="max-w-[898px] mx-auto">
                <span className="text-2xl lg:text-4xl font-bold text-[#9192DE]">
                  Block Time,
                  <br className="xs:hidden" />
                  <span className="hidden xs:inline-block">&nbsp;</span>Track
                  Progress,
                  <br />
                  Achieve More
                </span>
                <div className="text-end mt-4 xs:mt-0">
                  <span className="text-base lg:text-2xl">
                    “A goal without a plan is just a wish.”
                    <br />
                    <span className="text-xs lg:text-base">
                      -Antoine de Saint-Exupéry
                    </span>
                  </span>
                </div>

                <div className="mt-11 w-max mx-auto">
                  <button className=" rounded-md border border-[#9192DE] hover:bg-[#9192DE] hover:text-white">
                    <Link to={"/login"}>
                      <div className="px-3 py-2"> Sign up for free</div>
                    </Link>
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-16">
              <div
                className="flex gap-11 w-[898px]"
                style={{
                  marginLeft: "calc(50% - 449px)",
                }}
              >
                <div className="flex flex-col gap-4">
                  <HomeBlock
                    text={"Emails"}
                    time={"8.00 - 10.00"}
                    color={"bg-[#98C6EC]"}
                    height={"h-[153px]"}
                  />
                  <HomeBlock
                    text={"Exercise"}
                    time={"10.00 - 11.30"}
                    color={"bg-[#98ECAA]"}
                    height={"h-[110px]"}
                  />
                  <HomeBlock
                    text={"Focus Time"}
                    time={"11.30 - 14.00"}
                    color={"bg-[#C198EC]"}
                    height={"h-[110px]"}
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <HomeBlock
                    text={"Emails"}
                    time={"8.00 - 10.00"}
                    color={"bg-[#98C6EC]"}
                    height={"h-[153px]"}
                  />
                  <HomeBlock
                    text={"Focus Time"}
                    time={"10.00 - 14.30"}
                    color={"bg-[#C198EC]"}
                    height={"h-[244px]"}
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <HomeBlock
                    text={"Exercise"}
                    time={"8.00 - 9.00"}
                    color={"bg-[#98ECAA]"}
                    height={"h-[91px]"}
                  />
                  <HomeBlock
                    text={"Focus Time"}
                    time={"10.00 - 14.30"}
                    color={"bg-[#C198EC]"}
                    height={"h-[193px]"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="relative -mt-16 z-10">
        <div className="bg-white">
          <div className="py-9 sm:py-24 mx-auto max-w-[440px]  sm:max-w-[632px]">
            <h3 className="text-xl font-semibold w-max mx-auto">
              What is time blocking?
            </h3>
            <p className="mt-4 px-3 sm:px-0 text-sm sm:text-base p-0 w-full text-center">
              Time blocking is a planning method that divides your day into
              focused time slots, each dedicated to specific tasks or
              activities. It helps you structure your day, minimize
              distractions, and stay on top of your priorities.
            </p>
          </div>
        </div>
      </section>
      <section className="">
        <div className="bg-home">
          <div
            className="flex gap-11 w-[898px]"
            style={{
              marginLeft: "calc(50% - 449px)",
            }}
          >
            <div className="-mt-14">
              <HomeBlock
                text={"Focus Time"}
                time={"11.30 - 14.00"}
                color={"bg-[#C198EC]"}
                height={"h-[110px]"}
              />
            </div>
            <div className="-mt-10">
              <HomeBlock
                text={"Focus Time"}
                time={""}
                color={"bg-[#C198EC]"}
                height={"h-[133px]"}
              />
            </div>
          </div>

          <div className="pt-32 mx-auto w-min">
            <h3 className="text-xl font-semibold w-max mx-auto">
              Set up targets
            </h3>
            <div className="mt-16 flex lg:w-[1039px] flex-col lg:flex-row lg:justify-between items-center">
              <div className="flex flex-col gap-3">
                <Target
                  text={"Focus Time"}
                  target={"40 min left"}
                  color={"text-[#DE6363]"}
                />
                <Target
                  text={"Emails"}
                  target={"Done"}
                  color={"text-[#63DE75]"}
                />
                <Target
                  text={"Exercise"}
                  target={"1 block left"}
                  color={"text-[#DE6363]"}
                />
              </div>
              <p className="m-0 w-max max-w-[300px] sm:max-w-[436px] mt-8 lg:mt-0  text-center lg:text-left">
                Setting daily targets helps you stay focused and build
                productive habits. By breaking down goals into clear targets,
                you’ll stay on track and ensure steady progress toward what
                matters most.
              </p>
            </div>
          </div>

          <div className="pt-32 mx-auto w-min">
            <h3 className="text-xl font-semibold w-max mx-auto">
              See Where Your Time Goes
            </h3>
            <div className="mt-16 flex lg:w-[1039px] flex-col lg:flex-row lg:justify-between items-center">
              <div className="flex flex-col gap-3">
                <Activity
                  text={"Focus Time"}
                  time={"6h"}
                  color={"bg-[#C198EC]"}
                  width={"w-[300px] sm:w-[440px] "}
                />
                <Activity
                  text={"Emails"}
                  time={"4h"}
                  color={"bg-[#98C6EC]"}
                  width={"w-[200px] sm:w-[290px] "}
                />
                <Activity
                  text={"Exercise"}
                  time={"3h"}
                  color={"bg-[#98ECAA]"}
                  width={"w-[150px] sm:w-[218px] "}
                />
              </div>
              <p className="m-0 w-max max-w-[300px] sm:max-w-[436px] mt-8 lg:mt-0  text-center lg:text-left">
                Gain insights into how you spend your time. View detailed stats
                on your activities, spot patterns, and make adjustments to stay
                aligned with your goals. Tracking your time helps you work
                smarter and make every hour count.
              </p>
            </div>
          </div>

          <div className="py-32 mx-auto w-min">
            <h3 className="text-xl font-semibold w-max mx-auto">
              Track Your Achievements
            </h3>
            <div className="mt-16 flex lg:w-[1039px] flex-col lg:flex-row lg:justify-between items-center">
              <div>
                <HeatMap />
              </div>
              <p className="m-0 w-max max-w-[300px] sm:max-w-[436px] mt-8 lg:mt-0  text-center lg:text-left">
                See your progress at a glance. Charts show how many targets you
                hit each day, helping you track consistency, build momentum, and
                stay motivated on your journey.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="relative -mt-16 z-10">
        <div className="bg-white">
          <div className=" py-9 sm:py-24 mx-auto max-w-[440px]  sm:max-w-[632px]">
            <h3 className="text-xl font-semibold w-max mx-auto">
              Why Time Blocking Works
            </h3>
            <p className="mt-4 px-3 sm:px-0 text-sm sm:text-base p-0 w-full text-center">
              By assigning tasks to specific blocks, time blocking leverages
              focus and momentum. It helps you manage your energy, limits
              decision fatigue, and encourages a structured yet flexible
              approach to tackling your goals.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-home py-9 sm:py-24">
          <div className="w-max mx-auto">
            <h3 className="text-2xl font-bold">
              Try It First, No Account Needed
            </h3>
            <div className="mt-11 w-max mx-auto">
              <button className=" rounded-md border border-[#9192DE] hover:bg-[#9192DE] hover:text-white">
                <Link to={"/timeBlocker/blocks"}>
                  <div className="px-3 py-2"> Try it out</div>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

function HomeBlock({ color, height, text, time }) {
  return (
    <div
      className={["p-4 w-[270px] rounded-xl", color, height].join(" ")}
      style={{ boxShadow: "0px 0px 21.1px 6px rgba(0, 0, 0, 0.25)" }}
    >
      <div className="font-bold">{text}</div>
      <div>{time}</div>
    </div>
  );
}

function Target({ text, target, color }) {
  return (
    <div
      className="px-3 py-2 lg-py-3  rounded-xl flex bg-white w-[300px] sm:w-[440px]"
      style={{
        boxShadow: "0px 0px 21.1px 6px rgba(0, 0, 0, 0.25)",
      }}
    >
      <span className="font-bold text-sm sm:text-base flex items-center">
        {text}
      </span>
      <div className="ml-auto flex gap-1">
        <span className="text-sm sm:text-base">Daily Target: </span>
        <div className="w-24 sm:w-28 flex justify-center">
          <span className={["font-bold text-sm sm:text-base", color].join(" ")}>
            {target}
          </span>
        </div>
      </div>
    </div>
  );
}

function Activity({ text, time, color, width }) {
  return (
    <div
      className={[
        "px-3 py-2 lg-py-3 rounded-xl flex justify-between",
        color,
        width,
      ].join(" ")}
      style={{
        boxShadow: "0px 0px 21.1px 6px rgba(0, 0, 0, 0.25)",
      }}
    >
      <span className="font-bold text-sm sm:text-base flex items-center">
        {text}
      </span>
      <div>
        <span className="font-bold text-sm sm:text-base">{time}</span>
      </div>
    </div>
  );
}
