import { NavLink } from "react-router-dom";

export function Navigation() {
  return (
    <nav className="flex flex-col">
      <NavLink
        className={({ isActive }) =>
          [
            isActive ? "bg-primary text-white" : "",
            "border-none px-7 w-64 py-4 ",
          ].join(" ")
        }
        to="/timeBlocker/blocks"
      >
        Time blocks
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          [
            isActive ? "bg-primary text-white" : "",
            "border-none px-7 w-64 py-4",
          ].join(" ")
        }
        to="/timeBlocker/activities"
      >
        Activities
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          [
            isActive ? "bg-primary text-white" : "",
            "border-none px-7 w-64 py-4",
          ].join(" ")
        }
        to="/timeBlocker/statistics"
      >
        Statistics
      </NavLink>
    </nav>
  );
}
