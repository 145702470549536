import { getLanes } from "./Lanes";
import { getOverlappingGroups } from "./Overlaping";
import { getTree } from "./Tree";

export const getUpdatedBlockPositions = (blocks, columnWidth = 180) => {
  const sortedBlocks = sortBlocks(blocks);
  const overlappingGroups = getOverlappingGroups(sortedBlocks);

  let groupTrees = [];
  for (const group of overlappingGroups) {
    const lanes = getLanes(group);
    const { tree, treeBlocks } = getTree(group, lanes);
    insertDistanceLeft(treeBlocks, columnWidth);
    groupTrees.push({
      group,
      lanes,
      tree,
      treeBlocks,
    });
  }
  return sortedBlocks.map((block) => {
    const style = getBlockStyles(block, groupTrees, columnWidth);
    return {
      ...block,
      ...style,
    };
  });
};

function sortBlocks(blocks) {
  const sortedBlocks = [...blocks];
  sortedBlocks.sort(
    (a, b) =>
      a.start - b.start || b.end - a.end || a.createdDate - b.createdDate
  );
  return sortedBlocks;
}

function insertDistanceLeft(treeBlocks, columnWidth) {
  const sortedByDepth = [...treeBlocks].sort((a, b) => {
    return a.depth - b.depth;
  });
  for (const blockByDepth of sortedByDepth) {
    const block = treeBlocks.find((b) => b._id === blockByDepth._id);

    if (block.parents.length === 0) {
      block.left = 0;
      continue;
    }
    let maxLeft = 0;
    for (const parent of block.parents) {
      let left = 0;
      if (block.parentsOverlappingTexts.some((p) => p._id === parent._id)) {
        // handle left with text overlap in mind
        left =
          (columnWidth - block.masterParent.left) / block.masterParent.maxLen +
          block.masterParent.left;
      } else {
        left = parent.left + 10;
      }
      if (left > maxLeft) maxLeft = left;
    }
    block.left = maxLeft;
    continue;
  }
}

function getBlockStyles(block, groupTrees, columnWidth) {
  const { group, treeBlocks } = groupTrees.find((obj) =>
    obj.group.includes(block)
  );

  const treeBlock = treeBlocks.find((b) => b._id === block._id);
  return {
    width: `${columnWidth - treeBlock.left}px`,
    left: `${treeBlock.left}px`,
    zIndex: `${treeBlock.depth}`,
  };
}
