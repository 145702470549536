import axiosInstance from "../../httpInterceptor";

export async function postRecurrance(blockId, pattern, startDate) {
  return await axiosInstance.post("/recurrances/" + blockId, {
    pattern,
    startDate,
  });
}

export async function AddBlockFromRecurrance(block, recurranceId) {
  return await axiosInstance.patch(`/recurrances/${recurranceId}/block`, {
    ...block,
  });
}

export async function EditRecurrance(block, recurranceId, selectedRadioOption) {
  return await axiosInstance.patch(`/recurrances/${recurranceId}`, {
    ...block,
    option: selectedRadioOption,
  });
}

export async function patchRecurrancePattern(block, pattern) {
  return await axiosInstance.patch(
    "/recurrances/pattern/" + block.recurranceId,
    {
      pattern,
    }
  );
}

export async function patchRecurranceActionSuccess(
  endpoint,
  callback,
  data,
  option
) {
  return await axiosInstance.patch(endpoint, { ...data, option });
}
