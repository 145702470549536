import { useStatisticsContext } from "../../Shared/Contexts/StatisticsContextProvider";

export function OtherStats() {
  const { stats } = useStatisticsContext();
  if (stats == null) return <></>;
  return (
    <div className="flex flex-col gap-2">
      <div>
        <span className="font-bold">Blocks:</span> {stats.blockCount}
      </div>
    </div>
  );
}
