export function HoursDisplay() {
  const times = Array.from(Array(24).keys()).map((i) => {
    if (i < 10) {
      return <span key={i}>0{i}.00</span>;
    }
    return <span key={i}>{i}.00</span>;
  });
  return (
    <div className="-mt-3 flex flex-col gap-7 text-light overflow-visible">
      {times}
    </div>
  );
}
