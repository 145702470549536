import { Fragment, useState } from "react";
import "./colorPicker.css";
import removeWhite from "../../../Assets/Icons/remove-white.svg";
import remove from "../../../Assets/Icons/remove.svg";
import removeCross from "../../../Assets/Icons/remove-cross-white.svg";
import { TextInput } from "../../Shared/Components/TextInput";
import { ActivityItem } from "../../Shared/Components/ActivityItem";
import { CheckboxInput } from "../../Shared/Components/CheckboxInput";
import { Listbox, Transition } from "@headlessui/react";
import { ModalContext } from "../../Shared/Contexts/ModalContextProvider";
import ConfirmRecurringOptionModal from "./EditRecurringOptionModal";
import { useRecurringContext } from "../../Shared/Contexts/RecurringContextProvider";
import { useEditBlockContext } from "../../Shared/Contexts/EditBlockContextProvider";
import { SetRecurringPatternModal } from "./SetRecurringPatternModal";

export function ContextMenu({ contextMenuState }) {
  const { menuRef, left, top, open, contextBlock } = contextMenuState;
  const {
    color,
    title,
    handleColorChange,
    handleColorBlur,
    onRemove,
    handleTitleChange,
    blockActivity,
    handleRemoveActivity,
    onRecurringChange,
    handleRadioChange,
    handlePatternChange,
    onRecurringPatternSuccess,
    recurringPatternModalOpen,
    setRecurringPatternModalOpen,
    handleUseCustomColorChange,
    handleUseCustomTitleChange,
  } = useEditBlockContext();

  const { confirmRecurringModalOpen, setConfirmRecurringModalOpen } =
    useRecurringContext();

  const handleRecurringPatternSuccess = (pattern) => {
    onRecurringPatternSuccess(pattern);
  };
  const handleRecurringPatternCancel = () => {};

  if (!open) {
    return <></>;
  }

  return (
    <>
      <div
        ref={menuRef}
        className="absolute w-56 border rounded text-black dark:text-white bg-secondaryLight dark:bg-dark z-[999] pointer-events-auto"
        style={{ top, left }}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <ul className="flex gap-2">
          <li className="m-1 flex items-start">
            <div className="z-40">
              <input
                type="color"
                id="color"
                name="color"
                value={color || "#000000"}
                onChange={(event) => handleColorChange(event.target.value)}
                onBlur={handleColorBlur}
              />
            </div>
          </li>
          <li
            role="button"
            className="p-1 ml-auto cursor-pointer"
            onClick={onRemove}
          >
            <img className="h-4 hidden dark:block" src={removeWhite} alt="" />
            <img className="h-3 dark:hidden" src={remove} alt="" />
          </li>
        </ul>

        <div className="p-3 space-y-3">
          <TextInput
            title={"Title"}
            onValueChange={handleTitleChange}
            value={title}
          />
          {blockActivity && contextBlock.hasCustomColor && (
            <button
              className="w-full border px-6 rounded-md py-1 text-center text-sm text-black border-black  bg-red-400"
              onClick={() => handleUseCustomColorChange(false)}
            >
              Remove Custom Color
            </button>
          )}
          {blockActivity && contextBlock.hasCustomTitle && (
            <button
              className="w-full border px-6 rounded-md py-1 text-center text-sm text-black border-black  bg-red-400"
              onClick={() => handleUseCustomTitleChange(false)}
            >
              Remove Custom Title
            </button>
          )}
          {blockActivity && (
            <div className="flex gap-1 items-center">
              <div className="flex-grow">
                <ActivityItem
                  id={blockActivity.id}
                  activity={blockActivity}
                  showOptions={false}
                />
              </div>
              <div
                role="button"
                className="cursor-pointer"
                onClick={handleRemoveActivity}
              >
                <img className="h-4" src={removeCross} alt="" />
              </div>
            </div>
          )}

          <hr className="border-black dark:border-lightDark" />

          <CheckboxInput
            title={"Recurring"}
            onValuehange={onRecurringChange}
            value={contextBlock.isRecurrance}
          />
          {contextBlock.isRecurrance && (
            <ChangeAsRadio
              title={"Block to update"}
              onChange={handleRadioChange}
              selectedOption={contextBlock.selectedRadioOption ?? "single"}
            />
          )}

          {contextBlock.isRecurrance && (
            <SelectPattern
              selected={contextBlock.recurrencePattern}
              onSelectedChange={handlePatternChange}
            />
          )}
        </div>
        <ModalContext.Provider
          value={[confirmRecurringModalOpen, setConfirmRecurringModalOpen]}
        >
          <ConfirmRecurringOptionModal />
        </ModalContext.Provider>
        <ModalContext.Provider
          value={[recurringPatternModalOpen, setRecurringPatternModalOpen]}
        >
          <SetRecurringPatternModal
            onSetSuccess={handleRecurringPatternSuccess}
            onCancel={handleRecurringPatternCancel}
          />
        </ModalContext.Provider>
      </div>
    </>
  );
}

function ChangeAsRadio({ selectedOption, onChange }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <div className="mt-5">
      <h4>Make changes to</h4>
      <div className="radio">
        <label className="flex gap-3">
          <input
            type="radio"
            value="single"
            checked={selectedOption === "single"}
            onChange={handleChange}
          />
          This block
        </label>
        <label className="flex gap-3">
          <input
            type="radio"
            value="future"
            checked={selectedOption === "future"}
            onChange={handleChange}
          />
          This and future blocks
        </label>
      </div>
      <div className="radio">
        <label className="flex gap-3">
          <input
            type="radio"
            value="all"
            checked={selectedOption === "all"}
            onChange={handleChange}
          />
          All blocks
        </label>
      </div>
    </div>
  );
}

export function SelectPattern({ children, selected, onSelectedChange }) {
  const [recurrencePattern, setRecurrencePattern] = useState([
    "Daily",
    "Weekly",
    "Yearly",
    "Weekdays",
  ]);

  return (
    <Listbox value={selected} onChange={onSelectedChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-black dark:text-white">
            Recurrence Pattern
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-secondaryLight dark:bg-lightDark py-1.5 pl-3 pr-10 text-left  dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <span className="ml-3 block truncate">{selected}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2"></span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-secondaryLight dark:bg-lightDark  py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {recurrencePattern.map((pattern) => (
                  <Listbox.Option
                    key={pattern}
                    className="text-black dark:text-white relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-white dark:hover:bg-dark"
                    value={pattern}
                  >
                    {pattern}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
