import { Listbox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Modal } from "../../Shared/Components/Modal";
import { TextInput } from "../../Shared/Components/TextInput";
import { BlockPreview } from "./BlockPreview";
import { useActivityContext } from "../../Shared/Contexts/ActivitiesContextProvider";
import { ColorTemplate } from "./ColorTemplate";

export default function EditActivityModal({
  onEditActivity,
  activity,
  categories,
}) {
  const { activities } = useActivityContext();
  const handleEditActivity = () => {
    const updatedActivity = {
      ...activity,
      title,
      type,
      hasCustomColor: !hasCategoryColor,
      color: hasCategoryColor ? null : color,
      categoryId: selectedCategory,
    };

    onEditActivity(updatedActivity);
  };

  const [title, setTitle] = useState(null);
  const [type, setType] = useState(null);
  const [hasCategoryColor, setHasCategoryColor] = useState(null);
  const [color, setColor] = useState("#ffffff");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [hasValues, setHasValues] = useState(false);

  useEffect(() => {
    if (activity == null) return;

    setTitle(activity.title);
    setType(activity.type);
    setHasCategoryColor(!activity.hasCustomColor);
    setColor(
      activity.color == null
        ? activities.find((a) => a.id === activity.categoryId)?.color
        : activity.color
    );
    setSelectedCategory(activity.categoryId);
    setHasValues(true);
  }, [activity]);

  const handleSelectedChange = (value) => {
    setType(value);
  };
  const handleTitleChange = (value) => {
    setTitle(value);
  };

  const handleCategorySelect = (value) => {
    setSelectedCategory(value);
    if (value === null) setHasCategoryColor(false);
  };

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  const handlePalettePicked = (color) => {
    setColor(color);
  };

  const clearValues = () => {
    if (activity == null) return;

    setTitle(activity.title);
    setType(activity.type);
    setHasCategoryColor(!activity.hasCustomColor);
    setColor(
      activity.color == null
        ? activities.find((a) => a.id === activity.categoryId)?.color
        : activity.color
    );
    setSelectedCategory(activity.categoryId);
    setHasValues(true);
  };

  const handleCancel = () => {
    clearValues();
  };

  if (!hasValues) return "";

  return (
    <Modal
      title={"Edit Activity"}
      successText={"Save"}
      onSuccess={handleEditActivity}
      onCancel={handleCancel}
    >
      <div className="flex gap-5">
        <div>
          <BlockPreview
            title={title}
            color={
              hasCategoryColor
                ? activities.find((a) => a.id === selectedCategory)?.color
                : color
            }
          />
          <div className="mt-1">
            <ColorTemplate onColorSelect={handlePalettePicked} />
          </div>
        </div>

        <form className="text-black dark:text-white space-y-3">
          <TextInput
            title={"Title"}
            value={title}
            placeholder={"Activity_1"}
            onValueChange={handleTitleChange}
          />
          <SelectActivity
            selected={type}
            onSelectedChange={handleSelectedChange}
          />

          <div>
            <input
              type="color"
              value={color}
              onChange={handleColorChange}
              className="border-0 rounded bg-none"
            />
          </div>

          {selectedCategory != null && (
            <CheckboxInput
              title={"Use category color"}
              onValuehange={setHasCategoryColor}
              value={hasCategoryColor}
            />
          )}

          {categories?.map((category) => (
            <Category
              key={category._id}
              category={category}
              selectedCategory={selectedCategory}
              onSelect={handleCategorySelect}
            />
          ))}
        </form>
      </div>
    </Modal>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function SelectActivity({ children, selected, onSelectedChange }) {
  const [activities, setActivities] = useState([
    "Critical & Immediate",
    "Routine Activites",
    "Personal Projects & Initiatives",
    // "Categories",
  ]);

  return (
    <Listbox value={selected} onChange={onSelectedChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-black dark:text-white">
            Type
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-secondaryLight dark:bg-lightDark py-1.5 pl-3 pr-10 text-left text-black dark:text-gray-100 shadow-sm ring-1 ring-inset ring-black dark:ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <span className="ml-3 block truncate">{selected}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2"></span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-secondaryLight dark:bg-lightDark  py-1 text-base shadow-lg ring-1 ring-white dark:ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {activities.map((activity) => (
                  <Listbox.Option
                    key={activity}
                    className={({ active }) =>
                      classNames(
                        "text-black dark:text-white relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-white dark:hover:bg-dark"
                      )
                    }
                    value={activity}
                  >
                    {activity}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

function CheckboxInput({ title, onValuehange, value }) {
  const onValueChange = (input) => {
    onValuehange(input.target.checked);
  };
  return (
    <div className="flex items-start mb-6">
      {value}
      <div className="flex items-center h-5">
        <input type="checkbox" checked={value} onChange={onValueChange} />
      </div>
      <label className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-300">
        {title}
      </label>
    </div>
  );
}

function RadioInput({ hasCustomColor, setHasCategoryColor }) {
  const onRadioChange = (event) => {
    setHasCategoryColor(event.target.value === "true");
  };

  return (
    <div>
      <h3 className="mb-2 text-sm font-medium leading-6">Color</h3>
      <ul className="p-2 rounded-xl grid w-full gap-6 md:grid-cols-2 bg-lightDark">
        <li>
          <input
            type="radio"
            id="color-category"
            name="color"
            value="false"
            className="hidden peer"
            onChange={onRadioChange}
            checked={!hasCustomColor} // Check if this option is selected
          />
          <label
            htmlFor="color-category"
            className="flex justify-center w-full px-2 py-1 text-white bg-lightDark rounded-lg cursor-pointer  peer-checked:text-black peer-checked:bg-white hover:text-white hover:bg-dark "
          >
            <div className="block">
              <div className="w-full font-semibold">Category</div>
            </div>
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="color-custom"
            name="color"
            value="true"
            className="hidden peer"
            onChange={onRadioChange}
            checked={hasCustomColor} // Check if this option is selected
          />
          <label
            htmlFor="color-custom"
            className="flex justify-center w-full px-2 py-1 text-white bg-lightDark rounded-lg cursor-pointer  peer-checked:text-black peer-checked:bg-white hover:text-white hover:bg-dark "
          >
            <div className="block">
              <div className="w-full font-semibold ">Custom</div>
            </div>
          </label>
        </li>
      </ul>
    </div>
  );
}

function Category({ category, selectedCategory, onSelect }) {
  const onSelectCategory = () => {
    if (category && category?._id === selectedCategory) {
      onSelect(null);
    } else {
      onSelect(category._id);
    }
  };

  return (
    <button
      type="button"
      onClick={onSelectCategory}
      className={[
        "px-3 py-1 rounded-md cursor-pointer inline-flex items-center gap-2 hover:bg-secondaryLight dark:hover:bg-lightDark",
        category._id === selectedCategory
          ? "bg-secondaryLight dark:bg-lightDark"
          : "",
      ].join(" ")}
    >
      <div
        className="w-2 h-2"
        style={{ backgroundColor: category.color }}
      ></div>
      <h4 className="text-sm text-black dark:text-white">{category.title}</h4>
    </button>
  );
}
