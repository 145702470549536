import { useDateCountContext } from "../../Shared/Contexts/DateCountContextProvider";
import { TimeBlocksHeader } from "./TimeBlocksHeader";
import { TimeLine } from "./TimeLine";
import { getGridTemplate } from "../Utils/functions/getGridTemplate";
import { ColumnDates } from "./ColumnDates";
import { HoursDisplay } from "./HoursDisplay";
import "./TimeBlocks.css";
import { useScrollRef } from "../Utils/CustomHooks/useScrollRef";
import { ScrollObj } from "./ScrollObj";
import { Column } from "./Column";
import { useCalWidthRef } from "../Utils/CustomHooks/useCalWidthRef";
import { useSelectedDates } from "../Utils/CustomHooks/useSelectedDates";
import { useBlocksContext } from "../../Shared/Contexts/BlocksContextProvider";
import { TargetAchievedModal } from "../../Targets/Components/TargetAchievedModal";
import { ModalContext } from "../../Shared/Contexts/ModalContextProvider";
import { useState } from "react";
import { normalizeDate } from "../../Shared/Utils/Functions/dateToNumber";

function TimeBlocker() {
  const { dateCount } = useDateCountContext();
  const { loadingBlocks } = useBlocksContext();
  const { showDates } = useSelectedDates();
  const { scrollRef } = useScrollRef();
  const { calWidthRef } = useCalWidthRef();

  const [targetModalOpen, setTargetModalOpen] = useState(false);
  const [targetDate, setTargetDate] = useState(null);
  const handleTargetClicked = (date) => {
    setTargetDate(normalizeDate(date));
    setTargetModalOpen(true);
  };

  return (
    <>
      <div className="mx-1 sm:mx-12  max-h-screen" ref={calWidthRef}>
        <TimeBlocksHeader />

        {!loadingBlocks || <div>Loading...</div>}
        {!loadingBlocks && (
          <>
            <ColumnDates
              dateCount={dateCount}
              dates={showDates}
              targetClicked={handleTargetClicked}
            />

            <div
              className="gap-2 py-2 select-none screen-height overflow-scroll hide-scroll relative "
              style={{
                display: "grid",
                gridTemplateColumns:
                  "min-content " + getGridTemplate(dateCount),
              }}
              draggable="false"
            >
              <TimeLine />
              <ScrollObj refObj={scrollRef} />
              <HoursDisplay />

              {showDates.map((date) => (
                <Column key={date.dateAsNumber} date={date.date} />
              ))}
            </div>
          </>
        )}
      </div>
      <ModalContext.Provider value={[targetModalOpen, setTargetModalOpen]}>
        <TargetAchievedModal date={targetDate} />
      </ModalContext.Provider>
    </>
  );
}

export default TimeBlocker;
