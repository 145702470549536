import React, { createContext, useContext, useState, useCallback } from "react";
import { useBlocksContext } from "./BlocksContextProvider";
import {
  patchRecurranceActionSuccess,
  postRecurrance,
} from "../../../Endpoints/Recurrance/RecurranceEndpoints";
import { useColumnStateUpdater } from "./ColumnStateContextProvider";
import { useBlockAdjustmentsContext } from "./BlockAdjustmentsProvider";

const RecurringContext = createContext();

export const RecurringContextProvider = ({ children }) => {
  const [confirmRecurringModalOpen, setConfirmRecurringModalOpen] =
    useState(false);
  const [endpoint, setEndpoint] = useState(null);
  const [callback, setCallback] = useState(null);
  const [data, setData] = useState(null);
  const [blockId, setBlockId] = useState(null);
  const [date, setDate] = useState(null);

  const { preChangeBlock } = useBlockAdjustmentsContext();
  const { blocks, setBlocks } = useBlocksContext();
  const { loadColumns } = useColumnStateUpdater();

  const handleRecurringChange = useCallback(
    (block, recurring, pattern) => {
      if (recurring) {
        postRecurrance(block._id, pattern, block.date).then(() => {
          loadColumns();
        });
      } else {
        setEndpoint("/recurrances/delete/" + block.recurranceId);
        setCallback(() => (response) => {
          loadColumns();
        });
        setData({ fromDate: block.data });
        setBlockId(block._id);
        setData({ fromDate: block.date });
        setConfirmRecurringModalOpen(true);
      }
    },
    [loadColumns]
  );

  const handleRecurranceActionSuccess = useCallback(
    (option) => {
      patchRecurranceActionSuccess(endpoint, callback, data, option).then(
        (response) => callback(response, option)
      );
    },
    [endpoint, callback, data]
  );

  const handleRecurranceActionCancel = useCallback(() => {
    if (preChangeBlock == null) return;

    setBlocks((currentBlocks) =>
      blocks.map((b) => (b._id !== blockId ? b : { ...preChangeBlock }))
    );
  }, [blocks, blockId, preChangeBlock, setBlocks]);

  return (
    <RecurringContext.Provider
      value={{
        handleRecurringChange,
        handleRecurranceActionSuccess,
        handleRecurranceActionCancel,
        confirmRecurringModalOpen,
        setConfirmRecurringModalOpen,
      }}
    >
      {children}
    </RecurringContext.Provider>
  );
};

export function useRecurringContext() {
  const context = useContext(RecurringContext);
  if (!context) {
    throw new Error(
      "useRecurringContext must be used within a RecurringContextProvider"
    );
  }
  return context;
}
