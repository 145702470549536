import {
  dayOfWeek,
  isSameDate,
  isToday,
} from "../../Shared/Utils/Functions/dateToNumber";
import { getGridTemplate } from "../Utils/functions/getGridTemplate";
import targetIconGreen from "../../../Assets/Icons/target-green.svg";
import targetIconRed from "../../../Assets/Icons/target-red.svg";
import { useTargets } from "../../Targets/Utils/CustomHooks/useTargets";
import { useWeeklyTargets } from "../../Targets/Utils/CustomHooks/useWeeklyTargets";
import { useTagContext } from "../../Shared/Contexts/TagContextProvider";
import { patchTag, postTag } from "../../../Endpoints/Tags/TagsEndpoints";
import { useEffect, useState } from "react";
import { TagItem } from "../../Shared/Components/TagItem";
import { useColumnWidth } from "../Utils/CustomHooks/useColumnWidth";
import { ModalContext } from "../../Shared/Contexts/ModalContextProvider";
import { TagModal } from "./TagModal";

export function ColumnDates({ dates, targetClicked }) {
  return (
    <div
      className="gap-2 ml-[46px] bg-transparent"
      style={{
        display: "grid",
        gridTemplateColumns: getGridTemplate(dates.length),
      }}
    >
      {dates.map((date) => (
        <DateComp
          key={date.dateAsNumber}
          date={date}
          targetClicked={targetClicked}
        />
      ))}
    </div>
  );
}

function DateComp({ date, targetClicked }) {
  const { tags, setTags, getDateTags } = useTagContext();
  const { areAchieved } = useTargets(date.date);
  const today = isToday(date.date);
  const [dateTags, setDateTags] = useState([]);
  const [tagsModalOpen, setTagsModalOpen] = useState(false);
  const { columnWidth } = useColumnWidth();

  useEffect(() => {
    setDateTags(getDateTags(new Date(date.date)));
  }, [date, tags]);

  const handleDropTagActivity = (event) => {
    const tagActivityId = event.dataTransfer.getData("tagActivityId");
    if (tagActivityId == null || tagActivityId === "") return;

    const existingTag = tags.find(
      (t) =>
        t.tagActivityId === tagActivityId &&
        isSameDate(new Date(t.date), new Date(date.date))
    );
    if (existingTag != null) {
      patchTag({ _id: existingTag._id, amount: existingTag.amount + 1 }).then(
        (response) => {
          setTags((current) =>
            current.map((t) => (t._id === response._id ? { ...response } : t))
          );
        }
      );
    } else {
      postTag({ date: date.date, tagActivityId }).then((response) => {
        setTags((current) => [...current, { ...response }]);
      });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleTagsClicked = () => {
    setTagsModalOpen(true);
  };

  return (
    <div
      className="w-full mb-5 relative"
      key={date.dateAsNumber}
      onDrop={handleDropTagActivity}
      onDragOver={handleDragOver}
    >
      <div
        className={[
          today ? "" : "opacity-40",
          "w-min mx-auto text-xl text-black dark:text-white",
        ].join(" ")}
      >
        {date.date.getDate()}
      </div>
      <div
        className={[
          today ? "" : "opacity-40",
          "w-min mx-auto text-base text-black dark:text-white",
        ].join(" ")}
      >
        {dayOfWeek(date.date)}
      </div>

      <div className="flex items-center">
        <div
          className="flex cursor-pointer truncate"
          style={{ maxWidth: `${columnWidth - 5}px` }}
          onClick={handleTagsClicked}
        >
          {dateTags.length > 0 &&
            dateTags.map((t) => <TagItem key={t._id} tag={t} />)}
        </div>
      </div>

      <button
        className={[
          "absolute right-0 bottom-6  cursor-pointer",
          // today ? "" : "opacity-40",
        ].join(" ")}
        onClick={() => targetClicked(date.date)}
      >
        {areAchieved ? (
          <img className="w-6" src={targetIconGreen} alt="" />
        ) : (
          <img className="w-6" src={targetIconRed} alt="" />
        )}
      </button>

      <ModalContext.Provider value={[tagsModalOpen, setTagsModalOpen]}>
        <TagModal date={date.date} />
      </ModalContext.Provider>
    </div>
  );
}
