export function ScrollObj({ refObj }) {
  const segmentMultiplier = 4;
  const startHour = 6;
  const topValue = "calc(0.75rem * " + startHour * segmentMultiplier + ")";

  return (
    <div
      ref={refObj}
      className="absolute w-full pointer-events-none select-none"
      style={{ top: topValue }}
    ></div>
  );
}
