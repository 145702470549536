import { useState } from "react";
import { Modal } from "../../Shared/Components/Modal";
import { useRecurringContext } from "../../Shared/Contexts/RecurringContextProvider";

export default function ConfirmRecurringOptionModal() {
  const { handleRecurranceActionSuccess, handleRecurranceActionCancel } =
    useRecurringContext();

  const handleSuccess = () => {
    handleRecurranceActionSuccess(option);
    // onSuccess(data, option);
  };
  const handleCancel = () => {
    handleRecurranceActionCancel();
    // onCancel(data);
  };

  const [option, setOption] = useState("future");

  const handleChange = (event) => {
    setOption(event.target.value);
  };

  return (
    <Modal
      title={null}
      successText={"OK"}
      onSuccess={handleSuccess}
      onCancel={handleCancel}
    >
      <div className="text-black dark:text-white">
        <h3>Confirm recurring action</h3>
        <div className="mt-5">
          <div className="radio">
            <label className="flex gap-3">
              <input
                type="radio"
                value="future"
                checked={option === "future"}
                onChange={handleChange}
              />
              This and future blocks
            </label>
          </div>
          <div className="radio">
            <label className="flex gap-3">
              <input
                type="radio"
                value="all"
                checked={option === "all"}
                onChange={handleChange}
              />
              All blocks
            </label>
          </div>
        </div>
      </div>
    </Modal>
  );
}
