import { initializeApp } from "firebase/app";
import {
  getAuth,
  signOut,
  linkWithPopup,
  OAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInAnonymously,
} from "firebase/auth";
import axiosInstance from "../../../httpInterceptor";

const firebaseConfig = {
  apiKey: "AIzaSyBvr4_T3C1vNJ3kAuoPMIWJaKfbOVOEZ0w",
  authDomain: "timeblocker-c72d3.firebaseapp.com",
  projectId: "timeblocker-c72d3",
  storageBucket: "timeblocker-c72d3.appspot.com",
  messagingSenderId: "71238432490",
  appId: "1:71238432490:web:ff25009436dc457c5fc993",
  measurementId: "G-T3MSFTNL1L",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
  const currentUser = auth?.currentUser;
  if (currentUser?.isAnonymous === true) {
    return linkAnonymousToGoogleAccount();
  }

  return signInWithPopup(auth, provider)
    .then((result) => {
      const user = result.user;
      return user.getIdToken();
    })
    .then(() => {
      return axiosInstance.post("/users/googleLogin");
    })
    .catch((error) => {});
};

export const linkAnonymousToGoogleAccount = () => {
  const currentUser = auth.currentUser;

  return linkWithPopup(currentUser, provider)
    .then((response) => {})
    .catch((error) => {
      // trying to link an anonymous account to an already existing google account. This will result in logging in as the existing account instead
      if (error.toString().includes("auth/credential-already-in-use")) {
        const credential = OAuthProvider.credentialFromError(error);
        return signInWithCredential(auth, credential).then(() => {});
      }
    });
};

export const signInAnonymous = () => {
  return signInAnonymously(auth)
    .then((result) => {
      const user = result.user;
      return user.getIdToken();
    })
    .then(() => {
      return axiosInstance.post("/users/anonymousLogin");
    })
    .catch((error) => {});
};

export const handleSignOut = () => {
  return signOut(auth);
};
