import { useDateCountContext } from "../../Shared/Contexts/DateCountContextProvider";

export function SelectCount() {
  const { dateCount, setDateCount } = useDateCountContext();

  const handleCountChange = (e) => {
    setDateCount(Number(e.target.value));
  };

  return (
    <div className="custom-select">
      <select
        value={dateCount ?? ""}
        onChange={(e) => handleCountChange(e)}
        className="border-none"
      >
        <option value={1}>1 Day</option>
        <option value={3}>3 Day</option>
        <option value={7}>Week</option>
      </select>
    </div>
  );
}
