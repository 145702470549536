import { useEffect, useState } from "react";
import { TargetMinMax, TargetType } from "./TargetType";
import { TargetRange } from "./TargetRange";
import { DatePickerComp } from "../../Statistics/Components/DateChooser";
import { normalizeDate } from "../../Shared/Utils/Functions/dateToNumber";
import "./checkbox.css";

const defaultTargetRange = "daily";
const defaultCustomRange = {
  mon: false,
  tue: false,
  wed: false,
  thu: false,
  fri: false,
  sat: false,
  sun: false,
};
const defaultTargetType = "amount";
const defaultTargetMinMax = "min";
const defaultTargetAmount = 1;
const defaultEndDate = null;

export function AddTagTargetForm({ editTarget, onCancel, onSuccess, onEdit }) {
  useEffect(() => {
    if (editTarget == null) {
      reset();
    } else {
      setTargetRange(editTarget.range);
      if (editTarget.range === "custom")
        setTargetCustomRange(editTarget.customRange);
      else setTargetCustomRange(defaultCustomRange);

      setTargetType(editTarget.type);
      setTargetAmount(editTarget.amount);
      setStartDate(normalizeDate(new Date(editTarget.startDate)));
      if (editTarget.endDate != null) setEndDate(editTarget.endDate);
    }
  }, [editTarget]);

  const [targetRange, setTargetRange] = useState(defaultTargetRange);
  const [targetCustomRange, setTargetCustomRange] =
    useState(defaultCustomRange);
  const [targetType, setTargetType] = useState(defaultTargetType);
  const [targetMinMax, settargetMinMax] = useState(defaultTargetMinMax);
  const [targetAmount, setTargetAmount] = useState(defaultTargetAmount);
  const [startDate, setStartDate] = useState(normalizeDate(new Date()));
  const [endDate, setEndDate] = useState(defaultEndDate);

  const handleRangeChange = (range) => {
    setTargetRange(range);
  };
  const handleTargetTypeChange = (type) => {
    setTargetType(type);
  };
  const handleTargetMinMaxChange = (value) => {
    settargetMinMax(value);
  };
  const handleTargetAmountChange = (value) => {
    setTargetAmount(value);
  };

  const handleStartDateChange = (value) => {
    setStartDate(normalizeDate(new Date(value)));
  };
  const handleEndDateChange = (value) => {
    setEndDate(normalizeDate(new Date(value)));
  };
  const handleCustomRangeChange = (value) => {
    setTargetCustomRange(value);
  };
  const reset = () => {
    setTargetRange(defaultTargetRange);
    setTargetType(defaultTargetType);
    setTargetAmount(defaultTargetAmount);
    setStartDate(normalizeDate(new Date()));
    setEndDate(defaultEndDate);
    setTargetCustomRange(defaultCustomRange);
  };
  const HandleCancel = () => {
    onCancel();
    reset();
  };
  const handleSuccess = () => {
    if (editTarget === null) {
      onSuccess({
        range: targetRange,
        customRange: targetRange === "custom" ? targetCustomRange : null,
        type: targetType,
        minMax: targetMinMax,
        amount: targetAmount,
        startDate,
      });
    } else {
      onEdit({
        ...editTarget,
        range: targetRange,
        customRange: targetRange === "custom" ? targetCustomRange : null,
        type: targetType,
        minMax: targetMinMax,
        amount: targetAmount,
        startDate,
        endDate,
      });
    }
    reset();
  };

  return (
    <div>
      <div className="flex justify-between">
        <TargetRange
          currentRange={targetRange}
          onTargetRangeChange={handleRangeChange}
        />
        <div className="flex gap-1">
          <button
            onClick={HandleCancel}
            className="inline-flex w-full justify-center items-center rounded-md bg-secondaryLight dark:bg-lightDark px-3 py-1 text-sm font-semibold text-black dark:text-white shadow-sm ring-1 ring-inset ring-black dark:ring-gray-300 hover:bg-white dark:hover:bg-dark sm:mt-0 sm:w-auto"
          >
            Cancel
          </button>
          <button
            onClick={handleSuccess}
            className="inline-flex w-full justify-center items-center rounded-md bg-secondaryLight dark:bg-lightDark px-3 py-1 text-sm font-semibold text-black dark:text-white shadow-sm ring-1 ring-inset ring-green-600 dark:ring-green-600 hover:bg-white dark:hover:bg-dark sm:mt-0 sm:w-auto"
          >
            {editTarget === null ? "Add" : "Save"}
          </button>
        </div>
      </div>

      {targetRange === "custom" && (
        <div className="mt-2">
          <CustomRange
            value={targetCustomRange}
            onValueChange={handleCustomRangeChange}
          />
        </div>
      )}

      <div className="mt-2 flex gap-1 items-center">
        <span>StartDate:</span>
        <span className="px-2 py-1 rounded text-black dark:text-white bg-secondaryLight  dark:bg-dark">
          {startDate == null ? "" : new Date(startDate).toLocaleDateString()}
        </span>
        <DatePicker value={startDate} onValueChange={handleStartDateChange} />
      </div>
      {endDate !== null && (
        <div className="mt-2 flex gap-1 items-center">
          <span>EndDate:</span>
          <span className="px-2 py-1 rounded text-black dark:text-white bg-secondaryLight  dark:bg-dark">
            {endDate == null ? "" : new Date(endDate).toLocaleDateString()}
          </span>
          <DatePicker value={endDate} onValueChange={handleEndDateChange} />
        </div>
      )}

      <div className="flex flex-col gap-1">
        <div className="mt-2 w-min">
          <TargetMinMax
            currentType={targetMinMax}
            onTypeChange={handleTargetMinMaxChange}
          />
        </div>
        <div className="mt-2">
          <div className="flex gap-1 items-center">
            <TagAmountInput
              value={targetAmount}
              onValueChange={handleTargetAmountChange}
            />
            <span>Tags</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function DatePicker({ value, onValueChange }) {
  return (
    <div>
      <DatePickerComp startDate={value} onDateChange={onValueChange} />
    </div>
  );
}

function TagAmountInput({ value, onValueChange }) {
  const handleValueChange = (event) => {
    onValueChange(event.target.value);
  };
  return (
    <div className="w-28">
      <input
        className="h-10 block bg-secondaryLight dark:bg-lightDark w-full rounded-md border-0 py-1.5 pl-7 p-6 pr-1 text-black dark:text-gray-100 ring-1 ring-inset ring-black dark:ring-gray-300 placeholder:text-gray-600 dark:placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
        type="number"
        value={value}
        step={1}
        min={0}
        onChange={handleValueChange}
      />
    </div>
  );
}

function CustomRange({ value, onValueChange }) {
  const handleChange = (day, event) => {
    const updatedValues = { ...value };
    updatedValues[day] = event.target.checked;
    onValueChange(updatedValues);
  };
  return (
    <div>
      <div className="flex gap-2">
        {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map((day) => (
          <div key={day} className="flex flex-col items-center">
            <input
              onChange={(event) => handleChange(day, event)}
              id={day}
              type="checkbox"
              checked={value[day] || false} // Ensure a boolean is always passed
              className="custom-checkbox appearance-none cursor-pointer w-6 h-6 dark:bg-dark border-2 border-gray-400 rounded-md checked:bg-blue-500 checked:border-blue-500 focus:outline-none"
            />
            <label htmlFor={day}>
              {day.charAt(0).toUpperCase() + day.slice(1)}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
