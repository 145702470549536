import { useEffect, useRef, useState } from "react";

export function useScrollRef() {
  const scrollRef = useRef(null);
  const [refCurrent, setRefCurrent] = useState(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    setRefCurrent(scrollRef.current);
  });
  useEffect(() => {
    if (refCurrent && scrollRef.current) {
      scrollRef.current.scrollIntoView();
      setHasScrolled(true);
    }
  }, [refCurrent]);

  return { scrollRef, hasScrolled };
}
