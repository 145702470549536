import { createContext, useContext, useEffect, useState } from "react";
import { useActivityContext } from "./ActivitiesContextProvider";

const BlocksContext = createContext(null);

export function BlocksContextProvider({ children }) {
  const [blocks, setBlocks] = useState([]);
  const [loadingBlocks, setLoadingBlocks] = useState([]);

  return (
    <BlocksContext.Provider
      value={{ blocks, setBlocks, loadingBlocks, setLoadingBlocks }}
    >
      {children}
    </BlocksContext.Provider>
  );
}

export function useBlocksContext() {
  const context = useContext(BlocksContext);
  if (!context) {
    throw new Error(
      "useBlocksContext must be used within a BlocksContextProvider"
    );
  }
  return context;
}
