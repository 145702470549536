import { useEffect, useState } from "react";

export function ActivitiesHeader({ filter, setFilter }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = (e) => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFilterChange = (value) => {
    setFilter((current) => ({ ...current, show: value.target.value }));
  };

  return (
    <div className="px-12 py-8 flex">
      <div>
        <select
          value={filter.show}
          onChange={handleFilterChange}
          className="px-6 rounded-md text-black border-black bg-white dark:bg-lightDark dark:text-white dark:border-white"
        >
          <option value={"active"}>Active</option>
          <option value={"done"}>Done</option>
          <option value={"all"}>All</option>
        </select>
      </div>

      {windowWidth < 600 && (
        <div className="">{/* <HeaderNavigationMenu /> */}</div>
      )}
    </div>
  );
}
