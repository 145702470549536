import { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useModalContext } from "../Contexts/ModalContextProvider";

export function Modal({
  title,
  successText,
  children,
  onSuccess,
  onCancel,
  successColor = "bg-green-600",
  successHoverColor = "bg-green-500",
  hasSuccess = true,
  overFlowHidden = true,
}) {
  const [modalOpen, setModalOpen] = useModalContext();

  const cancelButtonRef = useRef(null);

  const handleSuccess = () => {
    onSuccess();
    setModalOpen(false);
  };

  const handleCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }
    setModalOpen(false);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };
  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999]"
        initialFocus={cancelButtonRef}
        onClose={handleCancel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={[
                  "relative transform  rounded-lg bg-white dark:bg-dark text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg",
                  overFlowHidden ? "overflow-hidden" : "",
                ].join(" ")}
              >
                <div
                  className="bg-white dark:bg-dark px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
                  onClick={stopPropagation}
                >
                  {/* <div className="sm:flex sm:items-start w-full"> */}
                  <div className="w-full">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      {title != null && (
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-black dark:text-white"
                        >
                          {title}
                        </Dialog.Title>
                      )}

                      <div className="mt-2">{children}</div>
                    </div>
                  </div>
                </div>
                <div
                  className="bg-secondaryLight dark:bg-lightDark px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
                  onClick={stopPropagation}
                >
                  {hasSuccess && (
                    <button
                      type="button"
                      className={[
                        "inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto",
                        successColor,
                        successHoverColor,
                      ].join(" ")}
                      onClick={handleSuccess}
                    >
                      {successText}
                    </button>
                  )}
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-secondaryLight dark:bg-lightDark px-3 py-2 text-sm font-semibold text-black dark:text-white shadow-sm ring-1 ring-inset ring-black dark:ring-gray-300 hover:bg-white dark:hover:bg-dark sm:mt-0 sm:w-auto"
                    onClick={handleCancel}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
