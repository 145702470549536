import { useState } from "react";
import { useEffect } from "react";

export function useCurrentTime() {
  const [currentDateTime, setCurrentDateTime] = useState();
  useEffect(() => {
    const date = new Date();

    // for testing
    // date.setHours(15);
    // date.setMinutes(45);

    setCurrentDateTime(date);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000); // 60000 milliseconds = 1 minute

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);

  return currentDateTime;
}
