import { useEffect, useState } from "react";
import { useStatisticsContext } from "../../../Shared/Contexts/StatisticsContextProvider";
import { useTimeSpanContext } from "../../../Shared/Contexts/TimeSpanContextProvider";
import {
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  addWeeks,
  startOfYear,
  addMonths,
  format,
  isSameDay,
  isSameWeek,
  isSameMonth,
} from "date-fns";

export function useBlockedTimeStats() {
  const { stats } = useStatisticsContext();
  const { timeSpan } = useTimeSpanContext();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!stats) return;

    let labels = [];
    let timeSpanData = [];

    if (timeSpan === "week") {
      const startOfCurrentWeek = startOfWeek(
        stats.timePerDate[0] == null ? new Date() : stats.timePerDate[0].date,
        { weekStartsOn: 1 }
      );
      labels = Array.from({ length: 7 }, (_, i) =>
        format(addDays(startOfCurrentWeek, i), "yyyy-MM-dd")
      );

      timeSpanData = labels.map((dateLabel) => {
        const matchingEntry = stats.timePerDate.find((entry) =>
          isSameDay(new Date(entry.date), new Date(dateLabel))
        );
        return matchingEntry ? matchingEntry.totalTime / 60 : 0;
      });
    } else if (timeSpan === "month") {
      const startOfCurrentMonth = startOfMonth(
        stats.timePerDate[0] == null ? new Date() : stats.timePerDate[0].date
      );
      const endOfCurrentMonth = endOfMonth(
        stats.timePerDate[0] == null ? new Date() : stats.timePerDate[0].date
      );

      labels = [];
      let currentWeekStart = startOfWeek(startOfCurrentMonth, {
        weekStartsOn: 1,
      });
      while (currentWeekStart <= endOfCurrentMonth) {
        labels.push(format(currentWeekStart, "yyyy-MM-dd")); // Ensure date format
        currentWeekStart = addWeeks(currentWeekStart, 1);
      }

      timeSpanData = labels.map((weekStart) => {
        const matchingEntry = groupEntriesByWeek(stats.timePerDate).find(
          (entry) =>
            isSameWeek(new Date(entry.date), new Date(weekStart), {
              weekStartsOn: 1,
            })
        );
        return matchingEntry ? matchingEntry.totalTime / 60 : 0;
      });
    } else if (timeSpan === "year") {
      const startOfCurrentYear = startOfYear(
        stats.timePerDate[0] == null ? new Date() : stats.timePerDate[0].date
      );
      labels = Array.from({ length: 12 }, (_, i) =>
        format(addMonths(startOfCurrentYear, i), "yyyy-MM")
      );

      timeSpanData = labels.map((monthLabel) => {
        const monthStart = new Date(monthLabel);

        const matchingEntry = groupEntriesByMonth(stats.timePerDate).find(
          (entry) => isSameMonth(new Date(entry.date), monthStart)
        );

        return matchingEntry ? matchingEntry.totalTime / 60 : 0;
      });
    }

    setData({
      labels,
      datasets: [
        {
          label: "Total Time (hours)",
          data: timeSpanData,
          backgroundColor: "#4095BA",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
          barThickness: 20,
        },
      ],
    });
  }, [stats, timeSpan]);

  let text;
  if (timeSpan === "week") text = "Total Time Per Date";
  else if (timeSpan === "month") text = "Total Time Per Week";
  else if (timeSpan === "year") text = "Total Time Per Month";

  return { data, text };
}

function groupEntriesByWeek(entries) {
  const weeklyData = [];

  // Object to hold weekly data with week start date as the key
  const entriesByWeek = {};

  entries.forEach(({ date, totalTime }) => {
    const weekStartDate = getWeekStart(new Date(date)); // Function to get the week's start date (e.g., Monday)

    const weekKey = weekStartDate.toISOString().split("T")[0];

    // Initialize the week entry if it doesn't exist
    if (!entriesByWeek[weekKey]) {
      entriesByWeek[weekKey] = { date: weekKey, totalTime: 0 };
    }

    // Add the time to the total for that week
    entriesByWeek[weekKey].totalTime += totalTime;
  });

  // Convert entriesByWeek object into an array of objects
  for (const week of Object.values(entriesByWeek)) {
    weeklyData.push(week);
  }

  // Sort weekly data by date in ascending order
  weeklyData.sort((a, b) => new Date(a.date) - new Date(b.date));

  return weeklyData;
}

// Helper function to get the start date of the week (assuming Monday as start)
function getWeekStart(date) {
  const day = date.getUTCDay(); // Day of week (0=Sunday, 6=Saturday)
  const diff = (day === 0 ? -6 : 1) - day; // Adjust to get to Monday
  const weekStartDate = new Date(date);
  weekStartDate.setUTCDate(date.getUTCDate() + diff);
  weekStartDate.setUTCHours(0, 0, 0, 0);
  return weekStartDate;
}

function groupEntriesByMonth(entries) {
  const monthlyData = [];

  // Object to hold monthly data with month-year as the key
  const entriesByMonth = {};

  entries.forEach(({ date, totalTime }) => {
    const entryDate = new Date(date);
    const monthKey = `${entryDate.getUTCFullYear()}-${(
      entryDate.getUTCMonth() + 1
    )
      .toString()
      .padStart(2, "0")}`; // Format as YYYY-MM

    // Initialize the month entry if it doesn't exist
    if (!entriesByMonth[monthKey]) {
      entriesByMonth[monthKey] = { date: monthKey, totalTime: 0 };
    }

    // Add the time to the total for that month
    entriesByMonth[monthKey].totalTime += totalTime;
  });

  // Convert entriesByMonth object into an array of objects
  for (const month of Object.values(entriesByMonth)) {
    monthlyData.push(month);
  }

  // Sort monthly data by date in ascending order
  monthlyData.sort((a, b) => new Date(a.date) - new Date(b.date));

  return monthlyData;
}
