export function TargetType({ currentType, onTypeChange }) {
  const handleTypeChange = (event) => {
    onTypeChange(event.target.value);
  };

  return (
    <div className="px-4 py-2 rounded bg-se bg-secondaryLight dark:bg-dark select-none">
      <ul className="flex gap-2">
        <li>
          <input
            type="radio"
            id="targetTimeType"
            name="targetTimeType"
            value={"time"}
            className="hidden peer"
            checked={currentType === "time"}
            onChange={handleTypeChange}
          />
          <label
            htmlFor="targetTimeType"
            className="px-2 py-1 text-sm rounded cursor-pointer text-black peer-checked:bg-lightDark peer-checked:text-white dark:text-white dark:peer-checked:bg-white dark:peer-checked:text-black"
          >
            Time
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="targetBlockType"
            name="targetBlockType"
            value={"block"}
            className="hidden peer"
            checked={currentType === "block"}
            onChange={handleTypeChange}
          />
          <label
            htmlFor="targetBlockType"
            className="px-2 py-1 text-sm rounded cursor-pointer text-black peer-checked:bg-lightDark peer-checked:text-white dark:text-white dark:peer-checked:bg-white dark:peer-checked:text-black"
          >
            Block
          </label>
        </li>
      </ul>
    </div>
  );
}
export function TargetMinMax({ currentType, onTypeChange }) {
  const handleTypeChange = (event) => {
    onTypeChange(event.target.value);
  };

  return (
    <div className="px-4 py-2 rounded bg-se bg-secondaryLight dark:bg-dark select-none">
      <ul className="flex gap-2">
        <li>
          <input
            type="radio"
            id="targetMin"
            name="targetMin"
            value={"min"}
            className="hidden peer"
            checked={currentType === "min"}
            onChange={handleTypeChange}
          />
          <label
            htmlFor="targetMin"
            className="px-2 py-1 text-sm rounded cursor-pointer text-black peer-checked:bg-lightDark peer-checked:text-white dark:text-white dark:peer-checked:bg-white dark:peer-checked:text-black"
          >
            Min
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="targetMax"
            name="targetMax"
            value={"max"}
            className="hidden peer"
            checked={currentType === "max"}
            onChange={handleTypeChange}
          />
          <label
            htmlFor="targetMax"
            className="px-2 py-1 text-sm rounded cursor-pointer text-black peer-checked:bg-lightDark peer-checked:text-white dark:text-white dark:peer-checked:bg-white dark:peer-checked:text-black"
          >
            Max
          </label>
        </li>
      </ul>
    </div>
  );
}
