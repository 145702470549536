import { useEffect, useState } from "react";
import { useActivityContext } from "../../Contexts/ActivitiesContextProvider";

export function useActivityColor(inputActivity) {
  const { activities, activitiesLoaded } = useActivityContext();

  const [activity, setActivity] = useState(null);
  const [color, setColor] = useState(null);

  useEffect(() => {
    setActivity(inputActivity);
  }, [inputActivity]);

  const getActivityColor = (activityId) => {
    const activity = activities.find((a) => a._id === activityId);

    if (activity == null) return null;

    if (activity.type === "Categories" || activity.hasCustomColor) {
      return activity.color;
    }

    const category = activities.find((a) => a._id === activity.categoryId);
    return category?.color;
  };

  useEffect(() => {
    if (activity == null || activitiesLoaded === false) return;

    const color = getActivityColor(activity._id);
    setColor(color);
  }, [activity, activitiesLoaded]);

  // Define a method to retrieve the color based on activityId

  return { color, getActivityColor, colorsLoaded: activitiesLoaded };
}
