import { useEffect, useState } from "react";
import { useStatisticsContext } from "../../../Shared/Contexts/StatisticsContextProvider";
import { useTimeSpanContext } from "../../../Shared/Contexts/TimeSpanContextProvider";
import { fixTimeZone } from "../../../Shared/Utils/Functions/fixTimeZone";

export function useCategoryStats(selectedCategoryInput) {
  const { stats } = useStatisticsContext();
  const { timeSpan } = useTimeSpanContext();
  const [timeUnit, setTimeUnit] = useState(null);
  const [dataList, setDataList] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    setSelectedCategory(selectedCategoryInput);
  }, [selectedCategoryInput]);

  useEffect(() => {
    if (stats == null || selectedCategory == null) return;
    if (timeSpan === "week") {
      setTimeUnit({
        unit: "day", // Group by day
      });
      setDataList(stats.timePerCategoryPerDay[selectedCategory._id]);
    }
    if (timeSpan === "month") {
      setTimeUnit({
        unit: "week", // Group by day
        tooltipFormat: "wo", // Displays week number in tooltip
        displayFormats: {
          week: "'Week' w", // Displays as "Week 1", "Week 2", etc.
        },
      });
      setDataList(
        groupPerWeek(stats.timePerCategoryPerDay, selectedCategory._id)
      );
    }
    if (timeSpan === "year") {
      setTimeUnit({
        unit: "month",
        tooltipFormat: "MMMM", // Displays full month name in tooltip (e.g., "January")
        displayFormats: {
          month: "MMMM yyyy", // Axis labels as "January 2024", "February 2024", etc.
        },
        round:
          timeSpan === "week" ? "day" : timeSpan === "month" ? "week" : "month",
      });
      setDataList(
        groupTimePerMonth(stats.timePerCategoryPerDay, selectedCategory._id)
      );
    }
  }, [stats, timeSpan, selectedCategory]);

  return { timeUnit, dataList };
}

function groupPerWeek(timeMap, categoryId) {
  const weeklyActivityMap = [];

  const entries = timeMap[categoryId] || [];
  const entriesByWeek = {};

  entries.forEach(({ date, totalTime }) => {
    const weekInfo = getWeekStartAndEnd(new Date(date)); // helper function to get week start and end dates
    const weekKey = `${weekInfo.startDate.toISOString().split("T")[0]}_${
      weekInfo.endDate.toISOString().split("T")[0]
    }`;

    if (!entriesByWeek[weekKey]) {
      entriesByWeek[weekKey] = { totalTime: 0, ...weekInfo };
    }

    entriesByWeek[weekKey].totalTime += totalTime;
  });

  for (const [weekKey, { startDate, endDate, totalTime }] of Object.entries(
    entriesByWeek
  )) {
    weeklyActivityMap.push({
      date: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
      totalTime,
    });
  }

  // Sort the array of dates in ascending order
  weeklyActivityMap.sort((a, b) => new Date(a.date) - new Date(b.date));

  return weeklyActivityMap;
}

function groupTimePerMonth(timeMap, categoryId) {
  const monthlyActivityMap = [];

  const dailyEntries = timeMap[categoryId] || [];
  const entriesByMonth = {};

  dailyEntries.forEach(({ date, endDate, totalTime }) => {
    const start = new Date(date);
    const monthKey = `${start.getUTCFullYear()}-${String(
      start.getUTCMonth() + 1
    ).padStart(2, "0")}`;

    if (!entriesByMonth[monthKey]) {
      const monthStartDate = new Date(
        start.getUTCFullYear(),
        start.getUTCMonth(),
        1
      );
      const monthEndDate = new Date(
        start.getUTCFullYear(),
        start.getUTCMonth() + 1,
        0
      );
      fixTimeZone(monthStartDate);
      fixTimeZone(monthEndDate);

      entriesByMonth[monthKey] = {
        date: monthStartDate,
        endDate: monthEndDate,
        totalTime: 0,
      };
    }

    entriesByMonth[monthKey].totalTime += totalTime;
  });

  for (const { date, endDate, totalTime } of Object.values(entriesByMonth)) {
    monthlyActivityMap.push({
      date: date.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
      totalTime,
    });
  }

  // Sort dates in ascending order
  monthlyActivityMap.sort((a, b) => new Date(a.date) - new Date(b.date));

  return monthlyActivityMap;
}

function getWeekStartAndEnd(date) {
  const tempDate = new Date(date);
  const dayOfWeek = tempDate.getUTCDay() || 7; // Adjust so that Sunday becomes 7, not 0
  tempDate.setUTCDate(tempDate.getUTCDate() - dayOfWeek + 1); // Start of ISO week (Monday)
  const startDate = new Date(tempDate);

  const endDate = new Date(tempDate);
  endDate.setUTCDate(endDate.getUTCDate() + 6); // End of ISO week (Sunday)

  return { startDate, endDate };
}
