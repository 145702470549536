import { getContrastingColor } from "../../Shared/Utils/Functions/getContrastingColor";

export function BlockPreview({ title, color }) {
  const textColor = getContrastingColor(color);

  return (
    <div>
      <div className="text-black dark:text-white mb-1">Preview:</div>
      <div
        className={
          "px-1 w-[150px] h-[160px]  rounded-md border border-1 border-black dark:border-white"
        }
        style={{ backgroundColor: color, color: textColor }}
      >
        <div>
          <span> {title === "" ? "(No title)" : title}</span>
        </div>
        <div>09.30 - 11.15</div>
      </div>
    </div>
  );
}
