const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
// const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function dateToNumber(date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0, so we add 1
  const dd = String(date.getDate()).padStart(2, "0");

  return Number(`${yyyy}${mm}${dd}`);
}

export function isToday(date) {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function gte(date1, date2) {
  // Remove time from the dates by setting hours, minutes, seconds, and milliseconds to zero
  const normalizedDate1 = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  );
  const normalizedDate2 = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  );

  // Compare the dates
  return normalizedDate1 >= normalizedDate2;
}
export function gt(date1, date2) {
  // Remove time from the dates by setting hours, minutes, seconds, and milliseconds to zero
  const normalizedDate1 = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  );
  const normalizedDate2 = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  );

  // Compare the dates
  return normalizedDate1 > normalizedDate2;
}
export function lte(date1, date2) {
  // Remove time from the dates by setting hours, minutes, seconds, and milliseconds to zero
  const normalizedDate1 = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  );
  const normalizedDate2 = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  );

  // Compare the dates
  return normalizedDate1 <= normalizedDate2;
}
export function lt(date1, date2) {
  // Remove time from the dates by setting hours, minutes, seconds, and milliseconds to zero
  const normalizedDate1 = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  );
  const normalizedDate2 = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  );

  // Compare the dates
  return normalizedDate1 < normalizedDate2;
}

export function dayOfWeek(date) {
  return weekdays[date.getDay()];
}

export function isSameDate(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export function normalizeDate(date) {
  return date;
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  // const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  // newDate.setTime(newDate.getTime() - newDate.getTimezoneOffset() * 60 * 1000);
  // return newDate;
}

export function toLocalISOString(date) {
  const pad = (number) => (number < 10 ? `0${number}` : number);

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
    date.getSeconds()
  )}.${(date.getMilliseconds() / 1000).toFixed(3).slice(2, 5)}`;
}

export function isDateThisWeek(date) {
  const now = normalizeDate(new Date());
  const startOfWeek = new Date(now);

  // Adjust day calculation to make Monday the start of the week
  const dayOfWeek = now.getDay();
  const daysToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Sunday becomes -6, others adjust to Monday
  startOfWeek.setDate(now.getDate() + daysToMonday);

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  return gte(date, startOfWeek) && lte(date, endOfWeek);
}
