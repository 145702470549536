import { useEffect, useState } from "react";
import { useBlocksContext } from "../../Shared/Contexts/BlocksContextProvider";
import { Block } from "./Block";
import { isSameDate } from "../../Shared/Utils/Functions/dateToNumber";
import { useBlockAdjustmentsContext } from "../../Shared/Contexts/BlockAdjustmentsProvider";
import { useTagContext } from "../../Shared/Contexts/TagContextProvider";
import { patchTag, postTag } from "../../../Endpoints/Tags/TagsEndpoints";

export function Column({ date }) {
  const { tags, setTags } = useTagContext();
  const { blocks } = useBlocksContext();
  const [columnBlocks, setColumnBlocks] = useState([]);
  useEffect(() => {
    setColumnBlocks(blocks.filter((b) => isSameDate(b.date, date)));
  }, [blocks, date]);

  const isUpdating = false;

  const { setEnteredSegment, onSegmentPressed } = useBlockAdjustmentsContext();

  const handleDropTagActivity = (event) => {
    const tagActivityId = event.dataTransfer.getData("tagActivityId");
    if (tagActivityId == null) return;

    const existingTag = tags.find(
      (t) =>
        t.tagActivityId === tagActivityId &&
        isSameDate(new Date(t.date), new Date(date))
    );
    if (existingTag != null) {
      patchTag({ _id: existingTag._id, amount: existingTag.amount + 1 }).then(
        (response) => {
          setTags((current) =>
            current.map((t) => (t._id === response._id ? { ...response } : t))
          );
        }
      );
    } else {
      postTag({ date, tagActivityId }).then((response) => {
        setTags((current) => [...current, { ...response }]);
      });
    }
  };

  const onEnter = (segmentIndex) => {
    setEnteredSegment((current) => ({
      segment: segmentIndex,
      date,
    }));
  };

  const onMouseDown = (segmentIndex) => {
    onSegmentPressed(segmentIndex, date);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div
        className="flex flex-col w-full relative"
        draggable="false"
        onDrop={handleDropTagActivity}
        onDragOver={handleDragOver}
      >
        {renderSegments(onMouseDown, onEnter)}
        {columnBlocks.map((block) => {
          // const isUpdating = block.isRecurrance
          // ? updatingRecurranceIds[block.recurranceId] ?? false
          // : updatingBlockIds[block._id] ?? false;
          return (
            <Block key={block._id} block={block} isUpdating={isUpdating} />
          );
        })}
      </div>
    </div>
  );
}

function renderSegments(onMouseDown, onEnter) {
  const groupedSegments = [];

  for (let groupIndex = 0; groupIndex < 24; groupIndex++) {
    const group = [];

    for (let i = 0; i < 4; i++) {
      const segmentIndex = groupIndex * 4 + i;
      group.push(
        <div
          onMouseDown={(e) => {
            if (e.button === 0) onMouseDown(segmentIndex);
          }}
          onMouseEnter={() => onEnter(segmentIndex)}
          draggable="false"
          key={segmentIndex}
          className={["w-full h-3 cursor-pointer select-none "].join(" ")}
        >
          &nbsp;
        </div>
      );
    }

    groupedSegments.push(
      <div
        key={`group-${groupIndex}`}
        className="flex flex-col rounded-[3px]"
        style={{ boxShadow: "0px 3px 4.2px 1px rgba(131, 130, 127, 0.1)" }}
      >
        {group}
      </div>
    );
  }

  return <div className="flex flex-col gap-1">{groupedSegments}</div>;
}
