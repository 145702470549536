import { useEffect, useState } from "react";
import { Modal } from "../../Shared/Components/Modal";
import { useTagContext } from "../../Shared/Contexts/TagContextProvider";
import { TagItem } from "../../Shared/Components/TagItem";
import { deleteTag, patchTag } from "../../../Endpoints/Tags/TagsEndpoints";

export function TagModal({ date: inputDate }) {
  const { tags, setTags, getDateTags } = useTagContext();
  const [date, setDate] = useState(null);
  const [dateTags, setDateTags] = useState([]);

  useEffect(() => {
    if (inputDate == null) return;
    setDate(new Date(inputDate));
  }, [inputDate]);

  useEffect(() => {
    if (date == null) return;

    setDateTags(getDateTags(date));
  }, [date, tags]);

  const handleCancel = () => {};

  const handleAmountChange = (tag, newAmount) => {
    if (newAmount === 0) {
      deleteTag(tag).then((response) => {
        setTags((current) => current.filter((t) => t._id !== tag._id));
      });
      return;
    }

    patchTag({ _id: tag._id, amount: newAmount }).then((response) => {
      setTags((current) =>
        current.map((t) => (t._id === response._id ? { ...response } : t))
      );
    });
  };

  return (
    <Modal title={"Tags"} hasSuccess={false} onCancel={handleCancel}>
      <div className="flex flex-col gap-3">
        {dateTags.length > 0 &&
          dateTags.map((t) => (
            <TagItem
              key={t._id}
              tag={t}
              onlyIcon={false}
              amountChange={handleAmountChange}
            />
          ))}
      </div>
    </Modal>
  );
}
