import "./TimeSpan.css";
import { useTimeSpanContext } from "../../Shared/Contexts/TimeSpanContextProvider";

export function TimeSpan() {
  const { timeSpan, setTimeSpan } = useTimeSpanContext();

  const handleChange = (event) => {
    setTimeSpan(event.target.value);
  };

  return (
    <div>
      <select
        id="time-span-select"
        value={timeSpan}
        onChange={handleChange}
        className="py-1 w-24 border bg-white border-black dark:border-white text-black time-span-select dark:text-white"
      >
        <option value="week">Week</option>
        <option value="month">Month</option>
        <option value="year">Year</option>
      </select>
    </div>
  );
}
