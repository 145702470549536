import {
  addBlock,
  deleteBlock,
  patchBlock,
} from "../../../../Endpoints/Blocks/BlocksEndpoints";
import {
  AddBlockFromRecurrance,
  EditRecurrance,
} from "../../../../Endpoints/Recurrance/RecurranceEndpoints";
import { useBlocksContext } from "../../Contexts/BlocksContextProvider";
import { gte } from "../Functions/dateToNumber";
import { isRecurrance } from "../Functions/isRecurrance";

export function useBlockUpdater() {
  const { setBlocks } = useBlocksContext();

  const updateBlock = (updateData, block) => {
    const { _id, date, recurranceId, selectedRadioOption } = block;

    if (isRecurrance(block)) {
      EditRecurrance(
        { ...updateData, date },
        recurranceId,
        selectedRadioOption
      ).then((response) => {
        setBlocks((current) => {
          return current.map((b) => {
            return {
              ...b,
              ...((selectedRadioOption === "all" ||
                gte(new Date(b.date), new Date(date))) &&
                b.recurranceId === recurranceId && {
                  ...updateData,
                  recurranceId: response.data._id,
                }),
            };
          });
        });
      });
      return;
    }

    if (block.isRecurrance) {
      AddBlockFromRecurrance({ date, ...updateData }, recurranceId).then(
        (response) => {
          setBlocks((current) =>
            current.map((b) =>
              b._id === block._id
                ? {
                    ...b,
                    ...updateData,
                    recurranceId: null,
                    isRecurrance: false,
                    _id: response.data._id,
                  }
                : b
            )
          );
        }
      );
    } else {
      patchBlock({ _id, date, ...updateData }).then((response) => {
        setBlocks((current) =>
          current.map((b) =>
            b._id === block._id
              ? {
                  ...b,
                  ...updateData,
                }
              : b
          )
        );
      });
    }
  };

  const postBlock = (block) => {
    addBlock(block).then((response) => {
      setBlocks((current) =>
        current.map((b) =>
          b._id === block._id ? { ...b, _id: response.data._id } : b
        )
      );
    });
  };

  const removeBlock = (block) => {
    deleteBlock(block);
  };

  return { updateBlock, postBlock, removeBlock };
}
