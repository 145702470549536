import { useEffect, useState, useRef } from "react";
import { useActiveContextMenuContext } from "../../../Shared/Contexts/ActiveContextMenuProvider";

export function useContextMenu(block) {
  const { activeContextBlock, setActiveContextBlock } =
    useActiveContextMenuContext();

  const [contextBlock, setContextBlock] = useState(block);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [open, setOpen] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    setContextBlock(block);
  }, [block]);

  useEffect(() => {
    if (open && activeContextBlock?._id !== contextBlock._id) {
      setOpen(false);
    }
  }, [activeContextBlock, open]);

  const handleClick = (event) => {
    if (menuRef?.current && !menuRef?.current?.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const openMenu = (event, blockRef) => {
    const rect = blockRef.current.getBoundingClientRect();

    const isLeft = event.clientX < window?.innerWidth / 2;
    let x = event.pageX - rect.left - window.scrollX;
    const contextMenuWidth = 222;
    if (!isLeft) {
      x = event.pageX - rect.left - contextMenuWidth - window.scrollX;
    }
    const y = event.pageY - rect.top - window.scrollY;

    setLeft(x);
    setTop(y);
    setOpen(true);
    setActiveContextBlock(block);
  };

  return { menuRef, openMenu, left, top, open, contextBlock };
}

// export function useContextMenuState(block) {
//   const [color, setColor] = useState(block.color);
//   const [colorUpdated, setColorUpdated] = useState(false);

//   const handleColorChange = (value) => {
//     setColor(value);
//     setColorUpdated(true);
//   };
//   const handleColorBlur = () => {
//     if (colorUpdated) onColorChange(color);
//   };
// }
