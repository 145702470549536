import { useEffect, useState } from "react";
import { Modal } from "../../Shared/Components/Modal";
import { useActivityContext } from "../../Shared/Contexts/ActivitiesContextProvider";

export function ConfirmRemoveActivityModal({ activityId, onSuccess }) {
  const { activities, setActivities } = useActivityContext();
  const [activity, setActivity] = useState(null);

  useEffect(() => {
    if (activityId == null) return;
    const activity = activities.find((a) => a._id === activityId);
    setActivity(activity);
  }, [activityId]);

  const handleCancel = () => {};

  if (activity == null) return <></>;

  return (
    <Modal
      title={"Are you sure you want to delete " + `${activity.title}?`}
      successText={"Delete"}
      onCancel={handleCancel}
      onSuccess={() => onSuccess(activityId)}
      successColor={"bg-red-600"}
      successHoverColor={"hover:bg-red-500"}
    >
      <div className="text-black dark:text-white">
        <p className="text-sm">
          By deleting this all your statistics related to this activity will be
          gone. <br /> If you want to hide it you should instead mark it as done
        </p>
      </div>
    </Modal>
  );
}
