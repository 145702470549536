import {
  DeleteActivity,
  EditActivity,
  MarkAsDone,
  PostActivity,
} from "../../../Endpoints/Activities/ActivitiesEndpoints";
import { useActivityContext } from "../../Shared/Contexts/ActivitiesContextProvider";

export function useActivityOptions() {
  const { activities, setActivities } = useActivityContext();

  const handleNewActivity = (activity) => {
    PostActivity(activity).then((response) => {
      setActivities((currentActivities) => {
        const newActivity = response.data;
        return [...currentActivities, newActivity];
      });
    });
  };
  const handleActivityMarkedAsDone = (updatedActivity) => {
    MarkAsDone(updatedActivity).then((response) => {
      setActivities((currentActivities) => {
        const updatedActivity = response.data;
        return currentActivities.map((activity) =>
          activity._id !== updatedActivity._id ? activity : updatedActivity
        );
      });
    });
  };
  const handleActivityEdited = (updatedActivity) => {
    EditActivity(updatedActivity).then((response) => {
      setActivities((currentActivities) => {
        const updatedActivity = response.data;
        return currentActivities.map((activity) =>
          activity._id !== updatedActivity._id ? activity : updatedActivity
        );
      });
    });
  };

  const handleDeleteActivity = (activityId) => {
    DeleteActivity(activityId).then((response) => {
      setActivities((currentActivities) =>
        currentActivities.filter((activity) => activity._id !== activityId)
      );
    });
  };

  return {
    handleNewActivity,
    handleActivityEdited,
    handleDeleteActivity,
    handleActivityMarkedAsDone,
  };
}
