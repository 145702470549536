import { useState } from "react";
import { gt, normalizeDate } from "../../Shared/Utils/Functions/dateToNumber";
import { Modal } from "../../Shared/Components/Modal";
import { TagTarget } from "./TagTarget";
import { AddTagTargetForm } from "./AddTagTargetForm";

export function AddTagTargetModal({
  tagActivity,
  onAddTarget,
  onEditTarget,
  onDeleteTarget,
}) {
  const [isCreatingTarget, setIsCreatingTarget] = useState(false);
  const [editingTarget, setEditingTarget] = useState(null);
  const [showArchived, setShowArchived] = useState("active");

  if (tagActivity == null) return <></>;

  const handleCancel = () => {
    reset();
  };

  const reset = () => {
    setIsCreatingTarget(false);
    setEditingTarget(null);
    setShowArchived("active");
  };

  const handleAddTarget = (target) => {
    reset();
    onAddTarget(target);
  };

  const handleEditTarget = (target) => {
    setIsCreatingTarget(false);
    setEditingTarget(target);
  };

  const handleTargetEditedSuccess = (target) => {
    reset();
    onEditTarget(target);
  };

  const handleArchiveTarget = (target) => {
    if (gt(new Date(target.startDate), new Date())) {
      target.endDate = target.startDate;
    } else {
      target.endDate = new Date();
    }
    onEditTarget(target);
  };

  const handleReactivateTarget = (target) => {
    target.endDate = null;
    onEditTarget(target);
  };

  return (
    <Modal
      title={"Targets"}
      hasSuccess={false}
      onCancel={handleCancel}
      overFlowHidden={false}
    >
      <div className="text-black dark:text-white space-y-3 grow">
        <div className=" flex items-center gap-2">
          <h5 className="text-sm">
            <span>{tagActivity.icon}</span>
            <span>{tagActivity.title}</span>
          </h5>
        </div>

        <div>
          <div className="flex ">
            <h4 className="text-lg font-semibold">Targets</h4>
            <div className="ml-auto">
              <ArchiveSelect
                value={showArchived}
                onChange={(value) => setShowArchived(value)}
              />
            </div>
          </div>

          <div className="p-2 bg-secondaryLight dark:bg-lightDark rounded">
            <div>
              {!isCreatingTarget &&
                editingTarget === null &&
                showArchived === "active" && (
                  <button
                    onClick={() => setIsCreatingTarget(true)}
                    className="inline-flex items-center w-full justify-center rounded-md bg-secondaryLight dark:bg-lightDark px-3 py-1 text-sm font-semibold text-black dark:text-white shadow-sm ring-1 ring-inset ring-black dark:ring-gray-300 hover:bg-white dark:hover:bg-dark sm:mt-0 sm:w-auto"
                  >
                    Add Target
                  </button>
                )}
              {(isCreatingTarget || editingTarget !== null) && (
                <div>
                  <AddTagTargetForm
                    editTarget={editingTarget}
                    onCancel={handleCancel}
                    onSuccess={handleAddTarget}
                    onEdit={handleTargetEditedSuccess}
                  />
                </div>
              )}
            </div>

            {showArchived === "active" && (
              <div className="mt-4">
                <hr className="border-light dark:border-dark" />
              </div>
            )}
            <div className="mt-2 flex flex-col gap-1 rounded">
              {tagActivity.targets
                .filter((t) => {
                  if (showArchived === "archive") return targetIsArchived(t);
                  else return targetIsArchived(t) === false;
                })
                .map((target) => (
                  <TagTarget
                    key={target._id}
                    target={target}
                    onEdit={() => handleEditTarget(target)}
                    onArchive={() => handleArchiveTarget(target)}
                    onReactivate={() => handleReactivateTarget(target)}
                    onDelete={onDeleteTarget}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function ArchiveSelect({ value, onChange }) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div>
      <select
        id="time-span-select"
        value={value}
        onChange={handleChange}
        className="w-28 border bg-white border-black dark:border-white text-black time-span-select dark:text-white"
      >
        <option value="active">active</option>
        <option value="archive">archived</option>
      </select>
    </div>
  );
}

function targetIsArchived(target) {
  if (target.endDate == null) return false; // Return false if endDate is null or undefined
  return gt(new Date(), new Date(target.endDate));
}
