import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelectedDates } from "../../TimeBlocker/Utils/CustomHooks/useSelectedDates";
import { useBlocksContext } from "./BlocksContextProvider";
import { useColumnWidth } from "../../TimeBlocker/Utils/CustomHooks/useColumnWidth";
import { isSameDate, normalizeDate } from "../Utils/Functions/dateToNumber";
import { getUpdatedBlockPositions } from "../../TimeBlocker/Utils/functions/BlockPosition";
import { v4 as uuid } from "uuid";
import { defaultBlockColor } from "../../../App";
import { getBlockSize } from "../../TimeBlocker/Utils/functions/getBlockSize";
import { useActivityContext } from "./ActivitiesContextProvider";
import { useBlockUpdater } from "../Utils/CustomHooks/useBlockUpdater";

const BlockAdjustmentsContext = createContext(null);

export const defaultAdjustments = {
  isDragging: false,
  isResizing: false,
  isMoving: false,

  blockId: null,
  date: null,
  oldDate: null,

  initialStartSegment: null,
  initialEndSegment: null,

  initialMouseSegment: null,
  initialStartOffset: null,
  initialEndOffset: null,

  resizingSide: null,
};

export function BlockAdjustmentsContextProvider({ children }) {
  const [blockAdjustments, _setBlockAdjustments] = useState(defaultAdjustments);
  const { activities } = useActivityContext();
  const { updateBlock, postBlock, removeBlock } = useBlockUpdater();
  const { showDates } = useSelectedDates();
  const [preChangeBlock, setPreChangeBlock] = useState(null);
  const [updatingRecurranceIds, _setUpdatingRecurranceIds] = useState({});
  const updatingRecurranceIdsRef = React.useRef(updatingRecurranceIds);
  const setUpdatingRecurranceIds = (data) => {
    updatingRecurranceIdsRef.current = data;
    _setUpdatingRecurranceIds(data);
  };

  const [enteredSegment, setEnteredSegment] = useState({
    segment: null,
    date: null,
  });

  const blockAdjustmentsRef = React.useRef(blockAdjustments);
  const setBlockAdjustments = (data) => {
    blockAdjustmentsRef.current = data;

    _setBlockAdjustments(data);
  };

  const { blocks, setBlocks: _setBlocks } = useBlocksContext();
  const blocksRef = React.useRef(blocks);
  useEffect(() => {
    blocksRef.current = blocks;
  }, [blocks]);

  const setBlocks = (data) => {
    blocksRef.current = data;
    _setBlocks(data);
  };

  const { columnWidth } = useColumnWidth();
  useEffect(() => {
    //this is updating blockWidth when columnWidth changes
    // when changing daySetting toggle, this isnt used since we are loading new blocks
    // this means width is set from ColumnStateProvider in that case
    if (!isFinite(columnWidth) || showDates.length === 0) return;

    for (const date of showDates) {
      updateBlockIndention(date.date);
    }
  }, [columnWidth, showDates]);

  const getCurrentBlock = () => {
    return {
      ...blocksRef.current.find(
        (block) => block._id === blockAdjustmentsRef.current.blockId
      ),
    };
  };

  const updateBlockIndention = (date) => {
    const columnBlocks = blocksRef.current.filter((block) => {
      return isSameDate(block.date, date);
    });
    const otherBlocks = blocksRef.current.filter(
      (block) => !isSameDate(block.date, date)
    );

    setBlocks([
      ...otherBlocks,
      ...getUpdatedBlockPositions(columnBlocks, columnWidth - 10),
    ]);
  };

  const onSegmentPressed = (segmentIndex, date) => {
    const newBlock = {
      start: segmentIndex,
      end: segmentIndex,
      _id: uuid(),
      createdDate: Date.now(),
      color: defaultBlockColor,
      activityId: null,
      title: "",
      date,
    };

    setBlocks([...blocks, newBlock]);

    setBlockAdjustments({
      ...blockAdjustments,
      isDragging: true,
      blockId: newBlock._id,
      date,
    });

    document.addEventListener("mouseup", onEndDrag);
  };

  const onEndDrag = () => {
    document.removeEventListener("mouseup", onEndDrag);
    const block = getCurrentBlock();

    setBlockAdjustments({ ...defaultAdjustments });
    updateBlockIndention(block.date);
    postBlock(block);
  };

  const onResize = (block, resizingSide) => {
    document.addEventListener("mouseup", onEndResize);
    setBlockAdjustments({
      ...blockAdjustments,
      isResizing: true,
      blockId: block._id,
      date: block.date,
      resizingSide,
    });
  };

  const onEndResize = () => {
    document.removeEventListener("mouseup", onEndResize);
    const block = getCurrentBlock();

    setBlockAdjustments({ ...defaultAdjustments });
    updateBlockIndention(block.date);

    const updateData = { start: block.start, end: block.end };
    updateBlock(updateData, block);
  };

  const onActivityDrop = (activityId, blockId) => {
    const activity = activities.find((a) => a.id === activityId);
    const block = blocksRef.current.find((block) => block._id === blockId);

    const updateData = { activityId: activity._id };
    updateBlock(updateData, block);
  };

  const onMove = (block, resizingSide) => {
    document.addEventListener("mouseup", onEndMove);

    setBlockAdjustments({
      ...blockAdjustments,
      isMoving: true,
      blockId: block._id,
      date: block.date,
      resizingSide,
      initialStartSegment: block.start,
      initialEndSegment: block.end,
      oldDate: block.date,
    });
    setPreChangeBlock({ ...block });
  };

  const onEndMove = () => {
    document.removeEventListener("mouseup", onEndMove);
    const block = getCurrentBlock();

    const oldDate = blockAdjustmentsRef.current.oldDate;
    const initialStart = blockAdjustmentsRef.current.initialStartSegment;

    setBlockAdjustments({ ...defaultAdjustments });

    if (initialStart === block.start && oldDate === block.date) return;

    const updateData = { start: block.start, end: block.end };

    updateBlock(updateData, block);

    updateBlockIndention(block.date);
    updateBlockIndention(oldDate);
  };

  function setRecurranceLoadingState(recurranceId, loading) {
    setUpdatingRecurranceIds((current) => {
      const updatedObj = { ...current };
      updatedObj[recurranceId] = loading;
      return updatedObj;
    });
  }

  const onDelete = (block) => {
    setBlocks(blocks.filter((b) => b._id !== block._id));
    updateBlockIndention(block.date);

    removeBlock(block);
  };

  useEffect(() => {
    if (enteredSegment.segment == null) return;

    if (
      !blockAdjustments.isDragging &&
      !blockAdjustments.isResizing &&
      !blockAdjustments.isMoving
    ) {
      return;
    }

    //moving to other column
    if (
      blockAdjustments.isMoving &&
      !isSameDate(enteredSegment.date, blockAdjustments.date)
    ) {
      const block = blocks.find(
        (block) => block._id === blockAdjustments.blockId
      );
      const updatedValus = getBlockSize(
        block,
        blockAdjustments,
        enteredSegment.segment
      );

      setBlocks(
        blocks.map((block) =>
          block._id === blockAdjustments.blockId
            ? {
                ...updatedValus.block,
                date: normalizeDate(enteredSegment.date),
              }
            : block
        )
      );

      updatedValus.updatedBlockAdjustments.date = enteredSegment.date;

      setBlockAdjustments(updatedValus.updatedBlockAdjustments);

      return;
    }

    if (blockAdjustments.isResizing || blockAdjustments.isDragging) {
      const block = blocks.find(
        (block) => block._id === blockAdjustments.blockId
      );

      const updatedValus = getBlockSize(
        block,
        blockAdjustments,
        enteredSegment.segment
      );

      setBlocks(
        blocks.map((block) =>
          block._id === blockAdjustments.blockId ? updatedValus.block : block
        )
      );
      setBlockAdjustments(updatedValus.updatedBlockAdjustments);

      return;
    }

    if (!isSameDate(enteredSegment.date, blockAdjustments.date)) return;
    const block = blocks.find(
      (block) => block._id === blockAdjustments.blockId
    );

    const updatedValus = getBlockSize(
      block,
      blockAdjustments,
      enteredSegment.segment
    );

    setBlocks(
      blocks.map((block) =>
        block._id === blockAdjustments.blockId ? updatedValus.block : block
      )
    );

    setBlockAdjustments({ ...updatedValus.updatedBlockAdjustments });
  }, [enteredSegment]);

  return (
    <BlockAdjustmentsContext.Provider
      value={{
        enteredSegment,
        setEnteredSegment,
        onSegmentPressed,
        onResize,
        onMove,
        onDelete,
        onActivityDrop,
        blockAdjustments,
        preChangeBlock,
      }}
    >
      {children}
    </BlockAdjustmentsContext.Provider>
  );
}

export function useBlockAdjustmentsContext() {
  const context = useContext(BlockAdjustmentsContext);
  if (!context) {
    throw new Error(
      "BlockAdjustmentsContext must be used within a BlockAdjustmentsContextProvider"
    );
  }
  return context;
}
