import addRound from "../../../Assets/Icons/add-round.svg";
import addRoundBlack from "../../../Assets/Icons/add-round-black.svg";
import { ModalContext } from "../../Shared/Contexts/ModalContextProvider";
import { AddTagActivityModal } from "./AddTagActivityModal";
import { useEffect, useState } from "react";
import {
  deleteTagActivity,
  DeleteTagTarget,
  EditTagTarget,
  patchTagActivity,
  postTagActivity,
  PostTagTarget,
} from "../../../Endpoints/Tags/TagsEndpoints";
import { useTagContext } from "../../Shared/Contexts/TagContextProvider";
import { TagActivityItem } from "../../Shared/Components/TagActivityItem";
import { EditTagActivityModal } from "./EditTagActivityModal";
import { AddTagTargetModal } from "../../Targets/Components/AddTagTargetModal";
const { v4: uuidv4 } = require("uuid");

export function TagColumn() {
  const { tagActivities, setTagActivities } = useTagContext();

  const [addTagTargetModalOpen, setAddTagTargetModalOpen] = useState(false);
  const [addTagActivityModalOpen, setAddTagActivityModalOpen] = useState(false);
  const [editTagActivityModalOpen, setEditTagActivityModalOpen] =
    useState(false);
  const [editTagActivity, setEditTagActivity] = useState(null);

  useEffect(() => {
    if (editTagActivity == null) return;
    setEditTagActivity((current) =>
      tagActivities.find((ta) => ta._id === current._id)
    );
  }, [tagActivities]);

  const onAdd = () => {
    setAddTagActivityModalOpen(true);
  };

  const handleTagAdded = (tag) => {
    const tempId = uuidv4();
    setTagActivities((current) => [...current, { ...tag, _id: tempId }]);
    postTagActivity(tag).then((response) => {
      setTagActivities((current) =>
        current.map((t) => (t._id === tempId ? { ...response } : t))
      );
    });
  };

  const handleTagEdited = (tag) => {
    patchTagActivity({
      _id: tag._id,
      title: tag.title,
      icon: tag.icon,
      isDone: tag.isDone,
    }).then((response) => {
      setTagActivities((current) =>
        current.map((t) => (t._id === tag._id ? { ...response } : t))
      );
    });
  };

  const handleDeleteTag = (tag) => {
    deleteTagActivity(tag).then((response) =>
      setTagActivities((current) => current.filter((t) => t._id !== tag._id))
    );
  };
  const handleEditTag = (tag) => {
    setEditTagActivity(tag);
    setEditTagActivityModalOpen(true);
  };

  const handleAddTargetTag = (tag) => {
    setEditTagActivity(tag);
    setAddTagTargetModalOpen(true);
  };

  const handleTargetAdded = (target) => {
    PostTagTarget(target, editTagActivity._id).then((response) => {
      const updatedTagActivity = response.data;

      setTagActivities((Current) =>
        Current.map((a) =>
          a._id === updatedTagActivity._id ? { ...updatedTagActivity } : a
        )
      );
    });
  };
  const handleTargetEdited = (target) => {
    EditTagTarget(target, editTagActivity._id).then((response) => {
      const updatedTagActivity = response.data;

      setTagActivities((Current) =>
        Current.map((a) =>
          a._id === updatedTagActivity._id ? { ...updatedTagActivity } : a
        )
      );
    });
  };
  const handleTargetDeleted = (target) => {
    DeleteTagTarget(target, editTagActivity._id).then((response) => {
      const updatedTagActivity = response.data;

      setTagActivities((Current) => {
        return Current.map((a) =>
          a._id === updatedTagActivity._id ? { ...updatedTagActivity } : a
        );
      });
    });
  };

  return (
    <div className="w-52 p-3 rounded-md min-h-[600px] bg-secondaryLight text-black dark:text-white dark:bg-dark flex flex-col gap-1">
      <div className="h-14 flex">
        <h3>Tags</h3>
        <button className="ml-auto h-min mt-1" onClick={() => onAdd()}>
          <img className="hidden dark:block" src={addRound} alt="plus icon" />
          <img className="dark:hidden" src={addRoundBlack} alt="plus icon" />
        </button>
      </div>
      <div>
        {tagActivities.map((tag) => {
          return (
            <div key={"div" + tag._id}>
              <TagActivityItem
                key={tag._id}
                tagActivity={tag}
                onDelete={handleDeleteTag}
                onEdit={handleEditTag}
                onTargetsClicked={handleAddTargetTag}
                canMarkAsDone={true}
              />
            </div>
          );
        })}
      </div>
      <ModalContext.Provider
        value={[addTagActivityModalOpen, setAddTagActivityModalOpen]}
      >
        <AddTagActivityModal onCreateTagActivity={handleTagAdded} />
      </ModalContext.Provider>
      <ModalContext.Provider
        value={[editTagActivityModalOpen, setEditTagActivityModalOpen]}
      >
        <EditTagActivityModal
          onEditTagActivity={handleTagEdited}
          tagActivity={editTagActivity}
        />
      </ModalContext.Provider>
      <ModalContext.Provider
        value={[addTagTargetModalOpen, setAddTagTargetModalOpen]}
      >
        <AddTagTargetModal
          tagActivity={editTagActivity}
          onAddTarget={handleTargetAdded}
          onEditTarget={handleTargetEdited}
          onDeleteTarget={handleTargetDeleted}
        />
      </ModalContext.Provider>
    </div>
  );
}
