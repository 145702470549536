export function ColorTemplate({ onColorSelect }) {
  return (
    <div>
      <div className="flex gap-1 justify-center">
        <div
          role="button"
          className="w-5 h-5 rounded bg-[#D8A8F5]"
          onClick={() => onColorSelect("#D8A8F5")}
        ></div>
        <div
          role="button"
          className="w-5 h-5 rounded bg-[#8FC4F0]"
          onClick={() => onColorSelect("#8FC4F0")}
        ></div>
        <div
          role="button"
          className="w-5 h-5 rounded bg-[#8EEAB2]"
          onClick={() => onColorSelect("#8EEAB2")}
        ></div>
        <div
          role="button"
          className="w-5 h-5 rounded bg-[#F5C18A]"
          onClick={() => onColorSelect("#F5C18A")}
        ></div>
        <div
          role="button"
          className="w-5 h-5 rounded bg-[#F58AA6]"
          onClick={() => onColorSelect("#F58AA6")}
        ></div>
      </div>
      <div className="flex gap-1 mt-1 justify-center">
        <div
          role="button"
          className="w-5 h-5 rounded bg-[#ECA198]"
          onClick={() => onColorSelect("#ECA198")}
        ></div>
        <div
          role="button"
          className="w-5 h-5 rounded bg-[#C1EC98]"
          onClick={() => onColorSelect("#C1EC98")}
        ></div>
        <div
          role="button"
          className="w-5 h-5 rounded bg-[#A898EC]"
          onClick={() => onColorSelect("#A898EC")}
        ></div>
        <div
          role="button"
          className="w-5 h-5 rounded bg-[#F5E798]"
          onClick={() => onColorSelect("#F5E798")}
        ></div>
        <div
          role="button"
          className="w-5 h-5 rounded bg-[#A8F58D]"
          onClick={() => onColorSelect("#A8F58D")}
        ></div>
      </div>
    </div>
  );
}
