import EmojiPicker from "emoji-picker-react";
import { useEffect, useState } from "react";
import { Modal } from "../../Shared/Components/Modal";
import { TextInput } from "../../Shared/Components/TextInput";

export function EditTagActivityModal({ onEditTagActivity, tagActivity }) {
  const handleEditCategory = () => {
    const updatedTagActivity = {
      ...tagActivity,
      title,
      icon,
    };

    onEditTagActivity(updatedTagActivity);
  };

  const clearValues = () => {
    if (tagActivity == null) return;

    setTitle(tagActivity.title);
    setIcon(tagActivity.icon);
    setShowPicker(false);
    setHasValues(true);
  };

  const [title, setTitle] = useState(null);
  const [icon, setIcon] = useState("");
  const [showPicker, setShowPicker] = useState(false); // To toggle the emoji picker
  const [hasValues, setHasValues] = useState(false);

  useEffect(() => {
    if (tagActivity == null) return;

    setTitle(tagActivity.title);
    setIcon(tagActivity.icon);
    setHasValues(true);
  }, [tagActivity]);

  const handleTitleChange = (value) => {
    setTitle(value);
  };

  const onEmojiClick = (emojiData) => {
    setIcon((prevInput) => emojiData.emoji);
    setShowPicker(false); // Close picker after selection (optional)
  };

  const handleCancel = () => {
    clearValues();
  };

  if (!hasValues) return "";

  return (
    <Modal
      title={"Edit Tag"}
      successText={"Save"}
      onSuccess={handleEditCategory}
      onCancel={handleCancel}
    >
      <div className="flex gap-5 flex-col">
        <form className="text-black dark:text-white space-y-3">
          <TextInput
            title={"Title"}
            value={title}
            placeholder={"Category_1"}
            onValueChange={handleTitleChange}
          />
        </form>
        <div>
          <button
            className="px-6 py-1 rounded-md border text-black border-black bg-white dark:bg-lightDark dark:text-white dark:border-white"
            onClick={() => setShowPicker((val) => !val)}
          >
            Icon
          </button>
        </div>
        {showPicker && (
          <div>
            <EmojiPicker onEmojiClick={onEmojiClick} />
          </div>
        )}

        <div>{icon}</div>
      </div>
    </Modal>
  );
}
