import { Link, useNavigate } from "react-router-dom";
import { Navigation } from "./Navigation";
import "./SideMenu.css";
import Line from "../../Shared/Components/Line";
import { useActivityContext } from "../../Shared/Contexts/ActivitiesContextProvider";
import { ActivityItem } from "../../Shared/Components/ActivityItem";
// import { ActivitySection } from "./ActivitySection";
import ActivitySection from "./ActivitySection";
import { ThemeToggle } from "./ThemeToggle";
import { handleSignOut, signInWithGoogle } from "../../Shared/Utils/Auth";
import { useUserContext } from "../../Shared/Contexts/UserContextProvider";
import { useTargets } from "../../Targets/Utils/CustomHooks/useTargets";
import {
  isDateThisWeek,
  normalizeDate,
} from "../../Shared/Utils/Functions/dateToNumber";
import { useEffect, useState } from "react";
import { useWeeklyTargets } from "../../Targets/Utils/CustomHooks/useWeeklyTargets";
import { useSelectedDates } from "../../TimeBlocker/Utils/CustomHooks/useSelectedDates";
import { useActivityColor } from "../../Shared/Utils/CustomHooks/useActivityColor";
import { useWindowWidth } from "../../Shared/Utils/CustomHooks/UseWindowWidth";
import { CategoryBundle } from "../../Shared/Components/CategoryBundle";
import { useTagContext } from "../../Shared/Contexts/TagContextProvider";
import { TagActivityItem } from "../../Shared/Components/TagActivityItem";

function SideMenu({ showActivities = true }) {
  const { activities } = useActivityContext();
  const { tagActivities } = useTagContext();
  const { user, userLoaded } = useUserContext();
  const [date, setDate] = useState(null);
  const { weekDates } = useSelectedDates();
  const [isThisWeek, setIsThisWeek] = useState(false);
  const { targets } = useTargets(date);
  const { weeklyTargets } = useWeeklyTargets();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    setDate(normalizeDate(new Date()));
  }, []);
  useEffect(() => {
    if (weekDates === null || weekDates.length === 0) return;
    setIsThisWeek(isDateThisWeek(normalizeDate(weekDates[0].date)));
  }, [weekDates]);

  const navigate = useNavigate();
  const onSignOut = () => {
    handleSignOut().then(() => {
      navigate("/login");
    });
  };

  return (
    <div className="min-h-screen max-h-screen h-screen overflow-y-scroll overflow-x-hidden hide-scroll  w-64 quicksand text-black bg-secondaryLight dark:bg-dark dark:text-white">
      <div className="py-8">
        <div className="w-max mx-auto">
          <Link to="/timeBlocker/blocks">
            <h2 className="hidden dark:block text-3xl quicksand font-semibold logo">
              Blokkly
            </h2>
            <h2 className="dark:hidden text-3xl quicksand font-semibold logo-light">
              Blokkly
            </h2>
          </Link>
        </div>
      </div>

      <Navigation />

      <div className="mt-10">
        <div className="mb-1 grid grid-cols-5 items-center">
          <div className="col-span-1">
            <h5 className=" px-3 text-sm w-max mx-auto text-light">Targets</h5>
          </div>

          <div className="col-span-4">
            <Line />
          </div>
        </div>

        {isThisWeek && (
          <>
            <TargetSection Type={"Daily Targets"} targets={targets} />
            <TargetSection Type={"Weekly Targets"} targets={weeklyTargets} />
          </>
        )}
      </div>

      {showActivities && (
        <div className="mt-5">
          <div className="mb-1 grid grid-cols-5 items-center">
            <div className="col-span-2">
              <h5 className=" px-3 text-sm w-max mx-auto text-light">
                My Activities
              </h5>
            </div>

            <div className="col-span-3">
              <Line />
            </div>
          </div>

          <Section Type={"Critical & Immediate"} activities={activities} />
          <Section
            Type={"Personal Projects & Initiatives"}
            activities={activities}
          />
          <Section Type={"Routine Activites"} activities={activities} />
          <Section Type={"Categories"} activities={activities} />
        </div>
      )}

      {showActivities && (
        <div className="mt-5">
          <div className="mb-1 grid grid-cols-5 items-center">
            <div className="col-span-1">
              <h5 className=" px-3 text-sm w-max mx-auto text-light">
                My Tags
              </h5>
            </div>

            <div className="col-span-4">
              <Line />
            </div>
          </div>
          <ActivitySection title={"Tags"}>
            {tagActivities.map((tagActivity) =>
              tagActivity.isDone !== true ? (
                <TagActivityItem
                  key={tagActivity._id}
                  tagActivity={tagActivity}
                  showOptions={false}
                />
              ) : (
                ""
              )
            )}
          </ActivitySection>
        </div>
      )}

      <div className="mt-10 w-min mx-auto">
        <ThemeToggle />
      </div>

      {user && user.isAnonymous === false && (
        <div className="my-10 w-max mx-auto dark:text-light text-sm">
          <button onClick={onSignOut}>Log Out</button>
        </div>
      )}

      {(!user || user.isAnonymous === true) && (
        <div className="mt-10 w-max mx-auto dark:text-light text-sm">
          <Link to={"/login"}>Log In</Link>
          {/* <button onClick={signInWithGoogle}>Log In</button> */}
        </div>
      )}
      {windowWidth < 1330 && (!user || user.isAnonymous === true) && (
        <Link to={"/login"} className="">
          <div className="mt-5 px-2 text-center py-1 text-sm rounded p-2 bg-red-500 ml-1 mr-1">
            Sign In to Keep Your Creations Safe!
          </div>
        </Link>
      )}
    </div>
  );
}

export default SideMenu;

function Section({ Type, activities }) {
  //this code will hide the section if no activities exist
  // if (activities.filter((activity) => activity.type === Type).length === 0)
  //   return <></>;

  const groupedActivities = activities
    .filter(
      (activity) =>
        activity.type === Type &&
        activity.type !== "Categories" &&
        activity.isDone !== true
    )
    .reduce((groups, activity) => {
      const { categoryId } = activity;

      if (!categoryId) {
        // For activities without a categoryId, push them into a separate array
        groups["uncategorized"] = groups["uncategorized"] || [];
        groups["uncategorized"].push(activity);
      } else {
        groups[categoryId] = groups[categoryId] || [];
        groups[categoryId].push(activity);
      }
      return groups;
    }, {});

  const stopProp = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <ActivitySection title={Type}>
      <div className="flex flex-col gap-1">
        <div className="ml-4" onClick={stopProp}>
          {Object.entries(groupedActivities)
            .filter(
              ([categoryId, categoryActivities]) =>
                categoryId !== "uncategorized"
            )
            .map(([categoryId, categoryActivities]) => (
              <CategoryBundle key={categoryId} categoryId={categoryId}>
                {categoryActivities.map((activity) =>
                  activity.type === Type && activity.isDone !== true ? (
                    <ActivityItem
                      key={activity._id}
                      activity={activity}
                      showOptions={false}
                    />
                  ) : (
                    ""
                  )
                )}
              </CategoryBundle>
            ))}
        </div>

        {activities
          .filter((a) => a.categoryId == null)
          .map((activity) =>
            activity.type === Type && activity.isDone !== true ? (
              <ActivityItem
                key={activity._id}
                activity={activity}
                showOptions={false}
              />
            ) : (
              ""
            )
          )}
      </div>
    </ActivitySection>
  );
}

function TargetSection({ Type, targets }) {
  const notDoneTargets = targets.filter((t) => t.isDone === false);

  if (notDoneTargets.length === 0) return <></>;
  return (
    <ActivitySection title={Type}>
      <div className="flex flex-col gap-1">
        {notDoneTargets
          .filter((t) => t.type !== "amount")
          .map((target) => (
            <TargetItem key={target._id} target={target} />
          ))}
        {notDoneTargets
          .filter((t) => t.type === "amount")
          .map((target) => (
            <TagTargetItem key={target._id} target={target} />
          ))}
      </div>
    </ActivitySection>
  );
}

function TargetItem({ target }) {
  const { activities } = useActivityContext();
  const [activity, setActivity] = useState(null);
  useEffect(() => {
    setActivity(activities.find((a) => a._id === target.activityId));
  }, [target, activities]);
  const handleDragStart = (event) => {
    event.dataTransfer.setData("activityId", target.activityId);
  };
  const { color } = useActivityColor(activity);

  if (activity == null) return <></>;

  return (
    <div
      className="p-2 flex items-center gap-2 rounded relative"
      draggable={true}
      onDragStart={handleDragStart}
    >
      <div className="w-2 h-2" style={{ backgroundColor: color }}></div>
      <h4 className="text-sm">{activity.title}</h4>

      {/* Display remaining time or blocks left based on minMax */}
      {target.type === "time" && !target.isDone && target.minMax === "min" && (
        <div className="font-bold text-[#DE6363] ml-auto">
          {target.time - target.totalTime} min left
        </div>
      )}
      {target.type === "time" && !target.isDone && target.minMax === "max" && (
        <div className="font-bold text-[#DE6363] ml-auto">
          {target.totalTime - target.time} min over
        </div>
      )}

      {target.type === "block" && !target.isDone && target.minMax === "min" && (
        <div className="font-bold text-[#DE6363] ml-auto">
          {target.blocks - target.totalBlocks} blocks left
        </div>
      )}
      {target.type === "block" && !target.isDone && target.minMax === "max" && (
        <div className="font-bold text-[#DE6363] ml-auto">
          {target.totalBlocks - target.blocks} blocks over
        </div>
      )}

      {target.isDone && (
        <div className="font-bold text-[#63DE75] ml-auto">Done</div>
      )}
    </div>
  );
}

function TagTargetItem({ target }) {
  const { tagActivities, setTagActivities } = useTagContext();
  const [tagActivity, setTagActivity] = useState(null);

  useEffect(() => {
    setTagActivity(tagActivities.find((a) => a._id === target.activityId));
  }, [target, tagActivities]);

  const handleDragStart = (event) => {
    event.dataTransfer.setData("tagActivityId", target.activityId);
  };

  if (tagActivity == null) return <></>;

  return (
    <div
      className="py-2 pl-1 pr-2 flex items-center gap-2 rounded relative"
      draggable={true}
      onDragStart={handleDragStart}
    >
      <div className="flex items-center gap-2">
        <h5 className="text-sm">
          <span>{tagActivity.icon}</span>
          <span>{tagActivity.title}</span>
        </h5>
      </div>

      {/* Display remaining time or blocks left based on minMax */}
      {!target.isDone && target.minMax === "min" && (
        <div className="font-bold text-[#DE6363] ml-auto">
          {target.amount - target.totalAmount} tags left
        </div>
      )}
      {!target.isDone && target.minMax === "max" && (
        <div className="font-bold text-[#DE6363] ml-auto">
          {target.totalAmount - target.amount} tags over
        </div>
      )}

      {target.isDone && (
        <div className="font-bold text-[#63DE75] ml-auto">Done</div>
      )}
    </div>
  );
}
