import { createContext, useContext, useEffect, useState } from "react";
import { useSelectedDates } from "../../TimeBlocker/Utils/CustomHooks/useSelectedDates";
import {
  getTagActivities,
  getTags,
} from "../../../Endpoints/Tags/TagsEndpoints";
import { isSameDate } from "../Utils/Functions/dateToNumber";

const TagContext = createContext(null);

export function TagContextProvider({ children }) {
  const { showDates, weekDates } = useSelectedDates();

  const [tags, setTags] = useState([]);
  const [tagActivities, setTagActivities] = useState([]);
  const [tagsLoaded, setTagsLoaded] = useState(false);
  const [tagActivitiesLoaded, setTagActivitiesLoaded] = useState(false);

  useEffect(() => {
    if (weekDates === null || weekDates.length === 0) return;
    getTagActivities().then((response) => {
      setTagActivities(response);
      setTagActivitiesLoaded(true);
    });
    getTags(weekDates[0].date, weekDates.slice(-1)[0].date).then((response) => {
      setTags(response);
      setTagsLoaded(true);
    });
  }, [weekDates]);

  const getDateTags = (date) => {
    return tags.filter((t) => isSameDate(new Date(t.date), date));
  };

  return (
    <TagContext.Provider
      value={{
        tags,
        setTags,
        tagsLoaded,
        tagActivities,
        setTagActivities,
        tagActivitiesLoaded,
        getDateTags,
      }}
    >
      {children}
    </TagContext.Provider>
  );
}

export function useTagContext() {
  const context = useContext(TagContext);
  if (!context) {
    throw new Error("useTagContext must be used within a TagContextProvider");
  }
  return context;
}
