export const getOverlappingGroups = (blocks) => {
  let groups = [];

  blocks.forEach((block) => {
    let addedToGroup = false;
    for (let group of groups) {
      if (group.some((b) => doBlocksOverlap(block, b))) {
        group.push(block);
        addedToGroup = true;
        break;
      }
    }
    if (!addedToGroup) {
      groups.push([block]);
    }
  });

  // Merge groups if a block in one group overlaps with a block in another group
  for (let i = 0; i < groups.length; i++) {
    for (let j = i + 1; j < groups.length; j++) {
      if (
        groups[i].some((block1) =>
          groups[j].some((block2) => doBlocksOverlap(block1, block2))
        )
      ) {
        groups[i] = [...groups[i], ...groups[j]];
        groups.splice(j, 1);
        j--; // Adjust index after removal
      }
    }
  }

  return groups;
};

export const doBlocksOverlap = (block1, block2) => {
  return (
    (block1.start <= block2.start && block1.end >= block2.start) ||
    (block1.start <= block2.end && block1.end >= block2.end) ||
    (block2.start <= block1.start && block2.end >= block1.start)
  );
};

export const doBlocksOverlapText = (block1, block2) => {
  if (doBlocksOverlap(block1, block2)) {
    return (
      (block1.start >= block2.start && block1.start <= block2.start + 2) ||
      (block1.start < block2.start && block1.end >= block2.start)
    );
  }
  return false;
};
