import { useEffect, useState } from "react";

let isFirstUse = true;

export default function useTheme() {
  const [theme, _setTheme] = useState("light");

  const setTheme = (themeValue) => {
    _setTheme(themeValue);
    if (themeValue === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  };

  useEffect(() => {
    const themeIsDarkMode = localStorage.getItem("theme");
    if (themeIsDarkMode === null) {
      _setTheme("light");
      localStorage.setItem("theme", "light");
    } else if (themeIsDarkMode === "dark") {
      _setTheme("dark");
    } else {
      _setTheme("light");
    }
  }, []);

  useEffect(() => {
    if (isFirstUse === false) return;
    isFirstUse = false;

    if (
      localStorage.getItem("theme") === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);

  return { theme, setTheme };
}
