import { createContext, useEffect, useState } from "react";
import { useActivityContext } from "../../Shared/Contexts/ActivitiesContextProvider";
import { ActivitiesHeader } from "./ActivitiesHeader";
import { ActivityItem } from "./ActivityItem";
import addRound from "../../../Assets/Icons/add-round.svg";
import addRoundBlack from "../../../Assets/Icons/add-round-black.svg";
import { useActivityOptions } from "../CustomHooks/UseActivityOptions";
import AddActivityModal from "./AddActivityModal";
import AddCategoryModal from "./AddCategoryModal";
import EditActivityModal from "./EditActivityModal";
import EditCategoryModal from "./EditCategoryModal";
import { ModalContext } from "../../Shared/Contexts/ModalContextProvider";
import { AddTargetModal } from "../../Targets/Components/AddTargetModal";
import {
  DeleteTarget,
  EditTarget,
  PostTarget,
} from "../../../Endpoints/Activities/ActivitiesEndpoints";
import { ConfirmRemoveActivityModal } from "./ConfirmRemoveActivityModal";
import { CategoryBundle } from "../../Shared/Components/CategoryBundle";
import Line from "../../Shared/Components/Line";
import { TagColumn } from "./TagColumn";

export function ActivitiesPage() {
  const [addActivityModalOpen, setAddActivityModalOpen] = useState(false);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [editActivityModalOpen, setEditActivityModalOpen] = useState(false);
  const [editCategoryModalOpen, setEditCategoryModalOpen] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [deleteActivityId, setDeleteActivityId] = useState(null);
  const [targetsModalOpen, setTargetsModalOpen] = useState(false);
  const [type, setType] = useState(null);
  const [editActivity, setEditActivity] = useState(null);
  const { activities, setActivities } = useActivityContext();
  const [filter, setFilter] = useState({ show: "active" });

  const {
    handleNewActivity,
    handleActivityEdited,
    handleDeleteActivity: handleDeleteActivityConfirmed,
    handleActivityMarkedAsDone,
  } = useActivityOptions();

  useEffect(() => {
    if (editActivity == null) return;
    setEditActivity((current) => activities.find((a) => a._id === current._id));
  }, [activities]);

  const handleDeleteActivity = (id) => {
    console.log(id);

    setDeleteActivityId(id);
    setConfirmDeleteModalOpen(true);
  };

  const handleOpenModal = (type) => {
    setType(type);
    setAddActivityModalOpen(true);
  };

  const handleOpenCategoryModal = () => {
    setCategoryModalOpen(true);
  };
  const handleEditActivity = (activityId) => {
    const activity = activities.find((a) => a._id === activityId);
    setEditActivity(activity);
    setEditActivityModalOpen(true);
  };
  const handleEditCategory = (activityId) => {
    const activity = activities.find((a) => a._id === activityId);
    setEditActivity(activity);
    setEditCategoryModalOpen(true);
  };
  const handleTargetsClicked = (activityId) => {
    const activity = activities.find((a) => a._id === activityId);
    setEditActivity(activity);
    setTargetsModalOpen(true);
  };
  const handleMarkAsDone = (activityId) => {
    const activity = activities.find((a) => a._id === activityId);
    handleActivityMarkedAsDone({ ...activity, isDone: !activity.isDone });
  };

  const handleAddTarget = (target) => {
    PostTarget(target, editActivity._id).then((response) => {
      const updatedActivity = response.data;

      setActivities((CurrentActivities) =>
        CurrentActivities.map((a) =>
          a._id === updatedActivity._id ? { ...updatedActivity } : a
        )
      );
    });
  };
  const handleEditTarget = (target) => {
    EditTarget(target, editActivity._id).then((response) => {
      const updatedActivity = response.data;

      setActivities((CurrentActivities) =>
        CurrentActivities.map((a) =>
          a._id === updatedActivity._id ? { ...updatedActivity } : a
        )
      );
    });
  };
  const handleDeleteTarget = (target) => {
    DeleteTarget(target, editActivity._id).then((response) => {
      const updatedActivity = response.data;

      setActivities((CurrentActivities) =>
        CurrentActivities.map((a) =>
          a._id === updatedActivity._id ? { ...updatedActivity } : a
        )
      );
    });
  };

  return (
    <div>
      <ModalContext.Provider
        value={[addActivityModalOpen, setAddActivityModalOpen]}
      >
        <AddActivityModal
          categories={activities.filter((a) => a.type === "Categories")}
          onCreateActivity={handleNewActivity}
          type={type}
          onTypeChange={setType}
        />
      </ModalContext.Provider>
      <ModalContext.Provider value={[categoryModalOpen, setCategoryModalOpen]}>
        <AddCategoryModal onCreateCategory={handleNewActivity} />
      </ModalContext.Provider>
      <ModalContext.Provider
        value={[editActivityModalOpen, setEditActivityModalOpen]}
      >
        <EditActivityModal
          onEditActivity={handleActivityEdited}
          activity={editActivity}
          categories={activities.filter((a) => a.type === "Categories")}
        />
      </ModalContext.Provider>
      <ModalContext.Provider
        value={[editCategoryModalOpen, setEditCategoryModalOpen]}
      >
        <EditCategoryModal
          onEditCategory={handleActivityEdited}
          category={editActivity}
        />
      </ModalContext.Provider>
      <ModalContext.Provider value={[targetsModalOpen, setTargetsModalOpen]}>
        <AddTargetModal
          activity={editActivity}
          onAddTarget={handleAddTarget}
          onEditTarget={handleEditTarget}
          onDeleteTarget={handleDeleteTarget}
        />
      </ModalContext.Provider>

      <ModalContext.Provider
        value={[confirmDeleteModalOpen, setConfirmDeleteModalOpen]}
      >
        <ConfirmRemoveActivityModal
          activityId={deleteActivityId}
          onSuccess={handleDeleteActivityConfirmed}
        />
      </ModalContext.Provider>

      <ActivitiesHeader filter={filter} setFilter={setFilter} />
      <div className="px-12 w-min mx-auto">
        <div className="flex gap-3">
          <ActivityColumn
            title={"Critical & Immediate"}
            activities={activities.filter(
              (activity) => activity.type === "Critical & Immediate"
            )}
            onAdd={() => handleOpenModal("Critical & Immediate")}
            onDelete={handleDeleteActivity}
            onEdit={handleEditActivity}
            onMarkAsDone={handleMarkAsDone}
            filter={filter}
            canMarkAsDone={true}
            onTargetsClicked={handleTargetsClicked}
          />

          <ActivityColumn
            title={"Personal Projects & Initiatives"}
            activities={activities.filter(
              (activity) => activity.type === "Personal Projects & Initiatives"
            )}
            onAdd={() => handleOpenModal("Personal Projects & Initiatives")}
            onDelete={handleDeleteActivity}
            onEdit={handleEditActivity}
            onMarkAsDone={handleMarkAsDone}
            filter={filter}
            canMarkAsDone={true}
            onTargetsClicked={handleTargetsClicked}
          />
          <ActivityColumn
            title={"Routine Activites"}
            activities={activities.filter(
              (activity) => activity.type === "Routine Activites"
            )}
            onAdd={() => handleOpenModal("Routine Activites")}
            onDelete={handleDeleteActivity}
            onEdit={handleEditActivity}
            onMarkAsDone={handleMarkAsDone}
            filter={filter}
            canMarkAsDone={true}
            onTargetsClicked={handleTargetsClicked}
          />
          <div className="ml-5 flex gap-3">
            <ActivityColumn
              title={"Categories"}
              activities={activities.filter(
                (activity) => activity.type === "Categories"
              )}
              onAdd={handleOpenCategoryModal}
              onDelete={handleDeleteActivity}
              onEdit={handleEditCategory}
              filter={filter}
              canMarkAsDone={true}
              onMarkAsDone={handleMarkAsDone}
              onTargetsClicked={handleTargetsClicked}
            />
            <TagColumn />
          </div>
        </div>
      </div>
    </div>
  );
}

function ActivityColumn({
  title,
  activities,
  onAdd,
  onDelete,
  onEdit,
  onMarkAsDone,
  filter,
  canMarkAsDone,
  onTargetsClicked,
}) {
  const groupedActivities = filterActivities(activities, filter).reduce(
    (groups, activity) => {
      const { categoryId } = activity;

      if (!categoryId) {
        // For activities without a categoryId, push them into a separate array
        groups["uncategorized"] = groups["uncategorized"] || [];
        groups["uncategorized"].push(activity);
      } else {
        groups[categoryId] = groups[categoryId] || [];
        groups[categoryId].push(activity);
      }
      return groups;
    },
    {}
  );

  const uncategorized = filterActivities(activities, filter).filter(
    (a) => a.categoryId == null
  );

  const stopProp = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className="w-52 p-3 rounded-md min-h-[600px] bg-secondaryLight text-black dark:text-white dark:bg-dark flex flex-col gap-1">
      <div className="h-14 flex">
        <h3>{title}</h3>
        <button className="ml-auto h-min mt-1" onClick={() => onAdd()}>
          <img className="hidden dark:block" src={addRound} alt="plus icon" />
          <img className="dark:hidden" src={addRoundBlack} alt="plus icon" />
        </button>
      </div>

      {Object.entries(groupedActivities)
        .filter(
          ([categoryId, categoryActivities]) => categoryId !== "uncategorized"
        )
        .map(([categoryId, categoryActivities]) => (
          <CategoryBundle
            key={categoryId}
            categoryId={categoryId}
            startOpen={true}
          >
            {categoryActivities.map((activity) => (
              <div key={activity._id + "div"} onClick={stopProp}>
                <ActivityItem
                  key={activity._id}
                  id={activity._id}
                  activity={activity}
                  properties={{
                    title: activity.title,
                    isDone: activity.isDone,
                  }}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onMarkAsDone={onMarkAsDone}
                  canMarkAsDone={canMarkAsDone}
                  onTargetsClicked={onTargetsClicked}
                />
              </div>
            ))}
          </CategoryBundle>
        ))}

      {title !== "Categories" && uncategorized.length > 0 && (
        <CategoryBundle key={"uncategorized"} uncategorized={true}>
          {uncategorized.map((activity) => (
            <div key={activity._id + "div"} onClick={stopProp}>
              <ActivityItem
                key={activity._id}
                id={activity._id}
                activity={activity}
                properties={{
                  title: activity.title,
                  isDone: activity.isDone,
                }}
                onDelete={onDelete}
                onEdit={onEdit}
                onMarkAsDone={onMarkAsDone}
                canMarkAsDone={canMarkAsDone}
                onTargetsClicked={onTargetsClicked}
              />
            </div>
          ))}
        </CategoryBundle>
      )}

      {/* For Categories Column */}
      {title === "Categories" &&
        uncategorized.map((activity) => (
          <ActivityItem
            key={activity._id}
            id={activity._id}
            activity={activity}
            properties={{
              title: activity.title,
              isDone: activity.isDone,
            }}
            onDelete={onDelete}
            onEdit={onEdit}
            onMarkAsDone={onMarkAsDone}
            canMarkAsDone={canMarkAsDone}
            onTargetsClicked={onTargetsClicked}
          />
        ))}
    </div>
  );
}

function filterActivities(activities, filter) {
  if (filter == null || filter.show == null) return activities;
  return activities.filter((activity) => {
    if (filter.show === "all") return true;
    else if (filter.show === "done") return activity.isDone === true;
    else if (filter.show === "active") return activity.isDone === false;
    return true;
  });
}
