import { useStatisticsContext } from "../../Shared/Contexts/StatisticsContextProvider";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useActivityColor } from "../../Shared/Utils/CustomHooks/useActivityColor";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function ActivitiesStats({ onSelectActivity, limit = null }) {
  const { stats } = useStatisticsContext();
  const { getActivityColor } = useActivityColor();

  if (stats == null) return <></>;

  const data = {
    labels:
      limit !== null
        ? stats.activities
            .filter((a) => a.totalTime > 0)
            .map((a) => a.title)
            .slice(0, limit)
        : stats.activities.filter((a) => a.totalTime > 0).map((a) => a.title),
    datasets: [
      {
        label: "Time",
        data:
          limit !== null
            ? stats.activities
                .filter((a) => a.totalTime > 0)
                .map((a) => a.totalTime / 60)
                .slice(0, limit)
            : stats.activities
                .filter((a) => a.totalTime > 0)
                .map((a) => a.totalTime / 60),
        backgroundColor:
          limit !== null
            ? stats.activities
                .filter((a) => a.totalTime > 0)
                .map((a) => getActivityColor(a._id))
                .slice(0, limit)
            : stats.activities
                .filter((a) => a.totalTime > 0)
                .map((a) => getActivityColor(a._id)),
        borderColor: "#ffffff",
        borderWidth: 1,
        barThickness: 20, // Adjust the bar thickness
      },
    ],
  };

  const options = {
    responsive: true,
    indexAxis: "y", // Make bars horizontal
    onClick: (event, elements) => {
      if (elements[0] == null) return;
      const index = elements[0].index;
      const activity = stats.activities[index];
      onSelectActivity(activity);
    },

    plugins: {
      legend: {
        // position: "top",
        display: false, // Fully hides the legend and the colored square
      },
      title: {
        display: true,
        text: "Time Per Activity" + (limit !== null ? ` (top ${limit})` : ""),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          autoSkip: false, // Prevents skipping labels
          maxRotation: 0, // Keeps labels horizontal
          minRotation: 0, // Avoids tilting labels
        },
      },
    },
  };

  return (
    // <div style={{ width: "100%", maxWidth: "800px" }}>
    <Bar data={data} options={options} />
    // </div>
  );
}
