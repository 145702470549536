import { useDateCountContext } from "../../Shared/Contexts/DateCountContextProvider";
import { useWindowWidth } from "../../Shared/Utils/CustomHooks/UseWindowWidth";
import { SelectCount } from "./SelectCount";
import "./ColumnViewPicker.css";

export function ColumnViewPicker() {
  const { dateCount, setDateCount } = useDateCountContext();
  const windowWidth = useWindowWidth();

  const handleDateCountChange = (event) => {
    setDateCount(Number(event.target.value));
  };
  const handleCountChange = (value) => {
    setDateCount(value);
  };

  if (windowWidth < 860) {
    return (
      <div>
        <SelectCount dateCount={dateCount} onCountChange={handleCountChange} />
      </div>
    );
  }

  return (
    <div className="px-4 py-2 rounded bg-se bg-secondaryLight dark:bg-dark">
      <ul className="flex gap-2">
        <li>
          <input
            type="radio"
            id="1day"
            name="1day"
            value={1}
            className="hidden peer"
            checked={dateCount === 1}
            onChange={handleDateCountChange}
          />
          <label
            htmlFor="1day"
            className="px-2 py-1 text-sm rounded cursor-pointer text-black peer-checked:bg-lightDark peer-checked:text-white dark:text-white dark:peer-checked:bg-white dark:peer-checked:text-black"
          >
            1 Day
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="3day"
            name="3day"
            value={3}
            className="hidden peer"
            checked={dateCount === 3}
            onChange={handleDateCountChange}
          />
          <label
            htmlFor="3day"
            className="px-2 py-1 text-sm rounded cursor-pointer text-black peer-checked:bg-lightDark peer-checked:text-white dark:text-white dark:peer-checked:bg-white dark:peer-checked:text-black"
          >
            3 Days
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="week"
            name="week"
            value={7}
            className="hidden peer"
            checked={dateCount === 7}
            onChange={handleDateCountChange}
          />
          <label
            htmlFor="week"
            className="px-2 py-1 text-sm rounded cursor-pointer text-black peer-checked:bg-lightDark peer-checked:text-white dark:text-white dark:peer-checked:bg-white dark:peer-checked:text-black"
          >
            Week
          </label>
        </li>
      </ul>
    </div>
  );
}
