import ReactDatePicker from "react-datepicker";
import { useDateContext } from "../../Shared/Contexts/DateContextProvider";
import { useDateCountContext } from "../../Shared/Contexts/DateCountContextProvider";
import { daysFromDate } from "../Utils/functions/daysFromDate";
import { forwardRef } from "react";
import calendarWhite from "../../../Assets/Icons/calendar-white.svg";
import calendarBlack from "../../../Assets/Icons/calendar-black.svg";
import "./DateChooser.css";

import arrowLeftWhite from "../../../Assets/Icons/arrow-left-white.svg";
import arrowLeftBlack from "../../../Assets/Icons/arrow-left-black.svg";
import arrowRightWhite from "../../../Assets/Icons/arrow-right-white.svg";
import arrowRightBlack from "../../../Assets/Icons/arrow-right-black.svg";

export function DateChooser() {
  const { date, setDate } = useDateContext();
  const { dateCount } = useDateCountContext();

  const handlePreviousDate = () => {
    const newDate = daysFromDate(date, -dateCount);
    setDate(newDate);
  };
  const handleNextDate = () => {
    const newDate = daysFromDate(date, dateCount);
    setDate(newDate);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  return (
    <div className="flex">
      <button
        className="p-1 border rounded-l border-black dark:border-white "
        onClick={handlePreviousDate}
      >
        <img className="hidden dark:block" src={arrowLeftWhite} alt="" />
        <img className="dark:hidden" src={arrowLeftBlack} alt="" />
      </button>
      <div className="p-1 border-t border-b border-black dark:border-white">
        <DatePickerComp startDate={date} onDateChange={handleDateChange} />
      </div>
      <button
        className="p-1 border rounded-r border-black dark:border-white"
        onClick={handleNextDate}
      >
        <img className="hidden dark:block" src={arrowRightWhite} alt="" />
        <img className="dark:hidden" src={arrowRightBlack} alt="" />
      </button>
    </div>
  );
}

export function DatePickerComp({ startDate, onDateChange }) {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="rounded bg-" onClick={onClick} ref={ref}>
      <img className="hidden dark:block" src={calendarWhite} alt="" />
      <img className="dark:hidden" src={calendarBlack} alt="" />
    </button>
  ));

  return (
    <ReactDatePicker
      selected={startDate}
      onChange={(date) => onDateChange(date)}
      customInput={<ExampleCustomInput />}
    />
  );
}
