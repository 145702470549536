import { createContext, useContext, useEffect, useState } from "react";
import { getActivitiesAndCategories } from "../../../Endpoints/Activities/ActivitiesEndpoints";

export const ActivityContext = createContext(null);

export function ActivityContextProvider({ children, user }) {
  const [activities, setActivities] = useState([]);
  const [activitiesLoaded, setActivitiesLoaded] = useState(false);

  useEffect(() => {
    getActivitiesAndCategories().then((activitiesAndCategories) => {
      setActivities(activitiesAndCategories);
      setActivitiesLoaded(true);
    });
  }, [user]);

  return (
    <ActivityContext.Provider
      value={{ activities, setActivities, activitiesLoaded }}
    >
      {children}
    </ActivityContext.Provider>
  );
}

export function useActivityContext() {
  const context = useContext(ActivityContext);
  if (!context) {
    throw new Error(
      "useActivityContext must be used within a ActivityContextProvider"
    );
  }
  return context;
}
