import { useEffect, useState } from "react";
import { useActivityContext } from "../../Contexts/ActivitiesContextProvider";

export function useBlockTitle(inputBlock) {
  const { activities, activitiesLoaded } = useActivityContext();

  const [block, setBlock] = useState(inputBlock);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    setBlock(inputBlock);
  }, [inputBlock]);

  useEffect(() => {
    if (block == null || activitiesLoaded === false) return;

    if (block.hasCustomTitle) {
      setTitle(block.title);
      return;
    }

    const activity = activities.find((a) => a.id === block.activityId);
    if (activity == null || block.activityId == null) {
      setTitle("");
      return;
    }

    setTitle(activity.title);
  }, [block, activitiesLoaded]);

  return { title };
}
