import { useState } from "react";
import { TextInput } from "./AddCategoryModal";
import { Modal } from "../../Shared/Components/Modal";
import EmojiPicker from "emoji-picker-react";

export function AddTagActivityModal({ onCreateTagActivity }) {
  const handleCreateTagActivity = () => {
    onCreateTagActivity({
      title,
      icon,
    });
    clearValues();
  };

  const clearValues = () => {
    setTitle("");
    setIcon("");
    setShowPicker(false);
  };

  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");
  const [showPicker, setShowPicker] = useState(false); // To toggle the emoji picker

  const handleTitleChange = (value) => {
    setTitle(value);
  };

  const handleIconChange = (value) => {
    setIcon(value);
  };
  const handleCancel = () => {
    clearValues();
  };

  const onEmojiClick = (emojiData) => {
    setIcon((prevInput) => emojiData.emoji);
    setShowPicker(false); // Close picker after selection (optional)
  };

  return (
    <Modal
      title={"New Tag"}
      successText={"Add Tag"}
      onSuccess={handleCreateTagActivity}
      onCancel={handleCancel}
    >
      <div className="flex gap-5 flex-col">
        <form className="text-black dark:text-white space-y-3">
          <TextInput
            title={"Title"}
            value={title}
            placeholder={"Category_1"}
            onValueChange={handleTitleChange}
          />
        </form>
        <div>
          <button
            className="px-6 py-1 rounded-md border text-black border-black bg-white dark:bg-lightDark dark:text-white dark:border-white"
            onClick={() => setShowPicker((val) => !val)}
          >
            Icon
          </button>
        </div>
        {showPicker && (
          <div>
            <EmojiPicker onEmojiClick={onEmojiClick} />
          </div>
        )}

        <div>{icon}</div>
      </div>
    </Modal>
  );
}
