import { Outlet } from "react-router-dom";
import SideMenu from "../../SideMenu/Components/SideMenu";
import { useWindowWidth } from "../../Shared/Utils/CustomHooks/UseWindowWidth";

function TimeBlockerPage() {
  const windowWidth = useWindowWidth();
  const showSideMenu = windowWidth >= 600;

  return (
    <div className="min-h-screen bg-primaryLight dark:bg-lightDark text-white">
      <div className="flex">
        {showSideMenu && (
          <div>
            <SideMenu />
          </div>
        )}
        <div className="flex-grow">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default TimeBlockerPage;
