import { useEffect, useState } from "react";
import { useTagContext } from "../Contexts/TagContextProvider";

export function TagItem({ tag, onlyIcon = true, amountChange }) {
  const { tagActivities, tagActivitiesLoaded } = useTagContext();
  const [icon, setIcon] = useState("");
  useEffect(() => {
    if (tagActivitiesLoaded === false) return;

    const tagActivitiy = tagActivities.find(
      (ta) => ta._id === tag.tagActivityId
    );

    setIcon(`${tagActivitiy?.icon || ""}`.repeat(tag.amount));
  }, [tag, tagActivities, tagActivitiesLoaded]);

  const handleIncrease = () => {
    const newAmount = tag.amount + 1;
    amountChange(tag, newAmount);
  };

  const handleDecrease = () => {
    const newAmount = Math.max(0, tag.amount - 1); // Prevent negative values
    amountChange(tag, newAmount);
  };

  const remove = () => {
    amountChange(tag, 0);
  };

  if (onlyIcon) return <span>{icon}</span>;

  return (
    <div className="flex items-center gap-1">
      <button
        className="text-black dark:text-white mr-2 px-2 py-1  flex items-center justify-center border border-red-600 rounded"
        onClick={remove}
      >
        Remove
      </button>
      <button
        className="text-black dark:text-white mr-2 w-8 h-8 flex items-center justify-center border border-gray-300 dark:border-gray-600  rounded "
        onClick={handleDecrease}
        aria-label="Decrease Amount"
      >
        -
      </button>
      <button
        className="text-black dark:text-white mr-2 w-8 h-8 flex items-center justify-center border border-gray-300 dark:border-gray-600  rounded "
        onClick={handleIncrease}
        aria-label="Increase Amount"
      >
        +
      </button>

      <div className="max-w-80">{icon}</div>
    </div>
  );
}
