import { useRef } from "react";
import { ContextMenu } from "./ContextMenu";
import { useContextMenu } from "../Utils/CustomHooks/useContextMenu";
import { ModalContext } from "../../Shared/Contexts/ModalContextProvider";
import { SetRecurringPatternModal } from "./SetRecurringPatternModal";
import { useEditBlockContext } from "../../Shared/Contexts/EditBlockContextProvider";
import { useBlockAdjustmentsContext } from "../../Shared/Contexts/BlockAdjustmentsProvider";
import { getContrastingColor } from "../../Shared/Utils/Functions/getContrastingColor";
import { useBlockColor } from "../../Shared/Utils/CustomHooks/useBlockColor";
import { useBlockTitle } from "../../Shared/Utils/CustomHooks/useBlockTitle";

export function Block({ block, isUpdating }) {
  const { blockAdjustments, onResize, onMove, onDelete, onActivityDrop } =
    useBlockAdjustmentsContext();

  const triggerEvents = blockAdjustments.blockId !== null;
  const isSolid = blockAdjustments.blockId !== block._id;
  const contextMenuState = useContextMenu(block);
  const { color } = useBlockColor(block);
  const { title } = useBlockTitle(block);
  const { openMenu } = contextMenuState;
  const divRef = useRef(null);
  const { blockStyles, startTime, endTime, size } = getBlockProps(
    block,
    isSolid,
    contextMenuState.open,
    color
  );

  const onDeleteBlock = (e, block) => {
    e.preventDefault();
    onDelete(block);
  };

  const handleRightClick = (event) => {
    event.preventDefault();
    if (isUpdating) return;
    openMenu(event, divRef);
  };

  const handleDropActivity = (event) => {
    if (isUpdating) return;
    const activityId = event.dataTransfer.getData("activityId");

    if (activityId == null || activityId === "") return;
    onActivityDrop(activityId, block._id);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div
      ref={divRef}
      className={[
        isSolid && !triggerEvents ? "" : "pointer-events-none",
        "absolute border-black dark:border-white rounded-md",
      ].join(" ")}
      style={blockStyles}
      onMouseDown={(e) => {
        e.stopPropagation();
        if (isUpdating) return;
        if (e.button === 0) onMove(block); //left mouse
        if (e.button === 1) onDeleteBlock(e, block); //middle mouse
      }}
      draggable="false"
      onDrop={handleDropActivity}
      onDragOver={handleDragOver}
      onContextMenu={handleRightClick}
    >
      <div
        className={[
          isSolid ? "" : "hidden",
          block.end - block.start > 2 ? "h-2" : "h-1",
          "absolute top-0 left-0 right-0 cursor-ns-resize pointer-events-auto",
        ].join(" ")}
        onMouseDown={(e) => {
          e.stopPropagation();
          if (isUpdating) return;
          if (e.button === 0) onResize(block, "top");
          if (e.button === 1) onDeleteBlock(e, block); //middle mouse
        }}
      ></div>
      <div
        className={[
          "absolute w-full h-full overflow-hidden px-1 flex pointer-events-none select-none quicksand",
          size,
          block.end - block.start < 3 ? "gap-1" : "flex-col",
        ].join(" ")}
      >
        <span> {title === "" ? "(No title)" : title}</span>
        <span>
          {startTime} - {endTime}
        </span>
      </div>
      <div
        className={[
          isSolid ? "" : "hidden",
          block.end - block.start > 2 ? "h-2" : "h-1",
          "absolute bottom-0 left-0 right-0 cursor-ns-resize pointer-events-auto",
        ].join(" ")}
        onMouseDown={(e) => {
          e.stopPropagation();
          if (isUpdating) return;
          if (e.button === 0) onResize(block, "bottom");
          if (e.button === 1) onDeleteBlock(e, block); //middle mouse
          if (e.button === 2) handleRightClick(e);
        }}
      ></div>
      <ContextMenu contextMenuState={contextMenuState} block={block} />
    </div>
  );
}

function getBlockProps(block, isSolid, contextIsOpen, blockColor) {
  let blockTop = block.start;
  let blockHeight = block.end - block.start + 1;

  if (block.end < block.start) {
    blockTop = block.end;
    blockHeight = block.start - block.end + 1;
  }

  // Calculate the number of gaps spanned before and within the block
  const startGapIndex = Math.floor(block.start / 4);
  const endGapIndex = Math.floor(block.end / 4);
  const gapCountWithin = endGapIndex - startGapIndex;

  // Total gaps before the block's start
  const gapCountBefore = startGapIndex;

  // Adjust height for gaps (4px per gap)
  const gapAdjustmentHeight = gapCountWithin * 4;
  const gapAdjustmentTop = gapCountBefore * 4;

  // -1 and +1 adds a gap between blocks. To remove gap, remove these two constants(-1 & +1)
  const adjustedHeight = blockHeight * 12 + gapAdjustmentHeight - 1;
  const adjustedTop = blockTop * 12 + gapAdjustmentTop + 1;

  const top = `${adjustedTop}px`;
  const height = `${adjustedHeight}px`;

  const startHour =
    Math.floor(blockTop / 4) < 10
      ? `0${Math.floor(blockTop / 4)}`
      : `${Math.floor(blockTop / 4)}`;
  const startMinute = `${(blockTop % 4) * 15}`.padEnd(2, "0");
  const startTime = `${startHour}:${startMinute}`;

  const endHour =
    Math.floor((blockTop + blockHeight) / 4) < 10
      ? `0${Math.floor((blockTop + blockHeight) / 4)}`
      : `${Math.floor((blockTop + blockHeight) / 4)}`;
  const endMinute = `${((blockTop + blockHeight) % 4) * 15}`.padEnd(2, "0");
  const endTime = `${endHour}:${endMinute}`;

  const zIndex = !isSolid ? "1000" : block.zIndex;

  const blockStyles = {
    top,
    height,
    width: block?.width ?? "90%",
    left: block?.left ?? "0",
    zIndex: contextIsOpen ? "999" : zIndex,
    backgroundColor: blockColor,
    color: getContrastingColor(blockColor),
    boxShadow: "0px 0px 21.1px 6px rgba(0, 0, 0, 0.1)",
  };

  let size;
  if (block.end - block.start > 2) size = "text-[14px]";
  if (block.end - block.start === 2) size = "text-[12px]";
  if (block.end - block.start === 1) size = "text-[12px]";
  if (block.end - block.start === 0) size = "text-[12px] leading-[.6rem]";

  return { blockStyles, startTime, endTime, size };
}
