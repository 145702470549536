import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const baseUrl =
  process.env.NODE_ENV !== "development"
    ? "https://blokkly-e5c45b16d835.herokuapp.com/"
    : "http://localhost:3001";

const instance = axios.create({
  baseURL: baseUrl,
  // other axios config
});

instance.interceptors.request.use(
  async (config) => {
    const auth = getAuth();

    const user = await new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        auth,
        (user) => {
          unsubscribe();
          resolve(user);
        },
        reject
      );
    });

    if (user) {
      const token = await auth.currentUser.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
