import { useEffect, useRef, useState } from "react";
import { useCalendarWidthContext } from "../../../Shared/Contexts/CalendarWidthContextProvider";

export function useCalWidthRef() {
  const calWidthRef = useRef(null);
  const [calWidthRefCurrent, setCalWidthRefCurrent] = useState(null);
  const [refCurrentIsSet, setRefCurrentIsSet] = useState(false);
  const { setCalendarWidth } = useCalendarWidthContext();

  useEffect(() => {
    if (refCurrentIsSet !== false) return;
    setRefCurrentIsSet(true);
    setCalWidthRefCurrent(calWidthRef.current);
  }, [calWidthRef.current]);

  useEffect(() => {
    if (calWidthRefCurrent) {
      const handleWindowResize = () => {
        setCalendarWidth(calWidthRefCurrent.offsetWidth);
      };
      handleWindowResize();
      window.addEventListener("resize", handleWindowResize);

      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }
  }, [calWidthRefCurrent]);
  return { calWidthRef };
}
