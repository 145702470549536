export function getContrastingColor(color) {
  if (color == null) return null;
  // Remove the hash (#) if present
  color = color.replace("#", "");

  // Parse the hex color into RGB components
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  // Calculate the relative luminance
  const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

  // If luminance is above 0.5, return black; otherwise, return white
  return luminance > 0.5 ? "black" : "white";
}
