import axiosInstance from "../../httpInterceptor";

export async function getActivitiesAndCategories() {
  return axiosInstance
    .get("/activitys")
    .then((response) => response.data.activities);
}

export async function PostActivity(activity) {
  return await axiosInstance.post("/activitys", activity);
}
export async function EditActivity(activity) {
  return await axiosInstance.patch(`/activitys/${activity._id}`, activity);
}
export async function MarkAsDone(activity) {
  return await axiosInstance.patch(
    `/activitys/markAsDone/${activity._id}`,
    activity
  );
}
export async function DeleteActivity(activityId) {
  return await axiosInstance.delete(`/activitys/${activityId}`);
}

export async function PostTarget(target, activityId) {
  return await axiosInstance.post(
    "/activitys/" + activityId + "/target",
    target
  );
}
export async function EditTarget(target, activityId) {
  return await axiosInstance.patch(
    "/activitys/" + activityId + "/target/" + target._id,
    target
  );
}
export async function DeleteTarget(target, activityId) {
  return await axiosInstance.patch(
    "/activitys/" + activityId + "/RemoveTarget/" + target._id
  );
}
