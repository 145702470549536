export function CheckboxInput({ title, onValuehange, value = false }) {
  const onValueChange = (input) => {
    onValuehange(input.target.checked);
  };
  return (
    <div className="flex items-start mb-6">
      <div className="flex items-center h-5">
        <input
          type="checkbox"
          value={value}
          checked={value}
          onChange={onValueChange}
        />
      </div>
      <label className="ml-2 text-sm font-medium text-black dark:text-gray-300">
        {title}
      </label>
    </div>
  );
}
