export function getBlockSize(block, blockAdjustments, newSegment) {
  const updatedBlock = { ...block };

  if (blockAdjustments.isResizing)
    return resize(updatedBlock, blockAdjustments, newSegment);
  else if (blockAdjustments.isDragging)
    return drag(updatedBlock, blockAdjustments, newSegment);
  else if (blockAdjustments.isMoving)
    return move(updatedBlock, blockAdjustments, newSegment);
}

function resize(block, blockAdjustments, newSegment) {
  let updatedBlockAdjustments = getUpdatedBlockAdjustmentsResize(
    block,
    blockAdjustments,
    newSegment
  );

  if (
    block.start > newSegment &&
    updatedBlockAdjustments.resizingSide === "bottom"
  ) {
    block.start = newSegment;
    return { block, updatedBlockAdjustments };
  }

  if (
    block.end < newSegment &&
    updatedBlockAdjustments.resizingSide === "top"
  ) {
    block.end = newSegment;
    return { block, updatedBlockAdjustments };
  }

  if (updatedBlockAdjustments.resizingSide === "top") {
    block.start = newSegment;
  } else if (updatedBlockAdjustments.resizingSide === "bottom") {
    block.end = newSegment;
  }

  return { block, updatedBlockAdjustments };
}

function drag(block, blockAdjustments, newSegment) {
  let updatedBlockAdjustments = getUpdatedBlockAdjustmentsDrag(
    block,
    blockAdjustments,
    newSegment
  );

  if (
    block.start > newSegment &&
    updatedBlockAdjustments.resizingSide === null
  ) {
    block.start = newSegment;
    return { block, updatedBlockAdjustments };
  }
  if (
    block.start < newSegment &&
    updatedBlockAdjustments.resizingSide === null
  ) {
    block.end = newSegment;
    return { block, updatedBlockAdjustments };
  }
  if (
    block.start > newSegment &&
    updatedBlockAdjustments.resizingSide === "bottom"
  ) {
    block.start = newSegment;
    return { block, updatedBlockAdjustments };
  }
  if (
    block.end < newSegment &&
    updatedBlockAdjustments.resizingSide === "top"
  ) {
    block.end = newSegment;
    return { block, updatedBlockAdjustments };
  }

  if (updatedBlockAdjustments.resizingSide === "top") {
    block.start = newSegment;
  } else {
    block.end = newSegment;
  }
  return { block, updatedBlockAdjustments };
}

function move(block, blockAdjustments, newSegment) {
  let updatedBlockAdjustments = getUpdatedBlockAdjustmentsMove(
    block,
    blockAdjustments,
    newSegment
  );
  if (blockAdjustments.initialMouseSegment !== null) {
    if (
      newSegment - updatedBlockAdjustments.initialStartOffset >= 0 &&
      newSegment + updatedBlockAdjustments.initialEndOffset < 24 * 4
    ) {
      block.start = newSegment - updatedBlockAdjustments.initialStartOffset;
      block.end = newSegment + updatedBlockAdjustments.initialEndOffset;
    } else if (newSegment - updatedBlockAdjustments.initialStartOffset < 0) {
      block.start = 0;
      block.end =
        0 +
        updatedBlockAdjustments.initialStartOffset +
        updatedBlockAdjustments.initialEndOffset;
    } else if (newSegment + updatedBlockAdjustments.initialEndOffset > 24 * 4) {
      block.start =
        24 * 4 -
        1 -
        updatedBlockAdjustments.initialEndOffset -
        updatedBlockAdjustments.initialStartOffset;
      block.end = 24 * 4 - 1;
    }
  }
  return { block, updatedBlockAdjustments };
}

function getUpdatedBlockAdjustmentsResize(block, blockAdjustments, newSegment) {
  let updatedBlockAdjustments = { ...blockAdjustments };
  if (block.start > newSegment && blockAdjustments.resizingSide === "bottom")
    updatedBlockAdjustments.resizingSide = "top";
  else if (block.end < newSegment && blockAdjustments.resizingSide === "top")
    updatedBlockAdjustments.resizingSide = "bottom";
  return updatedBlockAdjustments;
}
function getUpdatedBlockAdjustmentsDrag(block, blockAdjustments, newSegment) {
  let updatedBlockAdjustments = { ...blockAdjustments };

  if (block.start > newSegment && blockAdjustments.resizingSide === null) {
    updatedBlockAdjustments.resizingSide = "top";
    return updatedBlockAdjustments;
  }
  if (block.start < newSegment && blockAdjustments.resizingSide === null) {
    updatedBlockAdjustments.resizingSide = "bottom";
    return updatedBlockAdjustments;
  }

  if (block.start > newSegment && blockAdjustments.resizingSide === "bottom") {
    updatedBlockAdjustments.resizingSide = "top";
  } else if (
    block.end < newSegment &&
    blockAdjustments.resizingSide === "top"
  ) {
    updatedBlockAdjustments.resizingSide = "bottom";
  }

  return updatedBlockAdjustments;
}

function getUpdatedBlockAdjustmentsMove(block, blockAdjustments, newSegment) {
  let updatedBlockAdjustments = { ...blockAdjustments };
  if (blockAdjustments.initialMouseSegment === null) {
    updatedBlockAdjustments.initialMouseSegment = newSegment;

    updatedBlockAdjustments.initialStartOffset =
      newSegment - blockAdjustments.initialStartSegment;

    updatedBlockAdjustments.initialEndOffset =
      blockAdjustments.initialEndSegment - newSegment;
  }

  return updatedBlockAdjustments;
}
