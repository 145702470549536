import { useCurrentTime } from "../Utils/CustomHooks/useCurrentTime";
import { useEffect, useState } from "react";

export function TimeLine() {
  const time = useCurrentTime();

  const [topHeight, setTopHeight] = useState("");
  useEffect(() => {
    if (time == null) return;
    const fullHour = time.getHours() + time.getMinutes() / 60; //ex 7.85
    const segmentMultiplier = 4;

    const totalSegments = fullHour * segmentMultiplier; // Total segments based on time
    const gapCount = Math.floor(totalSegments / 4); // Count of 4-segment gaps
    const gapAdjustment = gapCount * 4; // Add 4px per gap

    // Calculate top position including 0.5rem padding, 0.75rem per segment, and gap adjustments
    const topCalculation = `calc(0.5rem + ${totalSegments} * 0.75rem + ${gapAdjustment}px)`;
    setTopHeight(topCalculation); //0.5rem is the py-2 padding above the columns
  }, [time]);

  return (
    <div
      className="absolute w-full z-10 pointer-events-none select-none"
      style={{ top: topHeight }}
    >
      <div className="bg-primary h-[2px]"></div>
    </div>
  );
}
