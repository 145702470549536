import {
  normalizeDate,
  toLocalISOString,
} from "../../Shared/Utils/Functions/dateToNumber";
import { useTargetStats } from "../Utils/CustomHooks/useTargetStats";

export function TargetHeatMap() {
  const { datePercentageMap } = useTargetStats(); // Map with [date]: number format
  if (datePercentageMap.size === 0) return;

  // Generate an array of weeks, each containing 7 days
  const getYearWeeks = () => {
    const weeks = [];
    const start = new Date(new Date().getFullYear(), 0, 1);
    const targetYear = start.getFullYear();

    for (let week = 0; week < 53; week++) {
      const days = Array.from({ length: 7 }, (_, i) => {
        const date = normalizeDate(new Date(start));
        date.setDate(start.getDate() + week * 7 + i);

        // Format date as 'YYYY-MM-DD' to match `datePercentageMap` keys
        const formattedDate = toLocalISOString(date).split("T")[0];
        const percentage = datePercentageMap.get(formattedDate) || 0;

        return { date, percentage };
      }).filter((day) => day.date.getFullYear() === targetYear);
      weeks.push(days);
    }
    return weeks;
  };

  const weeks = getYearWeeks();

  const getHeatColor = (percentage) => {
    //outside of date range
    if (percentage === -1) return "rgba(235, 237, 240, 0.5)"; // Same as #ebedf0 with 50% opacity

    //date has no targets
    if (percentage === -2) return "rgba(174, 174, 174, 0.5)";

    if (percentage === 0) return "#ebedf0";
    if (percentage <= 25) return "#c6e48b";
    if (percentage <= 50) return "#7bc96f";
    if (percentage <= 75) return "#239a3b";
    return "#196127";
  };

  return (
    <div>
      <div className="flex gap-3">
        <div className="text-xl text-black dark:text-white">
          Targets Achieved
        </div>
        <div className="flex gap-5 items-center">
          <div className="flex flex-col items-center">
            <div
              style={{
                height: "14px",
                width: "14px",
                borderRadius: "2px",
                backgroundColor: "rgba(235, 237, 240, 0.5)",
                backgroundImage: `repeating-linear-gradient(
                          45deg,
                          #ccc,
                          #ccc 1px,
                          transparent 1px,
                          transparent 3px
                        )`,
              }}
            ></div>
            <div className="text-xs text-black dark:text-white">
              Outside range
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div
              style={{
                height: "14px",
                width: "14px",
                borderRadius: "2px",
                backgroundColor: "rgba(174, 174, 174, 0.5)",
              }}
            ></div>
            <div className="text-xs text-black dark:text-white">No Targets</div>
          </div>
          <div className="flex gap-2">
            <div className="flex flex-col items-center">
              <div
                style={{
                  height: "14px",
                  width: "14px",
                  borderRadius: "2px",
                  backgroundColor: "#ebedf0",
                }}
              ></div>
              <div className="text-xs text-black dark:text-white">&gt;0%</div>
            </div>
            <div className="flex flex-col items-center">
              <div
                style={{
                  height: "14px",
                  width: "14px",
                  borderRadius: "2px",
                  backgroundColor: "#c6e48b",
                }}
              ></div>
              <div className="text-xs text-black dark:text-white"> &gt;25%</div>
            </div>
            <div className="flex flex-col items-center">
              <div
                style={{
                  height: "14px",
                  width: "14px",
                  borderRadius: "2px",
                  backgroundColor: "#7bc96f",
                }}
              ></div>
              <div className="text-xs text-black dark:text-white"> &gt;50%</div>
            </div>
            <div className="flex flex-col items-center">
              <div
                style={{
                  height: "14px",
                  width: "14px",
                  borderRadius: "2px",
                  backgroundColor: "#239a3b",
                }}
              ></div>
              <div className="text-xs text-black dark:text-white"> &gt;75%</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="mt-3"
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${weeks.length}, 1fr)`,
          gap: "4px",
          alignItems: "start",
        }}
      >
        {weeks.map((week, weekIndex) => (
          <div key={weekIndex} style={{ display: "grid", gap: "2px" }}>
            {week.map(
              ({ date, percentage }, dayIndex) =>
                date.getFullYear() === new Date().getFullYear() && (
                  <div
                    key={dayIndex}
                    title={
                      percentage === -1
                        ? "Outside Range"
                        : percentage === -2
                        ? "No Target"
                        : `${date.toDateString()}: ${percentage}%`
                    }
                    style={{
                      width: "100%", // Full width of the grid cell
                      height: "14px", // Fixed height to avoid expanding
                      backgroundColor: getHeatColor(percentage),
                      borderRadius: "2px",
                      backgroundImage:
                        percentage === -1
                          ? `repeating-linear-gradient(
                          45deg,
                          #ccc,
                          #ccc 1px,
                          transparent 1px,
                          transparent 3px
                        )`
                          : "none",
                    }}
                  />
                )
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
