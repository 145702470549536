import { useDateContext } from "../../Shared/Contexts/DateContextProvider";
import { useUserContext } from "../../Shared/Contexts/UserContextProvider";
import { useWindowWidth } from "../../Shared/Utils/CustomHooks/UseWindowWidth";
import { useSelectedDates } from "../Utils/CustomHooks/useSelectedDates";
import { DateChooser } from "./DateChooser";
import { TimeSpan } from "./TimeSpan";

export function StatisticsHeader() {
  const { setDate } = useDateContext();
  const windowWidth = useWindowWidth();
  const { user } = useUserContext();
  const { startDate, endDate } = useSelectedDates();

  const handleSetDateToday = () => {
    setDate(new Date());
  };

  return (
    <div className="px-2 py-5 md:px-12 md:py-7 flex items-center gap-3">
      <TimeSpan />
      <button
        className="border  px-6 rounded-md py-1 text-center text-black border-black dark:text-white dark:border-white"
        onClick={handleSetDateToday}
      >
        Today
      </button>
      <DateChooser />
      <div className="text-black dark:text-white">
        <span className="px-2 py-1 rounded bg-secondaryLight  dark:bg-dark">
          {startDate == null ? "" : new Date(startDate).toLocaleDateString()}
        </span>
        <span> - </span>
        <span className="px-2 py-1 rounded bg-secondaryLight  dark:bg-dark">
          {endDate == null ? "" : new Date(endDate).toLocaleDateString()}
        </span>
      </div>

      {windowWidth >= 600 && (!user || user.isAnonymous === true) && (
        <button className="px-3 py-1 rounded bg-red-500">
          Sign In to Keep Your Creations Safe!
        </button>
      )}
    </div>
  );
}
