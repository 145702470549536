import { getUpdatedBlockPositions } from "../../../TimeBlocker/Utils/functions/BlockPosition";
import { isSameDate } from "./dateToNumber";

export function GetUpdatedIndentions(blocks, date, columnWidth) {
  const columnBlocks = blocks.filter((block) => isSameDate(block.date, date));
  const otherBlocks = blocks.filter((block) => !isSameDate(block.date, date));
  return [
    ...otherBlocks,
    ...getUpdatedBlockPositions(columnBlocks, columnWidth - 10),
  ];
}
