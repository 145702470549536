import axiosInstance from "../../httpInterceptor";

// export async function getTags() {
//   return axiosInstance.get("/tags").then((response) => response.data);
// }

export async function getTagActivities() {
  return axiosInstance
    .get("/tags/tagActivities")
    .then((response) => response.data);
}
export async function postTagActivity(tagActivity) {
  return axiosInstance
    .post("/tags/tagActivities", tagActivity)
    .then((response) => response.data);
}
export async function patchTagActivity(tagActivity) {
  return axiosInstance
    .patch(`/tags/tagActivities/${tagActivity._id}`, tagActivity)
    .then((response) => response.data);
}
export async function deleteTagActivity(tagActivity) {
  return axiosInstance
    .delete(`/tags/tagActivities/${tagActivity._id}`)
    .then((response) => response.data);
}

export async function PostTagTarget(target, tagActivityId) {
  return await axiosInstance.post("/tags/" + tagActivityId + "/target", target);
}

export async function EditTagTarget(target, tagActivityId) {
  return await axiosInstance.patch(
    "/tags/" + tagActivityId + "/target/" + target._id,
    target
  );
}

export async function DeleteTagTarget(target, tagActivityId) {
  return await axiosInstance.patch(
    "/tags/" + tagActivityId + "/RemoveTarget/" + target._id
  );
}

export async function postTag(tag) {
  return axiosInstance.post("/tags", tag).then((response) => response.data);
}
export async function patchTag(tag) {
  return axiosInstance
    .patch(`/tags/${tag._id}`, tag)
    .then((response) => response.data);
}
export async function deleteTag(tag) {
  return axiosInstance
    .delete(`/tags/${tag._id}`)
    .then((response) => response.data);
}
export async function getTags(startDate, endDate) {
  return axiosInstance
    .post("/tags/getTags", { startDate, endDate })
    .then((response) => response.data);
}
