import { useEffect, useState } from "react";
import { useDateContext } from "../../../Shared/Contexts/DateContextProvider";
import { useTimeSpanContext } from "../../../Shared/Contexts/TimeSpanContextProvider";
import { fixTimeZone } from "../../../Shared/Utils/Functions/fixTimeZone";

export function useSelectedDates() {
  let { date } = useDateContext();
  const { timeSpan } = useTimeSpanContext(); // possible values: 'week', 'month', 'year'

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    let newDate = new Date(date);
    let start, end;

    if (timeSpan === "week") {
      // Calculate the start of the week (Monday)
      const day = newDate.getUTCDay();
      const difference = day === 0 ? -6 : 1 - day;
      newDate.setUTCDate(newDate.getUTCDate() + difference);
      start = new Date(newDate);

      // Set end date to Sunday of the same week
      end = new Date(newDate);
      end.setUTCDate(newDate.getUTCDate() + 6);
    } else if (timeSpan === "month") {
      // Set to the start of the month
      start = new Date(newDate.getUTCFullYear(), newDate.getUTCMonth(), 1);
      fixTimeZone(start);
      // Set end to the last day of the month
      end = new Date(newDate.getUTCFullYear(), newDate.getUTCMonth() + 1, 0);
      fixTimeZone(end);
    } else if (timeSpan === "year") {
      // Set to the start of the year
      start = new Date(newDate.getUTCFullYear(), 0, 1);
      fixTimeZone(start);

      // Set end to the last day of the year
      end = new Date(newDate.getUTCFullYear(), 11, 31);
      fixTimeZone(end);
    }

    setStartDate(start);
    setEndDate(end);
  }, [date, timeSpan]);

  return { startDate, endDate };
}
