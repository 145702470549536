import { doBlocksOverlap } from "./Overlaping";

export const getLanes = (blocks) => {
  const lanes = [[]];
  for (const block of blocks) {
    for (let i = 0; i < lanes.length; i++) {
      const lane = lanes[i];
      if (lane.length === 0) {
        lane.push(block);
        break;
      }
      if (lane.find((b) => doBlocksOverlap(b, block))) {
        if (i === lanes.length - 1) {
          lanes.push([]);
          lanes[i + 1].push(block);
          break;
        }
        continue;
      }
      lane.push(block);
      break;
    }
  }
  return lanes;
};
