import { Modal } from "../../Shared/Components/Modal";
import { useActivityContext } from "../../Shared/Contexts/ActivitiesContextProvider";
import { useTagContext } from "../../Shared/Contexts/TagContextProvider";
import { useActivityColor } from "../../Shared/Utils/CustomHooks/useActivityColor";
import { useTargets } from "../Utils/CustomHooks/useTargets";
import { useWeeklyTargets } from "../Utils/CustomHooks/useWeeklyTargets";

export function TargetAchievedModal({ date }) {
  const { targets } = useTargets(date);
  const { weeklyTargets } = useWeeklyTargets();

  const handleCancel = () => {};
  return (
    <Modal
      title={
        "Targets & Deadlines (" + new Date(date).toLocaleDateString() + ")"
      }
      hasSuccess={false}
      onCancel={handleCancel}
    >
      <div className="dark:bg-dark dark:text-white">
        <span>Targets Today</span>
        <div className="flex flex-col gap-1">
          {targets
            .filter((t) => t.type !== "amount")
            .map((target) => (
              <Target key={target._id} target={target} />
            ))}
          {targets
            .filter((t) => t.type === "amount")
            .map((target) => (
              <TagTarget key={target._id} target={target} />
            ))}
        </div>

        <div className="mt-5">Targets this Week</div>
        <div className="flex flex-col gap-1">
          {weeklyTargets
            .filter((t) => t.type !== "amount")
            .map((target) => (
              <Target key={target._id} target={target} />
            ))}
          {weeklyTargets
            .filter((t) => t.type === "amount")
            .map((target) => (
              <TagTarget key={target._id} target={target} />
            ))}
        </div>
      </div>
    </Modal>
  );
}

function Target({ target }) {
  const { activities } = useActivityContext();
  const { getActivityColor } = useActivityColor();
  const activity = activities.find((a) => a._id === target.activityId);
  const color = getActivityColor(activity._id);
  return (
    <div className="px-2 py-1 rounded  bg-secondaryLight dark:bg-lightDark">
      <div className="flex gap-1">
        <div className="flex items-center gap-2">
          <div className="w-2 h-2" style={{ backgroundColor: color }}></div>
          <h5 className="text-sm">{activity.title}</h5>
        </div>

        <div className="ml-3 flex gap-1">
          {target.type === "time" && (
            <div>
              ({target.totalTime}/{target.time}) minutes ({target.minMax})
            </div>
          )}
          {target.type === "block" && (
            <div>
              ({target.totalBlocks}/{target.blocks}) blocks ({target.minMax})
            </div>
          )}
        </div>
        <AchievedText target={target} />
      </div>
    </div>
  );
}

function TagTarget({ target }) {
  const { tagActivities, setTagActivities } = useTagContext();

  const tagActivity = tagActivities.find((a) => a._id === target.activityId);

  return (
    <div className="px-1 py-1 rounded  bg-secondaryLight dark:bg-lightDark">
      <div className="flex gap-1">
        <div className="flex items-center gap-2">
          <h5 className="text-sm">
            <span>{tagActivity.icon}</span>
            <span>{tagActivity.title}</span>
          </h5>
        </div>

        <div className="ml-3">
          <div>
            ({target.totalAmount}/{target.amount}) tags ({target.minMax})
          </div>
        </div>
        <AchievedText target={target} />
      </div>
    </div>
  );
}

function AchievedText({ target }) {
  return (
    <>
      {target.type === "time" && !target.isDone && target.minMax === "min" && (
        <div className="font-bold text-[#DE6363] ml-auto">
          {target.time - target.totalTime} min left
        </div>
      )}
      {target.type === "time" && !target.isDone && target.minMax === "max" && (
        <div className="font-bold text-[#DE6363] ml-auto">
          {target.totalTime - target.time} min over
        </div>
      )}
      {target.type === "time" && target.isDone && (
        <div className="font-bold text-[#63DE75] ml-auto">Done</div>
      )}

      {target.type === "block" && !target.isDone && target.minMax === "min" && (
        <div className="font-bold text-[#DE6363] ml-auto">
          {target.blocks - target.totalBlocks} blocks left
        </div>
      )}
      {target.type === "block" && !target.isDone && target.minMax === "max" && (
        <div className="font-bold text-[#DE6363] ml-auto">
          {target.totalBlocks - target.blocks} blocks over
        </div>
      )}
      {target.type === "block" && target.isDone && (
        <div className="font-bold text-[#63DE75] ml-auto">Done</div>
      )}

      {target.type === "amount" &&
        !target.isDone &&
        target.minMax === "min" && (
          <div className="font-bold text-[#DE6363] ml-auto">
            {target.amount - target.totalAmount} blocks left
          </div>
        )}
      {target.type === "amount" &&
        !target.isDone &&
        target.minMax === "max" && (
          <div className="font-bold text-[#DE6363] ml-auto">
            {target.totalAmount - target.amount} blocks over
          </div>
        )}
      {target.type === "amount" && target.isDone && (
        <div className="font-bold text-[#63DE75] ml-auto">Done</div>
      )}
    </>
  );
}
