import { useEffect, useState } from "react";
import { useCalendarWidthContext } from "../../../Shared/Contexts/CalendarWidthContextProvider";
import { useSelectedDates } from "./useSelectedDates";

export function useColumnWidth() {
  const { calendarWidth } = useCalendarWidthContext();
  const [columnWidth, setColumnWidth] = useState(null);
  const [columnWidthInitialized, setColumnWidthInitialized] = useState(null);
  const { showDates } = useSelectedDates();

  useEffect(() => {
    if (calendarWidth === 0 || calendarWidth == null) {
      return;
    }

    const translatedWidth = calendarWidth - 38; // time width
    const columnCount = showDates.length;

    setColumnWidth((translatedWidth - (columnCount - 1) * 8) / columnCount);
    setColumnWidthInitialized(true);
  }, [showDates, calendarWidth]);

  return { columnWidth, columnWidthInitialized };
}
