import { useDateContext } from "../../Shared/Contexts/DateContextProvider";
import { useWindowWidth } from "../../Shared/Utils/CustomHooks/UseWindowWidth";
import { DateChooser } from "./DateChooser";
import { ColumnViewPicker } from "./ColumnViewPicker";
import { HeaderNavigationMenu } from "./HeaderNavigationMenu";
import { useUserContext } from "../../Shared/Contexts/UserContextProvider";
import { useSelectedDates } from "../Utils/CustomHooks/useSelectedDates";
import { Link } from "react-router-dom";

export function TimeBlocksHeader() {
  const { setDate } = useDateContext();
  const windowWidth = useWindowWidth();
  const { user } = useUserContext();
  const { showDates } = useSelectedDates();

  const handleSetDateToday = () => {
    setDate(new Date());
  };

  return (
    <div className="px-2 py-5 md:pl-12 md:py-7 2xl:px-12 flex items-center gap-3">
      <button
        className="border  px-6 rounded-md py-1 text-center text-black border-black dark:text-white dark:border-white"
        onClick={handleSetDateToday}
      >
        Today
      </button>
      <DateChooser />
      <ColumnViewPicker />

      {windowWidth >= 1090 && (
        <div className="h-10 flex gap-1 text-black dark:text-white">
          <span className="px-2 py-2 rounded bg-secondaryLight  dark:bg-dark">
            {showDates == null || showDates[0] == null
              ? ""
              : new Date(showDates[0].date).toLocaleDateString()}
          </span>
          <span className="py-2"> - </span>
          <span className="px-2 py-2 rounded bg-secondaryLight  dark:bg-dark">
            {showDates == null || showDates[showDates.length - 1] == null
              ? ""
              : new Date(
                  showDates[showDates.length - 1].date
                ).toLocaleDateString()}
          </span>
        </div>
      )}

      {windowWidth >= 1330 && (!user || user.isAnonymous === true) && (
        <Link to={"/login"} className="px-3 py-1 rounded bg-red-500">
          Sign In to Keep Your Creations Safe!
        </Link>
      )}

      {windowWidth < 600 && (
        <div className="ml-auto">
          <HeaderNavigationMenu />
        </div>
      )}
    </div>
  );
}
