import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";

const StatisticsContext = createContext(null);

export function StatisticsContextProvider({ children }) {
  const [stats, setStats] = useState(null);

  return (
    <StatisticsContext.Provider value={{ stats, setStats }}>
      {children}
    </StatisticsContext.Provider>
  );
}

export function useStatisticsContext() {
  const context = useContext(StatisticsContext);
  if (!context) {
    throw new Error(
      "useStatisticsContext must be used within a StatisticsContextProvider"
    );
  }
  return context;
}
