import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { useTimeSpanContext } from "../../Shared/Contexts/TimeSpanContextProvider";
import { useBlockedTimeStats } from "../Utils/CustomHooks/useBlockedTimeStats";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

export function BlockedTimeStats() {
  const { timeSpan } = useTimeSpanContext();

  const { data, text } = useBlockedTimeStats();

  if (!data) return <></>;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Total Time (hours)",
        },
      },
      x: {
        offset: true,
        type: "time",
        time: {
          unit:
            timeSpan === "week"
              ? "day"
              : timeSpan === "month"
              ? "week"
              : "month",
          tooltipFormat: "PP",
          displayFormats: {
            day: "EEE",
            week: "'Week' w",
            month: "MMM",
          },
          round:
            timeSpan === "week"
              ? "day"
              : timeSpan === "month"
              ? "week"
              : "month",
        },
        title: {
          display: true,
          text: "Date",
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
}
