import { Menu, Transition } from "@headlessui/react";
import ellipsis from "../../../Assets/Icons/ellipsis.svg";
import { Fragment, useEffect, useState } from "react";

export function Target({ target, onEdit, onArchive, onReactivate, onDelete }) {
  return (
    <div className="pl-3 pr-1 py-1 flex items-center dark:bg-dark dark:text-white">
      <div className="font-semibold">{target.range}</div>

      <div className="ml-4 flex gap-1">
        <div className="ml-4">{target.minMax}</div>

        {target.type === "time" ? (
          <div>{target.time} minutes</div>
        ) : (
          <div>{target.blocks} blocks</div>
        )}
      </div>

      <div className="ml-auto mr-2">
        {new Date(target.startDate).toLocaleDateString()}
        {target.endDate != null &&
          " - " + new Date(target.endDate).toLocaleDateString()}
      </div>

      <div className="">
        <Options
          canArchive={target.endDate == null}
          canReactivate={target.endDate != null}
          onEdit={onEdit}
          onArchive={onArchive}
          onReactivate={onReactivate}
          onDelete={() => onDelete(target)}
        />
      </div>
    </div>
  );
}

function Options({
  canArchive,
  canReactivate,
  onEdit,
  onArchive,
  onReactivate,
  onDelete,
}) {
  const [isHolding, setIsHolding] = useState(false);
  const [isReadyToDelete, setIsReadyToDelete] = useState(false);

  useEffect(() => {
    let timeout;
    if (isHolding) {
      timeout = setTimeout(() => {
        setIsReadyToDelete(true); // Mark as ready after 2 seconds
      }, 1000);
    } else {
      clearTimeout(timeout);
      setIsReadyToDelete(false); // Reset when not holding
    }

    // Cleanup timeout on component unmount or when `isHolding` changes
    return () => clearTimeout(timeout);
  }, [isHolding]);

  const handleMouseDown = () => {
    setIsHolding(true);
  };

  const handleMouseUp = () => {
    if (isReadyToDelete) {
      onDelete(); // Trigger onDelete only if the button was held for 2 seconds
    }
    setIsHolding(false);
    setIsReadyToDelete(false);
  };

  const handleMouseLeave = () => {
    setIsHolding(false);
    setIsReadyToDelete(false);
  };

  return (
    <Menu as="div" className="relative text-left ml-auto flex align-middle">
      <Menu.Button className="w-full justify-center gap-x-1.5 px-3 py-2 text-sm font-semibold text-gray-900 ring-lightDark">
        <img className="w-3" src={ellipsis} alt="menu icon" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-[#515151] text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {canArchive && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={onArchive}
                    className={[
                      active ? "bg-gray-100 text-gray-900" : "text-white",
                      "block px-4 py-2 text-sm",
                    ]}
                  >
                    Archive
                  </div>
                )}
              </Menu.Item>
            )}
            {canReactivate && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={onReactivate}
                    className={[
                      active ? "bg-gray-100 text-gray-900" : "text-white",
                      "block px-4 py-2 text-sm",
                    ]}
                  >
                    Reactivate
                  </div>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={onEdit}
                  className={[
                    active ? "bg-gray-100 text-gray-900" : "text-white",
                    "block px-4 py-2 text-sm",
                  ]}
                >
                  Edit
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseLeave}
                  className={[
                    active ? "bg-gray-100 text-gray-900" : "text-white",
                    "block px-4 py-2 text-sm relative overflow-hidden",
                  ]}
                >
                  <div
                    className={`absolute inset-0 bg-red-500 transition-transform duration-[1000ms] ease-linear`}
                    style={{
                      transform: isHolding ? "scaleX(1)" : "scaleX(0)",
                      transformOrigin: "left",
                    }}
                  />
                  <span className="relative z-10">Delete</span>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
