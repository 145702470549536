import { createContext, useContext, useState } from "react";

const CalendarWidthContext = createContext(null);

export function CalendarWidthContextProvider({ children }) {
  const [calendarWidth, setCalendarWidth] = useState(null);

  return (
    <CalendarWidthContext.Provider value={{ calendarWidth, setCalendarWidth }}>
      {children}
    </CalendarWidthContext.Provider>
  );
}

export function useCalendarWidthContext() {
  const context = useContext(CalendarWidthContext);
  if (!context) {
    throw new Error(
      "CalendarWidthContext must be used within a CalendarWidthContextProvider"
    );
  }
  return context;
}
