import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useBlocksContext } from "./BlocksContextProvider";
import { useSelectedDates } from "../../TimeBlocker/Utils/CustomHooks/useSelectedDates";
import { useActivityContext } from "./ActivitiesContextProvider";
import { getBlocks } from "../../../Endpoints/Blocks/BlocksEndpoints";
import { defaultBlockColor } from "../../../App";
import { getUpdatedBlockPositions } from "../../TimeBlocker/Utils/functions/BlockPosition";
import { useColumnWidth } from "../../TimeBlocker/Utils/CustomHooks/useColumnWidth";
import { isSameDate } from "../Utils/Functions/dateToNumber";

const ColumnStateContext = createContext(null);

export const ColumnStateProvider = ({ children }) => {
  const { setBlocks, setLoadingBlocks } = useBlocksContext();
  const { showDates, weekDates } = useSelectedDates();
  const { columnWidth, columnWidthInitialized } = useColumnWidth();
  const columnWidthRef = useRef(columnWidth);
  useEffect(() => {
    columnWidthRef.current = columnWidth;
  }, [columnWidth]);

  const { activities, activitiesLoaded } = useActivityContext();
  const activitiesRef = useRef(activities);
  useEffect(() => {
    activitiesRef.current = activities;
  }, [activities]);

  const activitiesLoadedRef = useRef(activitiesLoaded);
  useEffect(() => {
    activitiesLoadedRef.current = activitiesLoaded;
  }, [activitiesLoaded]);

  const [blocksPreActivitiesLoaded, setBlocksPreActivitiesLoaded] =
    useState(null);

  useEffect(() => {
    if (blocksPreActivitiesLoaded === null || activitiesLoaded === false)
      return;

    const blocks = blocksPreActivitiesLoaded?.map((b) => ({ ...b }));

    let indentedBlocks = [];
    for (const date of showDates) {
      indentedBlocks = [
        ...indentedBlocks,
        ...getUpdatedBlockPositions(
          blocks.filter((b) => isSameDate(b.date, date.date)),
          columnWidth - 10
        ),
      ];
    }

    setBlocks((current) => indentedBlocks);
    setLoadingBlocks(false);
  }, [blocksPreActivitiesLoaded, activitiesLoaded]);

  const loadColumns = () => {
    if (weekDates === null || weekDates.length === 0) return;

    setLoadingBlocks(true);
    getBlocks(weekDates[0].date, weekDates.slice(-1)[0].date).then(
      (response) => {
        let responseBlocks = [
          ...response.data.map((b) => ({ ...b, date: new Date(b.date) })),
        ];

        if (activitiesLoadedRef.current !== true) {
          setBlocksPreActivitiesLoaded(responseBlocks);
          return;
        }

        let blocks = [];
        for (const date of weekDates) {
          blocks = [
            ...blocks,
            ...getUpdatedBlockPositions(
              responseBlocks.filter((b) => isSameDate(b.date, date.date)),
              columnWidthRef.current - 10
            ),
          ];
        }

        setBlocks((current) => blocks);
        setLoadingBlocks(false);
      }
    );
  };

  useEffect(() => {
    if (columnWidthInitialized === false) return;
    loadColumns();
  }, [weekDates, columnWidthInitialized]);

  return (
    <ColumnStateContext.Provider value={{ loadColumns }}>
      {children}
    </ColumnStateContext.Provider>
  );
};

export const useColumnStateUpdater = () => {
  const context = useContext(ColumnStateContext);
  if (!context) {
    throw new Error(
      "useColumnStateUpdater must be used within a ColumnStateProvider"
    );
  }
  return context;
};
