export function TargetRange({ currentRange, onTargetRangeChange }) {
  const handleChange = (event) => {
    onTargetRangeChange(event.target.value);
  };

  return (
    <div>
      <select
        id="time-span-select"
        value={currentRange}
        onChange={handleChange}
        className="py-1 w-24 border bg-white border-black dark:border-white text-black time-span-select dark:text-white"
      >
        <option value="daily">daily</option>
        <option value="weekly">weekly</option>
        <option value="weekDays">week days</option>
        <option value="custom">custom</option>
      </select>
    </div>
  );
}
