import { createContext, useContext, useState } from "react";

const TimeSpanContext = createContext(null);

export function TimeSpanContextProvider({ children }) {
  const [timeSpan, setTimeSpan] = useState("week");

  return (
    <TimeSpanContext.Provider value={{ timeSpan, setTimeSpan }}>
      {children}
    </TimeSpanContext.Provider>
  );
}

export function useTimeSpanContext() {
  const context = useContext(TimeSpanContext);
  if (!context) {
    throw new Error(
      "useTimeSpanContext must be used within a TimeSpanContextProvider"
    );
  }
  return context;
}
