import { useEffect, useState } from "react";
import { useBlocksContext } from "../../../Shared/Contexts/BlocksContextProvider";
import { useActivityContext } from "../../../Shared/Contexts/ActivitiesContextProvider";
import {
  gte,
  lte,
  normalizeDate,
} from "../../../Shared/Utils/Functions/dateToNumber";
import { useSelectedDates } from "../../../TimeBlocker/Utils/CustomHooks/useSelectedDates";
import { useTagContext } from "../../../Shared/Contexts/TagContextProvider";

export function useWeeklyTargets() {
  const { blocks } = useBlocksContext();
  const { activities } = useActivityContext();
  const { weekDates } = useSelectedDates();
  const { tags, tagActivities } = useTagContext();

  const [targets, setTargets] = useState([]);
  const [areAchieved, setAreAchieved] = useState([]);

  useEffect(() => {
    if (weekDates.length !== 7) return;
    const startDate = normalizeDate(weekDates[0].date);
    const endDate = normalizeDate(weekDates.slice(-1)[0].date);

    const relativeBlocks = blocks.filter((b) => {
      const blockDate = new Date(b.date);
      return gte(blockDate, startDate) && lte(blockDate, endDate);
    });

    const relativeTargets = getTargets(activities, startDate, endDate).filter(
      (t) => t.range === "weekly"
    );

    const relativeTagTargets = getTargets(
      tagActivities,
      startDate,
      endDate
    ).filter((t) => t.range === "weekly");

    const relativeTags = tags.filter((t) => {
      const blockDate = new Date(t.date);
      return gte(blockDate, startDate) && lte(blockDate, endDate);
    });

    calculateTargetTime(relativeTargets, relativeBlocks, activities);
    calculateTagTargetTime(relativeTagTargets, relativeTags);

    setTargets([...relativeTargets, ...relativeTagTargets]);

    setAreAchieved(
      relativeTargets.every((t) => {
        return (
          (t.type === "time" && t.minMax === "min"
            ? t.totalTime >= t.time
            : t.totalTime <= t.time) ||
          (t.type === "block" && t.minMax === "min"
            ? t.totalBlocks >= t.blocks
            : t.totalBlocks <= t.blocks) ||
          (t.type === "amount" && t.minMax === "min"
            ? t.totalAmount >= t.amount
            : t.totalAmount <= t.amount)
        );
      })
    );
  }, [blocks, activities, weekDates, tags]);

  return { weeklyTargets: targets, weeklyTargetsAchieved: areAchieved };
}

function getTargets(activities, inputStartDate, inputEndDate) {
  // Iterate over activities and collect matching targets
  const matchingTargets = activities.flatMap((activity) =>
    activity.targets
      .filter((target) => {
        const targetStartDate = normalizeDate(new Date(target.startDate));
        const targetEndDate = target.endDate
          ? normalizeDate(new Date(target.endDate))
          : null;

        // Check if the target date range overlaps with the input date range
        return (
          (targetEndDate === null && lte(targetStartDate, inputEndDate)) || // Includes targets with no end date that start before or within the input end date
          (targetEndDate !== null &&
            lte(targetStartDate, inputEndDate) &&
            gte(targetEndDate, inputStartDate))
        );
      })
      .map((target) => ({
        ...target,
        activityIsArchived: activity.isDone,
        activityId: activity._id, // Add the activityId to the target
      }))
  );

  return matchingTargets;
}

function calculateTargetTime(targets, blocks, activities) {
  targets.forEach((target) => {
    let totalTime = 0;

    // Find all activityIds that could match this target, including linked activities
    const relatedActivityIds = activities
      .filter((activity) => activity.categoryId === target.activityId)
      .map((activity) => activity._id);

    // Include the target's own activityId
    relatedActivityIds.push(target.activityId);

    const matchingBlocks = blocks.filter((block) =>
      relatedActivityIds.includes(block.activityId)
    );

    // Calculate the total time in minutes for these matching blocks
    matchingBlocks.forEach((block) => {
      // Calculate the number of 15-minute intervals and convert it to minutes
      const blockDurationMinutes = (block.end - block.start + 1) * 15;
      totalTime += blockDurationMinutes;
    });
    target.totalTime = totalTime;
    target.totalBlocks = matchingBlocks.length;

    // Check the condition based on minMax property
    if (target.minMax === "min") {
      // Same logic as before for 'min'
      if (target.type === "time" && target.totalTime >= target.time)
        target.isDone = true;
      else if (target.type === "block" && target.totalBlocks >= target.blocks)
        target.isDone = true;
      else target.isDone = false;
    } else if (target.minMax === "max") {
      // New logic for 'max'
      if (target.type === "time" && target.totalTime <= target.time)
        target.isDone = true;
      else if (target.type === "block" && target.totalBlocks <= target.blocks)
        target.isDone = true;
      else target.isDone = false;
    } else {
      // Default to 'min' behavior if minMax is not specified
      if (target.type === "time" && target.totalTime >= target.time)
        target.isDone = true;
      else if (target.type === "block" && target.totalBlocks >= target.blocks)
        target.isDone = true;
      else target.isDone = false;
    }
  });
}

function calculateTagTargetTime(targets, tags) {
  targets.forEach((target) => {
    const tag = tags.find((t) => t.tagActivityId === target.activityId);

    target.totalAmount = tag?.amount || 0;

    // Check the condition based on minMax property
    if (target.minMax === "min") {
      // Same logic as before for 'min'

      target.isDone = target.totalAmount >= target.amount;
    } else if (target.minMax === "max") {
      target.isDone = target.totalAmount <= target.amount;
    } else {
      // Default to 'min' behavior if minMax is not specified
      target.isDone = target.totalAmount >= target.amount;
    }
  });
}
